import {
  AddInput,
  AddInputDown,
  AddInputUp,
  BoxButton,
  FormHeader,
  InputBoxTitle,
  RequiredLabel,
  TextButton,
} from './components';
import { useEffect, useState } from 'react';
import { deliveryState, userState, firstViewState, cartState } from './atoms';
import { Link, useNavigate } from 'react-router-dom';
import css from '../index.module.scss';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../zz_general/utils/configs/firebase';
import * as Util from './util';
import { formatPhoneNum, insertTwoHyphens } from './form/form';

import dayjs from 'dayjs';

const BottomButtons = styled.div`
  padding: 24px 0;
`;
const DeliveryInfoForm = styled.form`
  padding: 0 24px;
`;

const AddressSelectCon = styled.div``;

const DeliveryDateSelectCon = styled.div``;

const SelectionCon = styled.div`
  padding: 24px;
  border: 1px solid #161c1c;
  margin-bottom: 8px;
  box-sizing: border-box;
  border-radius: 4px;

  div p {
    margin-bottom: 8px;
  }
  div p:first-child {
    margin-bottom: 12px;
  }
  div p:last-child {
    margin-bottom: 8px;
  }

  div p:last-child {
    margin-top: 12px;
  }
  div:nth-child(2) {
    margin: auto;
    text-align: right;
  }
`;

const DateSelectionCon = styled.div`
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 68px;
`;

const DateSelectionsCon = styled.div`
  border: 1px solid #a0a096;
  box-sizing: border-box;
  border-radius: 4px;
`;

const SelectionText = styled.p`
  margin-bottom: ${(props) => props.mb || 12}px;
  margin-top: 0;
  font-size: 20px;
  line-height: 24px;
  color: #161c1c;
`;

const Selection = (props) => {
  return (
    <label htmlFor={'selects' + props.index}>
      <SelectionCon
        className={classNames(css.flex)}
        onClick={() => {
          props.handleChange(props.index);
        }}
      >
        <div className={css.wid90}>
          <SelectionText>
            {(props.address['lName']?.value || '') + '  ' + (props.address['fName']?.value || '')}
          </SelectionText>
          <SelectionText mb={8}>
            〒
            {(props.address['postalCode']?.value.substring(0, 3) || '') +
              '-' +
              (props.address['postalCode']?.value.substring(3, 7) || '')}
          </SelectionText>
          <SelectionText mb={8}>
            {props.address['postalCode']?.value2} {props.address['address1']?.value || ''}
          </SelectionText>
          <SelectionText mb={8}>{props.address['address2']?.value || ''}</SelectionText>
          <SelectionText>{props.address['address3']?.value || ''}</SelectionText>
          <SelectionText>
            {() => {
              const event = props.address['phoneNum']?.value || '';
              insertTwoHyphens(event, formatPhoneNum(event)[0], formatPhoneNum(event)[1], formatPhoneNum(event)[2]);
            }}
          </SelectionText>
        </div>
        <div className={classNames(css.wid10, css.address_button)}>
          <div className={props.checked ? '' : css.displayNone}>
            <img src={`${process.env.PUBLIC_URL}/radio_on.svg`} alt="" />
          </div>
          <div className={props.checked ? css.displayNone : ''}>
            <img src={`${process.env.PUBLIC_URL}/radio_off.svg`} alt="" />
          </div>
        </div>
        <div className={css.address_edit}>
          <div
            onClick={(e) => {
              props.handleLinkClick(e, props.index);
            }}
            className={classNames(
              props.index !== 0 ? '' : css.displayNone,
              props.checked ? css.edit_button_on : css.displayNone
            )}
          >
            編集
          </div>
        </div>
      </SelectionCon>
    </label>
  );
};

const DateSelection = (props) => {
  let clockTime = `${props.time}:00 ~ ${props.time + 2}:00`;
  if (props.time === 8) {
    clockTime = `${props.time}:00 ~ ${props.time + 4}:00`;
  } else if (props.time === '指定なし') {
    clockTime = props.time;
  }
  return (
    <label htmlFor={'dateSelects' + props.index}>
      <DateSelectionCon className={css.flex}>
        <div className={css.wid90}>
          <SelectionText>{clockTime}</SelectionText>
        </div>
        <div className={css.wid10}>
          <input
            id={'dateSelects' + props.index}
            name="dateSelects"
            type="radio"
            onClick={() => props.updateClockTime(clockTime)}
          ></input>
        </div>
      </DateSelectionCon>
    </label>
  );
};

const AddressSelect = (props) => {
  const navigate = useNavigate();
  const [delivery, setDelivery] = useRecoilState(deliveryState);
  const addresses = delivery.addresses;

  const handleChange = (index) => {
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future.selectedAddress = addresses[index];
      future.checked.fill(false);
      future.checked[index] = true;
      return future;
    });
    setEdit(index);
  };

  const handleLinkClick = (event, _index) => {
    event.preventDefault();
    navigate('/lp-legacy/address-edit');
  };

  const setEdit = (index) => {
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future.edit = index;
      return future;
    });
  };
  const selections =
    addresses && addresses.length > 0
      ? addresses.map((s, i) => (
          <Selection
            address={s}
            key={i}
            index={i}
            handleChange={handleChange}
            handleLinkClick={handleLinkClick}
            checked={delivery['checked']?.[i]}
          />
        ))
      : [null];

  const requiredLabel = props.required ? <RequiredLabel>必須</RequiredLabel> : null;
  return (
    <AddressSelectCon>
      <InputBoxTitle>配送先{requiredLabel}</InputBoxTitle>
      {selections}
      <Link to="/lp-legacy/address">
        <AddInput text="別の配達先を追加する"></AddInput>
      </Link>
      {/* <div>selectedAddress : <AddInput text={delivery['selectedAddress']['fName']?.value || ''}></AddInput></div> */}
    </AddressSelectCon>
  );
};

const DeliveryDateSelect = () => {
  const [delivery, setDelivery] = useRecoilState(deliveryState);
  const [showCalender, setShowCalender] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [expectedShipping, setExpectedShipping] = useState('');
  const [expectedShippingIsSale, setExpectedShippingIsSale] = useState(false);

  // 白黒12/1以降
  const cart = useRecoilValue(cartState);

  async function getExpectedShipping() {
    const docRef = doc(db, 'stores', 'sale');
    const docSnap = await getDoc(docRef);
    // console.log(docSnap.data().description);

    return docSnap.data();
  }
  const promise = getExpectedShipping();

  // eslint-disable-next-line no-console
  promise.then((response) => setExpectedShippingIsSale(response.isSale)).catch((error) => console.log(error));
  // eslint-disable-next-line no-console
  promise.then((response) => setExpectedShipping(response.description)).catch((error) => console.log(error));

  const showCalenderToggle = () => {
    setShowCalender(!showCalender);
  };
  const showDateSelectionToggle = () => {
    setShowDateSelection(!showDateSelection);
  };

  const updateDate = (date, fulldate) => {
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      if (expectedShippingIsSale) {
        future['dateTime']['fulldate'] = '指定なし';
        future['dateTime']['date'] = '指定なし';
      } else {
        future['dateTime']['fulldate'] = fulldate;
        future['dateTime']['date'] = date;
      }
      return future;
    });
  };

  const updateClockTime = (time) => {
    showDateSelectionToggle();
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['dateTime']['clockTime'] = time;
      return future;
    });
  };

  const clockTime = ['指定なし', 8, 14, 16, 18, 19];
  const dateSelections = clockTime.map((s, i) => (
    <DateSelection time={s} index={i} key={i} updateClockTime={(time) => updateClockTime(time)} />
  ));

  useEffect(() => {
    Util.sendRefererData('DeliveryInfo');
  }, []);

  return (
    <div>
      <DeliveryDateSelectCon className={css.mt16}>
        <InputBoxTitle>配送希望日</InputBoxTitle>
        <div onClick={showCalenderToggle} className={showCalender ? css.displayNone : css.display_true}>
          <AddInputDown text={delivery['dateTime']['fulldate']}></AddInputDown>
        </div>
        <div onClick={showCalenderToggle} className={showCalender ? css.display_true : css.displayNone}>
          <AddInputUp text={delivery['dateTime']['fulldate']}></AddInputUp>
        </div>
        <div className={showCalender ? css.display_true : css.displayNone}>
          <CalenderBr
            updateDate={updateDate}
            selected={delivery['dateTime']['fulldate']}
            expectedShipping={expectedShipping}
            expectedShippingIsSale={expectedShippingIsSale}
            // 白黒12/1以降
            cart={cart}
          />
        </div>
      </DeliveryDateSelectCon>
      <DeliveryDateSelectCon className={css.mt16}>
        <InputBoxTitle>配送希望時間</InputBoxTitle>
        <div onClick={showDateSelectionToggle} className={showDateSelection ? css.displayNone : css.display_true}>
          <AddInputDown text={delivery['dateTime']['clockTime']}></AddInputDown>
        </div>
        <DateSelectionsCon className={showDateSelection ? css.display_true : css.displayNone}>
          {dateSelections}
        </DateSelectionsCon>
      </DeliveryDateSelectCon>
    </div>
  );
};

const CalenderBr = (props) => {
  let addDay;
  let numberOfDates;

  let calendarSelectStartDate;
  let calendarSelectEndDate;
  let calendarMonth;
  let calendarYear;
  let startDateOfTheDateToDisplay;
  const today = dayjs();

  // M#MO: 商品販売開始日数指定の処理を残す
  // const cart = useRecoilValue(cartState);
  // const cartItems = cart.items;
  // if (cartItems.uid16.count > 0 || cartItems.uid17.count > 0 || cartItems.uid24.count > 0) {
  //   // 12/1販売開始
  //   numberOfDates = 29;

  //   calendarSelectStartDate = dayjs('2023-12-5');
  //   calendarSelectEndDate = dayjs('2023-12-31');
  //   calendarYear = 2023;
  //   calendarMonth = 12;

  //   startDateOfTheDateToDisplay = calendarSelectStartDate.startOf('week');
  // } else {
  // 12/1販売開始以外
  numberOfDates = 14;
  addDay = 4;

  calendarSelectStartDate = today.add(addDay, 'd');
  calendarSelectEndDate = today.add(addDay + 7, 'd');

  calendarYear = calendarSelectStartDate.format('YYYY');
  calendarMonth = calendarSelectStartDate.format('MM');

  startDateOfTheDateToDisplay = today.startOf('week');
  // }

  const doNothing = () => {};
  const items = [];

  // カレンダーに表示する頭の日付と購入可能日の差が7日以上ある場合はカレンダーを7日間のみ表示させる
  if (calendarSelectStartDate.diff(startDateOfTheDateToDisplay, 'day') === 6) {
    startDateOfTheDateToDisplay = today.add(5, 'd');
    numberOfDates = 7;
  } else if (calendarSelectStartDate.diff(startDateOfTheDateToDisplay, 'day') > 6) {
    numberOfDates = 21;
  }

  for (let i = 0; i < numberOfDates; i++) {
    const loopDays = startDateOfTheDateToDisplay.add(i, 'd');
    const incrementDate = startDateOfTheDateToDisplay.add(i, 'd');
    const day = startDateOfTheDateToDisplay.add(i, 'd').date();

    const isSelectableDate = () => {
      if (props.expectedShippingIsSale) return false;
      return calendarSelectStartDate.format() <= loopDays.format() &&
        loopDays.format() <= calendarSelectEndDate.format()
        ? true
        : false;
    };

    if (isSelectableDate()) {
      items.push(
        <DateIcon
          day={day}
          isInPeriod={true}
          key={i}
          updateDate={props.updateDate}
          fulldate={`${incrementDate.format('YYYY')}年${incrementDate.format('MM')}月${day}日`}
          selected={props.selected}
          date={`${incrementDate.format('YYYY')}年${incrementDate.format('MM')}月${day}日`}
        />
      );
    } else {
      items.push(
        <DateIcon
          day={day}
          isInPeriod={false}
          key={i}
          updateDate={() => doNothing()}
          selected={props.selected}
          date={`${incrementDate.format('YYYY')}年${incrementDate.format('MM')}月`}
        />
      );
    }
  }
  const dateIcons = items;

  return (
    <div>
      <div className={classNames(css.calender_br, props.expectedShippingIsSale && css.calender_mask)}>
        <div className={css.title}>
          {calendarYear}年{calendarMonth}月
        </div>
        <div className={css.days_of_week}>
          <div className={css.day_of_week}>日</div>
          <div className={css.day_of_week}>月</div>
          <div className={css.day_of_week}>火</div>
          <div className={css.day_of_week}>水</div>
          <div className={css.day_of_week}>木</div>
          <div className={css.day_of_week}>金</div>
          <div className={css.day_of_week}>土</div>
        </div>
        <div className={css.date_icons}>{dateIcons}</div>
      </div>
      {props.expectedShippingIsSale ? (
        <div className={css.calender_alert}>
          ※大変申し訳ありませんが、現在ご注文が殺到しており、日付指定は一時的にできません。
          <br />
          製造が出来次第の発送になります。
          {/* <p className={css.sale__date}>現在の見込み発送日時は、ご購入から{props.expectedShipping}日後です。</p> */}
          <p className={css.sale__date}>現在の見込み発送日時は、ご購入から20日後です。</p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const DateIcon = (props) => {
  const isSelected = props.date === props.selected;

  const handleClick = (day, fulldate) => {
    isSelected ? props.updateDate('指定なし', '指定なし') : props.updateDate(day, fulldate);
  };

  return (
    <div
      className={css.date_iconCon}
      onClick={() => {
        handleClick(props.day, props.fulldate);
      }}
    >
      <div className={css.date_icon}>
        <div
          className={`${props.isInPeriod ? css.in : css.out}
          ${isSelected ? css.selected : css.not}`}
        >
          {props.day}
        </div>
      </div>
    </div>
  );
};

export function DeliveryInfo() {
  const user = useRecoilValue(userState);

  const firstView = useRecoilValue(firstViewState);
  const navigate = useNavigate();
  useEffect(() => {
    if (!firstView.visited) {
      navigate('/lp-legacy');
    }
  }, []);

  return (
    <div>
      <div className={css.input_info_con}>
        <FormHeader title="配送" step="step2" />
        <DeliveryInfoForm>
          <AddressSelect required={true} />
          <DeliveryDateSelect />
          <BottomButtons className={css.fontJp}>
            <Link to="/lp-legacy/payment">
              <BoxButton text="決済情報の入力へ進む" disabled={!user.accountInfoIsValid} />
            </Link>
            <Link to="/lp-legacy/customer">
              <TextButton text="お客様情報に戻る" />
            </Link>
          </BottomButtons>
        </DeliveryInfoForm>
      </div>
    </div>
  );
}
