import { getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { db } from '../../zz_general/utils/configs/firebase';

export const getAddLPProduct = async () => {
  return await getDocs(collection(db, 'LP', 'LP', 'items'));
};
export const getLpProduct = async () => {
  return await getDocs(collection(db, 'items'));
};

export const contactCollectionCreate = async (data) => {
  const dbContactCollection = collection(
    db,
    'LP',
    process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
    'contact'
  );

  try {
    await setDoc(doc(dbContactCollection), { data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
  return true;
};
