import { useEffect, useState } from 'react';
import { Wrapper } from './scss';
import { importAll } from '../../utils/image';
import { useRecoilState } from 'recoil';
import { addLPDeliveryState } from '../../store/atoms';

export const Calender = ({ className, width, startDate, endDate, selectedDate, setSelectedDate }) => {
  const [Pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [maxAmountOfDates, setMaxAmountOfDates] = useState(0);

  useEffect(() => {
    const PageList = [];
    let page = 1;
    for (
      let endOfMonth = startDate.endOf('month');
      endOfMonth.format() <= endDate.endOf('month').format();
      endOfMonth = endOfMonth.add(1, 'M').endOf('month')
    ) {
      const year = endOfMonth.year();
      const month = endOfMonth.month() + 1;
      const startOfPage = endOfMonth.startOf('month').startOf('week');
      const endOfPage = endOfMonth.endOf('week');
      const isFinalPage = endDate.isBefore(endOfMonth);
      const startOfSelect = startOfPage.isAfter(startDate) ? startOfPage : startDate;
      const endOfSelect = endOfPage.isBefore(endDate) ? endOfPage : endDate;
      const startToDisplay = startOfSelect.startOf('week');
      const endToDisplay = endOfSelect.endOf('week');
      const amountOfDates = endToDisplay.diff(startToDisplay, 'd') + 1;
      PageList.push(
        <Page
          key={page}
          page={page}
          year={year}
          month={month}
          isFinalPage={isFinalPage}
          endOfMonth={endOfMonth}
          startOfSelect={startOfSelect}
          endOfSelect={endOfSelect}
          startToDisplay={startToDisplay}
          amountOfDates={amountOfDates}
          maxAmountOfDates={maxAmountOfDates}
          setSelectedPage={setSelectedPage}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          width={width}
        />
      );
      page += 1;
    }
    setPages(PageList);
  }, [selectedPage, selectedDate, startDate, endDate, maxAmountOfDates]);

  useEffect(() => {
    let max = 0;
    for (
      let endOfMonth = startDate.endOf('month');
      endOfMonth.format() <= endDate.endOf('month').format();
      endOfMonth = endOfMonth.add(1, 'M').endOf('month')
    ) {
      const startOfPage = endOfMonth.startOf('month').startOf('week');
      const endOfPage = endOfMonth.endOf('week');
      const startOfSelect = startOfPage.isAfter(startDate) ? startOfPage : startDate;
      const endOfSelect = endOfPage.isBefore(endDate) ? endOfPage : endDate;
      const startToDisplay = startOfSelect.startOf('week');
      const endToDisplay = endOfSelect.endOf('week');
      const amountOfDates = endToDisplay.diff(startToDisplay, 'd') + 1;
      if (amountOfDates > max) {
        max = amountOfDates;
      }
    }
    setMaxAmountOfDates(max);
  }, [startDate, endDate]);

  return <div className={className}>{Pages[selectedPage - 1]}</div>;
};

export const Page = ({
  page,
  year,
  month,
  endOfMonth,
  startOfSelect,
  endOfSelect,
  startToDisplay,
  amountOfDates,
  maxAmountOfDates,
  isFinalPage,
  setSelectedPage,
  selectedDate,
  setSelectedDate,
  width,
}) => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const [dateIcons, setDateIcons] = useState([]);

  useEffect(() => {
    const dateList = [];
    let loopDays = startToDisplay;
    for (let i = 0; i < amountOfDates; i++) {
      const isInPeriod = startOfSelect.format() <= loopDays.format() && loopDays.format() <= endOfSelect.format();
      const isInSameMonth = loopDays.format('MM') === endOfMonth.format('MM');
      const isSelected = selectedDate?.format() === loopDays.format();
      dateList.push(
        <DateIcon
          key={i}
          day={loopDays}
          isInPeriod={isInPeriod}
          isSelected={isSelected}
          isInSameMonth={isInSameMonth}
          setSelectedDate={setSelectedDate}
        />
      );
      loopDays = loopDays.add(1, 'd');
    }
    setDateIcons(dateList);
  }, [selectedDate, startOfSelect, endOfSelect]);

  return (
    <Wrapper width={width} lines={amountOfDates / 7} maxLines={maxAmountOfDates / 7}>
      <div className="titleWrap">
        <img
          src={image['arrow_down.svg']}
          className="left"
          style={{ opacity: page === 1 ? 0 : 1 }}
          onClick={() => {
            page === 1 || setSelectedPage(page - 1);
          }}
        />
        <div className="title">
          {year}年{month}月
        </div>
        <img
          src={image['arrow_down.svg']}
          className="right"
          style={{ opacity: isFinalPage ? 0 : 1 }}
          onClick={() => {
            isFinalPage || setSelectedPage(page + 1);
          }}
        />
      </div>
      <div className="dayOfWeekWrap">
        <div className="dayOfWeek">日</div>
        <div className="dayOfWeek">月</div>
        <div className="dayOfWeek">火</div>
        <div className="dayOfWeek">水</div>
        <div className="dayOfWeek">木</div>
        <div className="dayOfWeek">金</div>
        <div className="dayOfWeek">土</div>
      </div>
      <div className="dateIcons">{dateIcons}</div>
    </Wrapper>
  );
};

const DateIcon = ({ day, isInPeriod, isSelected, isInSameMonth, setSelectedDate }) => {
  return (
    <div
      className="dateIconCon"
      onClick={() => {
        if (isSelected) {
          setSelectedDate(null);
        } else {
          isInPeriod && setSelectedDate(day);
        }
      }}
    >
      <div className="dateIcon">
        <div
          className={`${isInPeriod ? 'inPeriod' : 'outOfPeriod'} ${isSelected && 'selected'} ${
            isInSameMonth || 'outOfMonth'
          }`}
        >
          {day.date()}
        </div>
      </div>
    </div>
  );
};
