import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../zz_general/utils/configs/firebase';
import { consoleError, consoleLog } from '../../../zz_general/utils/snippets/console';
import { isProd } from '../../../zz_general/utils/snippets/env';
import dayjs from 'dayjs';
import { convertToDayjs } from '../../utils/date';

const discount = {
  0: -100,
  1: -100,
  2: -100,
  3: -200,
  4: -200,
  5: -500,
  6: -600,
  7: -777,
  8: -888,
  9: -999,
};

export const fetchCoupon = async (userId, attemptId) => {
  let attempt;

  try {
    const docRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'users', userId, 'quizAttempts', attemptId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      return null;
    }
    attempt = docSnap.data();
  } catch (error) {
    consoleError('Error logging in: ', error);
    return null;
  }

  if (attempt.couponLink.isUsed) {
    return null;
  }

  const expirationDate = convertToDayjs(attempt.attemptTime).add(1, 'week').format('YYYY-MM-DD:HH:mm:ss');

  const now = dayjs().format('YYYY-MM-DD:HH:mm:ss');
  if (now > expirationDate) {
    return null;
  }

  return {
    value: discount[attempt.quizData.score],
    expirationDate: expirationDate,
    score: attempt.quizData.score,
  };
};

export const useCoupon = async (userId, attemptId) => {
  try {
    const docRef = doc(
      db,
      'quiz',
      process.env.REACT_APP_IS_PROD === 'true' ? 'quiz' : 'quiz-test',
      'users',
      userId,
      'quizAttempts',
      attemptId
    );
    await updateDoc(docRef, {
      couponLink: {
        isUsed: true,
      },
    });
  } catch (error) {
    consoleError('Error logging in: ', error);
  }
};
