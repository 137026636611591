import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from './congrats.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import { QuizWrapper } from '../components/quizWrapper';
import { isProd, publicUrl } from '../../zz_general/utils/snippets/env';
import { useRecoilValue } from 'recoil';
import { QuizUserState } from '../store/atom';
import { changePage } from '../utils/navigate';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { consoleError } from '../../zz_general/utils/snippets/console';

const imgPath = (fileName) => publicUrl + '/quiz/congrats/images/' + fileName;

const text = (num) => `10問連続クリアで豪華賞品をゲット出来る、ティラミスにまつわる超難問クイズイベント開催中！

ティラミスを愛し、ティラミスに愛されるものは誰か

現在の10問連続正解者は${num - 5}人

挑戦求む！`;

export const QuizCongrats = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const user = useRecoilValue(QuizUserState);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [achievedUser, setAchievedUser] = useState(0);

  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  const handleTwitterShare = () => {
    const url = '\n\nhttps://bene-regalo.com/quiz\n\n'; // Replace with your actual URL
    const hashtags = 'ティラミスクイズ';

    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text(achievedUser)
    )}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(hashtags)}`;

    window.open(twitterIntentUrl, '_blank');
  };

  const fetchAchievedUser = async () => {
    try {
      const collectionRef = collection(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'users');
      const snapshot = await getDocs(collectionRef);

      let count = 0;

      snapshot.forEach((doc) => {
        if (doc.data().achieved === true) {
          count++;
        }
      });

      setAchievedUser(count);
      setIsPageLoading(false);
    } catch (e) {
      consoleError('Error Fetching achieved users: ', e);
      setIsPageLoading(false);
      return;
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      alert('ブラウザバックボタンが押されたため、TOP画面にリダイレクトします。');
      changePage(navigate, '', 'congrats');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    setIsPageLoading(true);
    !user.loggedIn && changePage(navigate, '', 'congrats');
    query.get('prev') !== 'success-prize' && changePage(navigate, '', 'congrats');
    fetchAchievedUser();

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={isPageLoading}
    >
      <Wrapper
        className="congrats"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <div className="congratsWrap">
          <div className="all_ques correct"> 全問正解 </div>
          <div className="all_ques"> おめでとうございます </div>
        </div>
        <div className="to_be_contacted">
          受け取り方法などの詳細については、BENE REGALO 運営よりメールにてご連絡いたします。
        </div>
        <div className="please">お願い</div>
        <div className="no_spreading_ans">問題や解答などは、拡散しないようご協力お願いいたします。</div>
        <div className="spread_word">
          {' '}
          ティラミスクイズの拡散に <br /> ご協力ください{' '}
        </div>
        <div>
          <img src={imgPath('xIcon.webp')} alt="cross" className="cross" onClick={handleTwitterShare} />
        </div>
        <div className="btn_container">
          <button
            onClick={() => {
              navigate('/');
            }}
            className="top_button"
            style={{}}
          >
            <span className="bene_regalo"> BENE REGALO TOP </span>
          </button>
        </div>
      </Wrapper>
    </QuizWrapper>
  );
};
