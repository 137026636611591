import { publicUrl } from '../../zz_general/utils/snippets/env';
import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

const imgPath = (fileName) => publicUrl + '/quiz/successPrize/images/' + fileName;

export const Wrapper = styled.div`
  &.successPrize {
    .all_correct {
      position: relative;
      z-index: 1;
      text-align: center;
      color: #fff;
      font-family: 'Hiragino Kaku Gothic Std';
      font-weight: 800;
      -webkit-text-stroke: 3px #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.14]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.064),
          marginTop: multiplyArrays(props.bodyWidth, [0.27, 0.2, 0.15]),
        });
      }}
      /* Adjust positioning of red circle */
      &::before {
        content: '';
        background-image: url(${imgPath('red_circle.webp')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        ${(props) => {
          return responsiveStyle({
            width: multiplyArrays(props.bodyWidth, [0.267, 0.23, 0.4]),
            height: multiplyArrays(props.bodyWidth, [0.267, 0.23, 0.2]),
            marginTop: multiplyArrays(props.bodyWidth, [-0.1, -0.09, -0.07]),
          });
        }}
      }
    }
    .congrats {
      text-align: center;
      color: #fff;
      font-family: 'Hiragino Kaku Gothic Std';
      font-weight: 800;
      -webkit-text-stroke: 2px #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.082, 0.08]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.064),
          marginTop: multiplyArrays(props.bodyWidth, [0.2, 0.15, 0.15]),
        });
      }}
    }
    .items_remain {
      color: #fff;
      font-weight: 800;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.082, 0.08]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.107),
          marginTop: multiplyArrays(props.bodyWidth, [0.1, 0.08, 0.08]),
        });
      }}
      .number_text {
        font-family: 'Noto Sans JP';
        -webkit-text-stroke: calc(0.06vw + 1.2px) #5f2f17;
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
          });
        }}
      }
      .items_text {
        font-family: 'Hiragino Kaku Gothic Std';
        -webkit-text-stroke: calc(0.04vw + 1px) #5f2f17;
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.062, 0.06]),
          });
        }}
      }
    }
    .prizeSlide {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '175%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-20%' : '0')};
    }
    .warningMessage {
      text-align: center;
      color: #fe163e;
      font-family: 'Kiwi Maru';
      font-weight: 500;
      -webkit-text-stroke: calc(0.02vw + 0.1px) #f0dcaa;
      margin-top: 16px;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.042, 0.038, 0.036]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.032),
        });
      }}
    }
    .navButton {
      // background: none;
      // border: none;
      cursor: pointer;
      z-index: 10;
    }
    .previous {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
          height: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
        });
      }}
    }
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
          height: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
        });
      }}
    }
    .prize_topText {
      font-family: 'Hiragino Kaku Gothic Std';
      font-weight: 800;
      -webkit-text-stroke: calc(0.03vw + 0.1px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.056]),
          marginTop: multiplyArrays(props.bodyWidth, [0.02, 0.02, 0.02]),
        });
      }}
    }
    .prize_botText {
      /* -webkit-text-stroke: calc(0.01vw + 0.05px) #fff; */
      font-family: 'Hiragino Kaku Gothic Pro';
      /* font-family: 'AvenirLTStd-Black'; */
      font-weight: 300;
      /* -webkit-text-stroke: 1px #fff; */
      /* text-shadow: 0 0 5px red; */
      background-color: white;
      border-radius: 10px;

      text-shadow: ${() => {
        const textShadow = '5px';
        const color = '#fff';
        return `0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color},0 0 ${textShadow} ${color}`;
      }};
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.042, 0.038, 0.038]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.042, 0.038, 0.038]),
        });
      }}
    }
    .imageContainer {
      position: relative;
      display: inline-block;
    }
    .greenCircle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.14]),
          height: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.14]),
        });
      }}
    }
    .prize_details {
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.6, 0.45, 0.45]),
          height: multiplyArrays(props.bodyWidth, [0.38, 0.28, 0.28]),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.01),
        });
      }}
    }
    .buttonWrapper {
      ${(props) => {
        return responsiveStyle({
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.05),
          paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
    }
    .confirm_button {
      border: ${(props) => `${multiplyArrays(props.bodyWidth, [0.027, 0.017, 0.01])[0]}px solid #5F2F17`};
      background: #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.85, 0.75, 0.65])
              : multiplyArrayByScalar(props.bodyWidth, 0.95),
          height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
          // marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.05, 0.03]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
        });
      }}
      &.notAvailable {
        background-color: #d9d9d9;
        border-color: #a7a7a7;
      }
    }
    .confirm_button.warning {
      border-color: #fe163e;
    }
    .confirm_prize {
      color: #fff;
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 800;
      -webkit-text-stroke: calc(0.05vw + 1px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.064),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.08),
        });
      }}
      &.notAvailable {
        -webkit-text-stroke: calc(0.05vw + 1px) #a7a7a7;
      }
    }
  }
`;
