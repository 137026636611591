import { Wrapper } from './scss';

export const Loading = () => {
  return (
    <Wrapper>
      <div className="loading"></div>
      <div className="loadingText">Loading...</div>
    </Wrapper>
  );
};
