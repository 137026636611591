import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

export const CountdownTimer = ({ targetDate, className }) => {
  const [timeLeft, setTimeLeft] = useState(dayjs(targetDate).diff(dayjs()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = dayjs();
      const diff = dayjs(targetDate).diff(now);
      setTimeLeft(diff);
    }, 1);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const seconds = String(Math.floor(timeLeft / 1000) % 60).padStart(2, '0');
  const minutes = String(Math.floor(timeLeft / 1000 / 60) % 60).padStart(2, '0');
  const hours = String(Math.floor(timeLeft / 1000 / 60 / 60) % 24).padStart(2, '0');

  // 日の部分はゼロを消すように修正
  const days =
    Math.floor(timeLeft / 1000 / 60 / 60 / 24) < 10
      ? String(Math.floor(timeLeft / 1000 / 60 / 60 / 24))
      : String(Math.floor(timeLeft / 1000 / 60 / 60 / 24)).padStart(2, '0');

  return (
    <div className={className}>
      {days}
      <span>日</span>
      {hours}
      <span>時間</span>
      {minutes}
      <span>分</span>
      {seconds}
      <span>秒</span>
    </div>
  );
};
