import { StyledButton, useStyledDiv, StyledDiv } from '../../utils/styled';
import { Loader } from '../../snippets/loader';

const [Wrapper] = useStyledDiv();

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

const calcWithoutBorder = (list) => {
  return [list[0] - 2, list[1] - 2, list[2] - 2];
};

export const LinkButton = ({ className, width, height, text, color, arrow, active, loading, onClick }) => {
  return (
    <Wrapper
      style={{
        position: 'relative',
      }}
      responsive={{
        width: width,
        height: height,
      }}
    >
      <StyledButton
        className={className}
        css={`
          display: block;
          border: 2px solid ${active ? '#161C1C' : '#C4C4C4'} !important;
          color: ${color === 'black' ? '#FFFFFF' : active ? '#161C1C' : '#C4C4C4'};
          background-color: ${color == 'white' ? '#FFFFFF' : active ? '#161C1C' : '#C4C4C4'};
          cursor: pointer;
          appearance: none;
          &:active {
            background-color: ${active && !loading && '#FFFFFF'};
            color: ${active && !loading && '#161C1C'};
            position: ${active && !loading && 'relative'};
            z-index: 1;
          }
        `}
        responsive={{
          top: multiplyList(height, 0.04),
          width: width,
          height: height,
          fontSize: calcWithoutBorder(multiplyList(height, 0.34)),
          lineHeight: calcWithoutBorder(multiplyList(height, 0.34)),
          padding: calcWithoutBorder(multiplyList(height, 0.33)),
          borderRadius: multiplyList(height, 0.125),
        }}
        onClick={() => {
          active && onClick();
        }}
      >
        {active && loading ? (
          <StyledDiv
            responsive={{
              width: calcWithoutBorder(multiplyList(height, 0.34)),
            }}
            css={`
              margin: 0 auto;
            `}
          >
            <Loader
              color={color === 'white' ? '#161C1C' : '#FFFFFF'}
              size={calcWithoutBorder(multiplyList(height, 0.34))}
            />
          </StyledDiv>
        ) : (
          <>
            <span
              style={{
                fontWeight: 'bold',
                display: arrow === 'left' ? 'inline-block' : 'none',
              }}
            >
              {'<'}&nbsp;
            </span>
            {text}
            <span
              style={{
                fontWeight: 'bold',
                display: arrow === 'right' ? 'inline-block' : 'none',
              }}
            >
              &nbsp;{'>'}
            </span>
          </>
        )}
      </StyledButton>
    </Wrapper>
  );
};
