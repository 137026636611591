import styled from 'styled-components';
import { responsiveStyle, StyledDiv } from '../../zz_general/utils/scss-modules/styled';

export const Wrapper = styled(StyledDiv)`
  margin: 0 auto;
  padding: 4vw 0;
  ${responsiveStyle({
    width: [340, 512, 720],
  })}
  h1 {
    text-align: center;
    width: 100%;
  }
  .marginTop {
    margin-top: 2vw;
  }

  .errorMessage {
    color: red;
  }
`;
