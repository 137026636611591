import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.congrats {
    .congratsWrap {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '150%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-16.67%' : '0')};

      .all_ques {
        color: #fff;
        -webkit-text-stroke: calc(0.1vw + 1px) #5f2f17;
        text-align: center;
        margin-top: 10vh;
        margin: ${(props) => props.screenOrientation === 'vertical' && '0 auto'};
        ${(props) => {
          return responsiveStyle({
            fontSize:
              props.screenOrientation === 'vertical'
                ? multiplyArrayByScalar(props.bodyWidth, 0.088)
                : multiplyArrayByScalar(props.bodyWidth, 0.102),
            lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
            height: multiplyArrayByScalar(props.bodyWidth, 0.032),
            marginTop:
              props.screenOrientation === 'horizontal'
                ? multiplyArrays(props.bodyWidth, [0.09, 0.09, 0.08])
                : multiplyArrays(props.bodyWidth, [0.06, 0.07, 0.04]),
            width: props.screenOrientation === 'vertical' && multiplyArrays(props.bodyWidth, [0.9, 0.8, 0.8]),
          });
        }}
      }
      .correct {
        ${(props) => {
          return responsiveStyle({
            marginTop: props.screenOrientation === 'vertical' && multiplyArrayByScalar(props.bodyWidth, 0.14),
          });
        }}
      }
    }
    .to_be_contacted {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '150%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-16.67%' : '0')};
      margin: ${(props) => props.screenOrientation === 'vertical' && '0 auto'};
      text-align: left;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.064),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.18),
          width: props.screenOrientation === 'vertical' && multiplyArrays(props.bodyWidth, [0.9, 0.7, 0.7]),
        });
      }}
    }
    .please {
      color: #fff;
      -webkit-text-stroke: calc(0.1vw + 1px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.072),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.14),
        });
      }}
    }
    .no_spreading_ans {
      -webkit-text-stroke: calc(0.02vw + 0.25px) #f0dcaa;
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '160%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-16.67%' : '0')};
      margin: ${(props) => props.screenOrientation === 'vertical' && '0 auto'};
      text-align: left;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.056),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.08),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.04),
          width: props.screenOrientation === 'vertical' && multiplyArrays(props.bodyWidth, [0.9, 0.7, 0.7]),
        });
      }}
    }
    .spread_word {
      -webkit-text-stroke: calc(0.02vw + 0.15px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.056),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.056),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
        });
      }}
    }
    .cross {
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.18, 0.16, 0.14]),
          height: multiplyArrays(props.bodyWidth, [0.18, 0.16, 0.14]),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
        });
      }}
    }
    .btn_container {
      ${(props) => {
        return responsiveStyle({
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.04]),
          paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
    }
    .top_button {
      border: ${(props) => `${multiplyArrays(props.bodyWidth, [0.027, 0.017, 0.01])[0]}px solid #5F2F17`};
      background: #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.85, 0.75, 0.65])
              : multiplyArrayByScalar(props.bodyWidth, 0.95),
          height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
          marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.05, 0.03]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
        });
      }}
    }
    .bene_regalo {
      color: #fff;
      font-family: 'Noto Sans JP';
      font-weight: 800;
      -webkit-text-stroke: calc(0.08vw + 0.75px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.056),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.06),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
        });
      }}
    }
  }
`;
