import { doc, getDoc } from 'firebase/firestore';

// MEMO : 6桁の英数字IDを生成する関数
const generateId = (digits) => {
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabdefghijkmnpqrtuy23456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < digits; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// MEMO : 指定されたコレクションにおいて、ユニークなIDを生成する関数
export const generateUniqueID = async (collectionRef, digits) => {
  let unique = false;
  let newId;

  while (!unique) {
    newId = generateId(digits);
    const docRef = doc(collectionRef, newId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      unique = true;
    }
  }

  return newId;
};
