import { StyledDiv, responsiveStyle } from '../../utils/styled';
import styled from 'styled-components';

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

export const Wrapper = styled(StyledDiv)`
  border: solid 1px #c4c4c4;
  margin: 0 auto;

  ${(props) => {
    return responsiveStyle({
      width: props.width,
      padding: multiplyList(props.width, 0.05),
      borderRadius: multiplyList(props.width, 0.025),
    });
  }}

  .titleWrap {
    display: flex;
    justify-content: space-between;
    ${(props) => {
      return responsiveStyle({
        fontSize: multiplyList(props.width, 0.05),
        lineHeight: multiplyList(props.width, 0.05),
        height: multiplyList(props.width, 0.1),
        paddingBottom: multiplyList(props.width, 0.05),
      });
    }}
    .right {
      cursor: pointer;
      transform: rotate(-90deg);
      ${(props) => {
        return responsiveStyle({
          width: multiplyList(props.width, 0.1),
        });
      }}
    }
    .left {
      cursor: pointer;
      transform: rotate(90deg);
      ${(props) => {
        return responsiveStyle({
          width: multiplyList(props.width, 0.1),
        });
      }}
    }
  }

  .dayOfWeekWrap {
    display: flex;
    justify-content: space-around;
    ${(props) => {
      return responsiveStyle({
        fontSize: multiplyList(props.width, 0.05),
        lineHeight: multiplyList(props.width, 0.05),
        height: multiplyList(props.width, 0.1),
        paddingBottom: multiplyList(props.width, 0.05),
      });
    }}
  }

  .dateIcons {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    ${(props) => {
      return responsiveStyle({
        height: multiplyList(props.width, 0.12 * props.maxLines),
        paddingBottom: multiplyList(props.width, 0.12 * (props.maxLines - props.lines)),
      });
    }}
  }

  .dateIconCon {
    text-align: center;
    margin: 0 auto;
    .dateIcon {
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyList(props.width, 0.05),
          lineHeight: multiplyList(props.width, 0.08),
          width: multiplyList(props.width, 0.08),
          height: multiplyList(props.width, 0.08),
        });
      }}
      .inPeriod {
        outline: solid 1px #161c1c;
        border-radius: 50%;
        color: #161c1c;
        background-color: #ffffff;
        transition: background-color 0.3s, color 0.3s;
      }

      .outOfPeriod {
        color: #c4c4c4;
        border-radius: 50%;
      }

      .outOfMonth {
        color: #c4c4c4;
      }

      .selected {
        color: #ffffff;
        background-color: #161c1c;
        transition: background-color 0.3s, color 0.3s;
      }
    }
  }
`;
