// modalRechallenge.js
import React, { useState, useEffect } from 'react';
import css from './modalRechallenge.module.scss';

export default function Modal({ onSoundChange, onClosePopup, handleSubmit }) {
  const [modalRechallenge, setModalRechallenge] = useState(true);

  const handleSoundChange = (enabled) => {
    // setSoundEnabled(enabled);
    onSoundChange(enabled); // Notify parent component
    if (enabled) {
      // audio.play();
    }
  };

  const closePopup = () => {
    setModalRechallenge(false);
    onClosePopup();
  };

  if (modalRechallenge) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <>
      {modalRechallenge && (
        <div className={css.modal}>
          <div className={css.overlay}></div>
          <div className={css.modal_content} onClick={(e) => e.stopPropagation()}>
            <span className={css.modal_text1}>チャレンジ申請を受け付けました。</span>
            <span className={css.modal_text2}>貴重な挑戦権を有意義に ご活用ください。</span>
            <div className={css.submitContainer}>
              <button
                className={css.modal_button}
                onClick={() => {
                  window.open(`${window.location.origin}/quiz`);
                }}
              >
                ティラミスクイズTOPへ
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
