const camelToKebab = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const responsiveStyle = (styles) => {
  return Object.entries(styles)
    .map(
      ([key, value]) => `
        ${camelToKebab(key)}: calc(${value[0]} / 375 * 100vw);
        @media (min-width: 375px){
          ${camelToKebab(key)}: calc(
            (${value[1]} - ${value[0]}) * 100vw / (834 - 375)
            + (${value[0]} * 834 - ${value[1]} * 375) * 1px / (834 - 375)
          );
        }
        @media (min-width: 834px) {
          ${camelToKebab(key)}: calc(
            (${value[2]} - ${value[1]}) * 100vw / (1440 - 834)
            + (${value[1]} * 1440 - ${value[2]} * 834)* 1px / (1440 - 834)
          );
        }
        @media (min-width: 1440px){
          ${
            value[1] === value[2]
              ? `${camelToKebab(key)}: ${value[2]};`
              : `${camelToKebab(key)}: calc(${value[2]} / 1440 * 100vw);`
          }
        }
      `
    )
    .join('');
};

export const multiplyArrays = (array1, array2) => {
  return [array1[0] * array2[0], array1[1] * array2[1], array1[2] * array2[2]];
};

export const multiplyArrayByScalar = (array, num) => {
  return [array[0] * num, array[1] * num, array[2] * num];
};
