import { Wrapper } from './explanation.scss';
import { QuizHeader } from '../components/quizHeader';
import { QuizWrapper } from '../components/quizWrapper';
import { env, publicUrl } from '../../zz_general/utils/snippets/env';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { changePage } from '../utils/navigate';

const imgPath = (fileName) => publicUrl + '/quiz/explanation/images/' + fileName;

export const QuizExplanation = () => {
  const navigate = useNavigate();
  const state = 'login';
  const callBackUrl = encodeURI(`${window.location.origin}/quiz/start`);
  const url =
    env === 'dev'
      ? `${window.location.origin}/quiz/start?state=${state}`
      : `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657393544&redirect_uri=${callBackUrl}&state=${state}&bot_prompt=aggressive&scope=profile%20openid%20email&`;

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);

  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={false}
    >
      <Wrapper
        className="explanation"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <QuizHeader
          onClick={() => {
            changePage(navigate, 'code-input', 'explanation');
          }}
          title="ルール説明"
        />
        <div className="rule">
          <p className="ruleGrid">
            <span>1.</span>問題は全10問
          </p>
          <p className="ruleGrid">
            <span>2.</span>それぞれ制限時間あり
          </p>{' '}
          <p className="ruleGrid">
            <span>3.</span>不正解の時点で終了
          </p>{' '}
          <p className="ruleGrid">
            <span>4.</span>不正解の場合、正答は表示されません。
          </p>
          <p className="ruleGrid">
            <span>5.</span>チャレンジは1人1回（追加方法あり）
          </p>
          <p className="ruleGrid">
            <span>6.</span>不正と判明した際は無効
          </p>
          <p className="ruleGrid">
            <span>7.</span>LINEとの連携が必須
          </p>
        </div>
        <div className="notesTitle">お願い</div>
        <div className="notes">多くの人に楽しくチャレンジをして欲しいので、問題や正答の公開や拡散はお控えください</div>
        <div className="notes2">※戻るや更新を押さないでください</div>
        <div className="tap_details">＼チャレンジには連携必須！／</div>
        <a href={url} alt="" className="">
          <div className=""></div>
          <img src={imgPath('lineLogin.svg')} alt="" className="lineButton" />
        </a>
        <div className="notes"></div>
      </Wrapper>
    </QuizWrapper>
  );
};
