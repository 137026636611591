import { useState } from 'react';
import { Wrapper } from './scss';
import { CountdownTimer } from '../../../../zz_general/components/atoms/contDown';
import { publicUrl } from '../../../../zz_general/utils/snippets/env';

const imgPath = (name) => publicUrl + '/lp/home/components/images/' + name;

export const QuizCouponPopUp = ({ cart, totalCount }) => {
  const [isShow, setIsShow] = useState(true);
  const quizCoupon = cart.items['uid38'];
  return (
    <Wrapper className="quizCoupon">
      <div
        style={{ display: isShow ? 'block' : 'none' }}
        className="backGround"
        onClick={() => {
          setIsShow(false);
        }}
      />
      <div className="popUp" style={{ display: isShow ? 'block' : 'none' }}>
        <div
          className="cross"
          onClick={() => {
            setIsShow(false);
          }}
        >
          ×
        </div>
        <div className="title">クイズチャレンジ得点 限定割引</div>
        <CountdownTimer className="countDown" targetDate={quizCoupon.expirationDate} />
        <div className="couponTicket">
          <img src={imgPath('bSymbol.webp')} />
          <div>
            <div className="ticketTitle">coupon</div>
            <div className="discount">
              <span className="price">{quizCoupon.price * -1}</span>円引き
            </div>
          </div>
        </div>
      </div>
      {totalCount === 0 && (
        <div className="cartIconBanner zeroCount">
          {quizCoupon.price * -1}
          <span>円</span>OFF
        </div>
      )}
      {totalCount > 0 && (
        <div className="cartIconBanner positiveCount">
          <div className="discountBlock">
            {quizCoupon.price * -1}
            <span>円OFF</span>
          </div>
          <div className="timerBlock">
            {/* <span className="remainingLabel">残り</span> */}
            <CountdownTimer className="countDownTimer" targetDate={quizCoupon.expirationDate} />
          </div>
        </div>
      )}
    </Wrapper>
  );
};
