import styled from 'styled-components';
import { responsiveStyle, StyledDiv } from '../../../zz_general/utils/scss-modules/styled';

export const Wrapper = styled(StyledDiv)`
  font-family: 'KiwiMaru', 'NotoSanJP';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  /* 画面の高さいっぱいに表示する */
  .loading {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #161c1c;
    border-radius: 50%;
    ${responsiveStyle({
      width: [32, 36, 40],
      height: [32, 36, 40],
    })}
    animation: spin 1s linear infinite;
  }

  .loadingText {
    ${responsiveStyle({
      marginLeft: [8, 9, 10],
      fontSize: [16, 18, 20],
    })}
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
