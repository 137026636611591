import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from './failPrize.scss';

import { db } from '../../zz_general/utils/configs/firebase';
import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { consoleError, consoleLog } from '../../zz_general/utils/snippets/console';
import { isProd, publicUrl } from '../../zz_general/utils/snippets/env';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { QuizCouponLinkState, QuizSoundState, QuizState, QuizUserState } from '../store/atom';
import { QuizWrapper } from '../components/quizWrapper';
import ReactHowler from 'react-howler';
import { changePage } from '../utils/navigate';
import { sendLarkLog } from '../../zz_general/utils/functions';

const audioPath = (fileName) => publicUrl + '/quiz/failPrize/sounds/' + fileName;
const imgPath = (fileName) => publicUrl + '/quiz/failPrize/images/' + fileName;

const text = (num) => `10問連続クリアで豪華賞品をゲット出来る、ティラミスにまつわる超難問クイズイベント開催中！

ティラミスを愛し、ティラミスに愛されるものは誰か

現在の10問連続正解者は${num - 5}人

挑戦求む！`;

export const QuizFailPrize = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const resetQuiz = useResetRecoilState(QuizState);

  const user = useRecoilValue(QuizUserState);
  const quiz = useRecoilValue(QuizState);
  const sound = useRecoilValue(QuizSoundState);
  const [couponLink, setCouponLink] = useRecoilState(QuizCouponLinkState);
  const [achievedUser, setAchievedUser] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [zannenAudio, setZannenAudio] = useState(false);
  const zannenAudioRef = useRef(null);

  const resetAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.seek(0);
    }
  };

  const handleTwitterShare = () => {
    const url = '\n\nhttps://bene-regalo.com/quiz\n\n'; // Replace with your actual URL
    const hashtags = 'ティラミスクイズ';

    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text(achievedUser)
    )}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(hashtags)}`;

    window.open(twitterIntentUrl, '_blank');
    resetQuiz();
    changePage(navigate, 'rechallenge', 'fail-prize');
  };

  const fetchAchievedUser = async () => {
    try {
      const collectionRef = collection(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'users');
      const snapshot = await getDocs(collectionRef);

      let achievedUserCount = 0;
      let userCount = 0;
      snapshot.forEach((doc) => {
        if (doc.data().achieved === true) {
          achievedUserCount++;
        }
        userCount++;
      });

      setAchievedUser(achievedUserCount);

      return [achievedUserCount, userCount];
    } catch (e) {
      consoleError('Error Fetching achieved users: ', e);
      return [0, 0];
    }
  };

  const fetchAttemptAmount = async () => {
    try {
      const collectionRef = collection(
        db,
        'quiz',
        isProd ? 'quiz' : 'quiz-test',
        'users',
        user.userInfo.sub,
        'quizAttempts'
      );
      const snapshot = await getDocs(collectionRef);

      let attemptAmount = 0;
      snapshot.forEach(() => {
        attemptAmount++;
      });

      return attemptAmount;
    } catch (e) {
      consoleError('Error Fetching achieved users: ', e);
      return 0;
    }
  };

  const updateFireStore = async (attemptAmount, achievedUser, allUser) => {
    try {
      const collectionRef = collection(
        db,
        'quiz',
        isProd ? 'quiz' : 'quiz-test',
        'users',
        user.userInfo.sub,
        'quizAttempts'
      );
      const docRef = await addDoc(collectionRef, {
        attemptTime: new Date(),
      });
      await updateDoc(docRef, {
        questionNumber: quiz.currentQuestion,
        exitReason: quiz.failureReason,
        selectedOption: quiz.selectedOption,
        isSuccess: false,
        couponLink: {
          url: `${window.location.origin}?quizUser=${user.userInfo.sub}&quizAttempt=${docRef.id}`,
          isUsed: false,
        },
        quizData: {
          score: quiz.score,
        },
      });
      setCouponLink(`${window.location.origin}?quizUser=${user.userInfo.sub}&quizAttempt=${docRef.id}`);
      consoleLog('Quiz document created successfully!');

      await sendLarkLog(
        isProd ? 'quiz_failure' : 'test',
        `以下のユーザーが${attemptAmount + 1}回目の挑戦で${quiz.currentQuestion}問目まで正解しました\n\nユーザーID : ${
          user.userInfo.sub
        }\n\n現在の合計ユーザー数 : ${allUser}人\n\n現在の10問連続正解者 : ${achievedUser}人`
      );
    } catch (e) {
      consoleError('Error creating quiz document: ', e);
      return;
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      alert('ブラウザバックボタンが押されたため、TOP画面にリダイレクトします。');
      changePage(navigate, '', 'fail-prize');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    !user.loggedIn && changePage(navigate, '', 'fail-prize');

    if (sound.enabled) {
      resetAudio(zannenAudioRef);
      setZannenAudio(true);
    }
    const async = async () => {
      const [achievedUser, allUser] = await fetchAchievedUser();
      const attemptAmount = await fetchAttemptAmount();
      if (couponLink === '') {
        await updateFireStore(attemptAmount, achievedUser, allUser);
      }
      setIsPageLoading(false);
    };
    async();

    consoleLog({
      user: user,
      quiz: quiz,
    });
  }, [quiz]);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={isPageLoading}
    >
      <ReactHowler
        src={audioPath('zannen.mp3')}
        playing={zannenAudio}
        ref={zannenAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setZannenAudio(false);
        }}
      />
      <Wrapper
        className="failPrize"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <div className="Tiramisu_jap"> ティラミスクイズ </div>
        <div className="content">
          <div className="blue_cross_container">
            <img src={imgPath('blueCross.webp')} alt="blue cross" className="blue_cross" />
          </div>
          <div className="incorrect_container">
            <span className="incorrect_ans">{quiz.failureReason === 'wrong' ? '残念 不正解' : '残念 時間切れ'}</span>
          </div>
        </div>
        <div className="correct_ans">
          {quiz.currentQuestion === 0 ? 'あなたは 正解なし' : `あなたは ${quiz.currentQuestion}問目まで正解`}
        </div>
        {user.tweetVerified || (
          <div>
            <div className="try_again"> X(旧Twitter)への投稿でもう一回チャレンジする </div>
            <div className="cross">
              <img src={imgPath('xIcon.webp')} alt="cross" className="cross" onClick={handleTwitterShare} />
            </div>
          </div>
        )}
        <div className="correct_upto">
          {quiz.currentQuestion === 0 ? '正解出来なかったあなたも' : `${quiz.currentQuestion}問目まで正解したあなたへ`}
        </div>
        <div className="discount"> ＼下記リンクから購入すると割引／ </div>
        <div
          className="coupon_container"
          onClick={() => {
            window.open(couponLink);
          }}
        >
          <div className="whiteRect">
            <div className="blackBorder">
              <img src={imgPath('bSymbol.webp')} alt="black" className="b_symbol" />
              <div className="couponText">
                <div className="coupon">coupon</div>
                <div className="couponAmount">
                  <div className="coupon_value">
                    {quiz.score <= 2
                      ? '100'
                      : quiz.score <= 4
                      ? '200'
                      : quiz.score <= 5
                      ? '500'
                      : quiz.score <= 6
                      ? '600'
                      : quiz.score <= 7
                      ? '777'
                      : quiz.score <= 8
                      ? '888'
                      : '999'}
                  </div>
                  <div className="yen">円引き</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page_transition"> ※このページから遷移しないと割引になりません。</div>
        <a href="https://pocket-se.info/">ポケットサウンド/効果音素材</a>
      </Wrapper>
    </QuizWrapper>
  );
};
