import { httpsCallable } from 'firebase/functions';
import { functions } from '../configs/firebase';

/**
 * [概要]
 ** 任意の文章をLarkに送信する
 * @param {string} group 送信先のグループ名
 * @param {string} text 送信するテキスト
 * @returns {Promise<void>} サーバーからのレスポンス
 *
 * [グループ名一覧]
 ** テスト用: 'test',
 ** quizエラー: 'quiz_error',
 ** quiz失敗: 'quiz_failure',
 ** quiz成功: 'quiz_achieve',
 ** LPエラー: 'lp_error',
 */

export const sendLarkLog = async (group, text) => {
  const sendLarkLog = httpsCallable(functions, 'lark2-sendLarkLog');
  return await sendLarkLog({
    group: group,
    text: text,
  });
};
