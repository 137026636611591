// Questions.js
import classNames from 'classnames';
import { Modal } from '../components/modal';
import { useState, useEffect, useRef } from 'react';
import { Wrapper } from './questions.scss';
import { consoleLog } from '../../zz_general/utils/snippets/console';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QuizSoundState, QuizState, QuizUserState } from '../store/atom';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { generateQuestions } from '../components/QuestionBank';
import { QuizWrapper } from '../components/quizWrapper';
import { publicUrl } from '../../zz_general/utils/snippets/env';
import ReactHowler from 'react-howler';
import { changePage } from '../utils/navigate';

const audioPath = (fileName) => publicUrl + '/quiz/questions/sounds/' + fileName;
const imgPath = (fileName) => publicUrl + '/quiz/questions/images/' + fileName;

export const QuizQuestions = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [questions] = useState(generateQuestions());
  const [options, setOptions] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const scrollableOptionsRef = useRef(null);
  const [timerColor, setTimerColor] = useState('black'); // State for timer color
  const [isLastQ, setLastQ] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [correctTimer, setCorrectTimer] = useState(3);
  const [timer, setTimer] = useState(questions[0].timeLimit);
  const [selectedOption, setSelectedOption] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [isCorrectAnswerTimerRunning, setIsCorrectAnswerTimerRunning] = useState(false);

  const [sound, setSound] = useRecoilState(QuizSoundState);
  const [quiz, setQuiz] = useRecoilState(QuizState);
  const user = useRecoilValue(QuizUserState);

  const [correctAudio, setCorrectAudio] = useState(false);
  const correctAudioRef = useRef(null);
  const [selectAudio, setSelectAudio] = useState(false);
  const selectAudioRef = useRef(null);
  const [timerStartAudio, setTimerStartAudio] = useState(false);
  const timerStartAudioRef = useRef(null);
  const [timerBeepAudio, setTimerBeepAudio] = useState(false);
  const timerBeepAudioRef = useRef(null);
  const [timerStartAudio2, setTimerStartAudio2] = useState(false);
  const timerStartAudio2Ref = useRef(null);

  const resetAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.seek(0);
    }
  };
  const setFailureReason = (reason) => {
    setQuiz((prev) => ({ ...prev, failureReason: reason }));
  };

  const setCurrentQuestion = (question) => {
    consoleLog('current question: ', question);
    setQuiz((prev) => ({ ...prev, currentQuestion: question }));
  };

  const setScore = (score) => {
    setQuiz((prev) => ({ ...prev, score: score }));
  };

  const updateSoundEnabled = (bool) => {
    setSound((prev) => ({ ...prev, enabled: bool }));
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  };

  const isAnswerCorrect = () => {
    return (
      arraysEqual(selectedOption, questions[quiz.currentQuestion].answer) ||
      questions[quiz.currentQuestion].answer.includes(userAnswer.trim())
    );
  };

  const shuffleOption = (prevArray) => {
    const array = [...prevArray];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleOptionClick = (option) => {
    if (sound.enabled) {
      resetAudio(selectAudioRef);
      setSelectAudio(true);
    }
    if (questions[quiz.currentQuestion].type === 'multipleChoice') {
      const index = selectedOption.indexOf(option);
      if (index === -1) {
        setSelectedOption([...selectedOption, option]);
      } else {
        const updatedOptions = [...selectedOption];
        updatedOptions.splice(index, 1);
        setSelectedOption(updatedOptions);
      }
    } else {
      if (selectedOption[0] === option) {
        setSelectedOption([]);
      } else {
        setSelectedOption([option]);
      }
    }
  };

  const handleNextQuestion = () => {
    // pauseAudio(timerBeepAudio);
    setTimerBeepAudio(false);
    intervalId && clearInterval(intervalId);

    if (isAnswerCorrect()) {
      if (isLastQ) {
        changePage(navigate, 'success-prize', 'questions');
      } else {
        startCorrectAnswerTimer();
      }
    } else {
      setFailureReason('wrong'); // Set failure reason to wrong answer
      changePage(navigate, 'fail-prize', 'questions');
    }
  };

  const startCorrectAnswerTimer = () => {
    setCorrectTimer(3);
    setIsCorrectAnswerTimerRunning(true);
    // sound.enabled && playAudio(correctAudio);
    if (sound.enabled) {
      resetAudio(correctAudioRef);
      setCorrectAudio(true);
    }
    let count = 3;
    const interval = setInterval(() => {
      count -= 1;
      setCorrectTimer(count);

      if (count > 0) return;

      setSelectedOption([]);
      setUserAnswer('');
      clearInterval(interval);
      setIsCorrectAnswerTimerRunning(false);

      handleCorrectAnswer();
    }, 1000);
  };

  const handleCorrectAnswer = () => {
    consoleLog('selected option: ', selectedOption);
    setScore(quiz.score + questions[quiz.currentQuestion].points);
    const nextQuestion = quiz.currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setTimer(questions[nextQuestion].timeLimit); // Set timer based on the next question's time limit
      setCurrentQuestion(nextQuestion);
    }
    setTimerColor('black');
    if (questions[quiz.currentQuestion].id + 1 === questions.length) {
      setLastQ(true);
    }
  };

  const startQuestionTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    // MEMO: Save the interval ID to clear it when the question is updated.
    setIntervalId(interval);
  };

  useEffect(() => {
    if (timer > 0) {
      if (timer <= 10 && timer !== 0) {
        setTimerColor('red');
        // sound.enabled && !isPlaying(timerBeepAudio) && playAudio(timerBeepAudio);
        if (sound.enabled && !timerBeepAudio) {
          resetAudio(timerBeepAudioRef);
          setTimerBeepAudio(true);
        }
      } else {
        setTimerColor('black');
      }
    }

    if (timer === 0) {
      clearInterval(intervalId);
      setFailureReason('timeout');
      changePage(navigate, 'fail-prize', 'questions');
    }
  }, [timer]);

  useEffect(() => {
    // Scroll to the top when the current question changes
    if (scrollableOptionsRef.current) {
      scrollableOptionsRef.current.scrollTop = 0;
    }
    // MEMO: The first question starts by closing the popup display.
    if (quiz.currentQuestion === 0) return;
    // MEMO: Set the timer when the question number is updated.
    if (sound.enabled) {
      if (quiz.currentQuestion < 6) {
        resetAudio(timerStartAudioRef);
        setTimerStartAudio(true);
      } else {
        resetAudio(timerStartAudio2Ref);
        setTimerStartAudio2(true);
      }
    }

    setOptions(shuffleOption(questions[quiz.currentQuestion].options));

    startQuestionTimer();
  }, [quiz.currentQuestion]);

  useEffect(() => {
    // Update isOptionSelected state based on the current question's selected option
    if (questions[quiz.currentQuestion].type === 'multipleChoice') {
      setIsOptionSelected(selectedOption.length > 0);
    } else {
      setIsOptionSelected(selectedOption !== null && selectedOption.length > 0);
    }
  }, [selectedOption]);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      alert('ブラウザバックボタンが押されたため、TOP画面にリダイレクトします。');
      changePage(navigate, '', 'questions');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    // MEMO: If the user is not logged in, redirect to the intro page.
    !user.loggedIn && changePage(navigate, '', 'questions');
    // MEMO: If the previous page is not the start page, redirect to the intro page.
    query.get('prev') !== 'start' && changePage(navigate, '', 'questions');

    setOptions(shuffleOption(questions[quiz.currentQuestion].options));

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (ReactHowler.ctx && ReactHowler.state === 'suspended') {
        ReactHowler.ctx.resume();
        consoleLog('Audio context resumed');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={false}
    >
      <ReactHowler
        src={audioPath('correct.mp3')}
        playing={correctAudio}
        ref={correctAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setCorrectAudio(false);
        }}
      />
      <ReactHowler
        src={audioPath('select.mp3')}
        playing={selectAudio}
        ref={selectAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setSelectAudio(false);
        }}
      />
      <ReactHowler
        src={audioPath('timerStart.mp3')}
        playing={timerStartAudio}
        ref={timerStartAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setTimerStartAudio(false);
        }}
      />
      <ReactHowler
        src={audioPath('timerStart2.mp3')}
        playing={timerStartAudio2}
        ref={timerStartAudio2Ref}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setTimerStartAudio2(false);
        }}
      />
      <ReactHowler
        src={audioPath('timerBeep.mp3')}
        playing={timerBeepAudio}
        ref={timerBeepAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setTimerBeepAudio(false);
        }}
      />

      <Wrapper
        className="questions"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <div className="tiramisuQuiz">ティラミスクイズ</div>
        <div className="questionContainer">
          <div className="question">
            <span className="questionId">
              {questions[quiz.currentQuestion].id}
              {'. '}
            </span>
            <span className="currentQuestion">{questions[quiz.currentQuestion].question}</span>
          </div>
        </div>

        <div className="timeRemaining">
          <span className="mt-2 text-warning">制限時間 : </span>
          <span className={classNames('timer', { ['red']: timerColor === 'red' })}>
            {isNaN(timer)
              ? ' 00 : 00 : 00 '
              : `${Math.floor(timer / 60)
                  .toString()
                  .padStart(2, '0')} : ${(timer % 60).toString().padStart(2, '0')} : 00`}
          </span>
          <img
            onClick={() => {
              updateSoundEnabled(!sound.enabled);
              if (sound.enabled) {
                setCorrectAudio(false);
                setSelectAudio(false);
                setTimerStartAudio(false);
                setTimerBeepAudio(false);
              } else {
                resetAudio(selectAudioRef);
                setSelectAudio(true);
              }
            }}
            src={imgPath(sound.enabled ? 'soundOff.svg' : 'soundOn.svg')}
            alt={sound.enabled ? 'Sound On' : 'Sound Off'}
            className="soundButton"
          />
        </div>

        {/* Conditional Rendering for Different Question Types */}
        {questions[quiz.currentQuestion].type === 'multipleChoice' ? (
          <div
            className={screenOrientation === 'horizontal' ? 'optionsContainer' : 'scrollableOptions'}
            ref={scrollableOptionsRef}
          >
            {options.map((option, index) => (
              <div key={index} className="optionContainer">
                <button
                  className="option"
                  onClick={() => handleOptionClick(option)}
                  disabled={isCorrectAnswerTimerRunning}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: selectedOption.includes(option) ? '#FFFA83' : 'white',
                    border: '1px solid gray',
                    textAlign: 'center',
                  }}
                >
                  <span className="optionId" style={{ marginRight: 'auto' }}>
                    {String.fromCharCode(64 + index + 1)}
                    {'.'}
                  </span>
                  <span className="options" style={{ flexGrow: 1 }}>
                    {option}
                  </span>
                </button>
              </div>
            ))}
          </div>
        ) : questions[quiz.currentQuestion].type === 'singleChoice' ? (
          <div className="optionsContainer">
            {options.map((option, index) => (
              <div key={index} className="optionContainer">
                <button
                  className="option"
                  onClick={() => handleOptionClick(option)}
                  disabled={isCorrectAnswerTimerRunning}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: selectedOption[0] === option ? '#FFFA83' : 'white',
                    border: '1px solid gray',
                    textAlign: 'center',
                  }}
                >
                  <span className="optionId" style={{ marginRight: 'auto' }}>
                    {String.fromCharCode(64 + index + 1)}
                    {'.'}
                  </span>
                  <span className="options" style={{ flexGrow: 1 }}>
                    {option}
                  </span>
                </button>
              </div>
            ))}
          </div>
        ) : questions[quiz.currentQuestion].type === 'freeNarrative' ? (
          <div className="freeNarrativeContainer">
            <div className="instructionsContainer">
              <div className="instruction1">下記条件を満たして回答してください。</div>
              {questions[quiz.currentQuestion].options.map((line, index) => (
                <div key={index} className="instructionLine">
                  {line}
                </div>
              ))}
            </div>
            <div className="textAreaContainer">
              <input
                className="freeNarrativeInput"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                rows="1"
              />
            </div>
          </div>
        ) : null}

        {questions[quiz.currentQuestion].type === 'multipleChoice' && (
          <div>
            <div className="selectedAnswer">選択した解答</div>
            <div className="confirmationBox">
              {selectedOption.map((option, index) => (
                <div key={index} className="confirmedOption">
                  {option}
                </div>
              ))}
            </div>
          </div>
        )}
        <p className="timeRemaining notes">※戻るや更新を押さないでください</p>
        <div className="nextQuesContainer">
          <button
            className={classNames({
              ['nextQuestion']:
                isOptionSelected ||
                (questions[quiz.currentQuestion].type === 'freeNarrative' && userAnswer.trim() !== ''),
              ['nextQuestionDisabled']: !(
                isOptionSelected ||
                (questions[quiz.currentQuestion].type === 'freeNarrative' && userAnswer.trim() !== '')
              ),
            })}
            onClick={handleNextQuestion}
            disabled={
              (!isOptionSelected && questions[quiz.currentQuestion].type !== 'freeNarrative') ||
              (questions[quiz.currentQuestion].type === 'freeNarrative' && userAnswer.trim() === '') ||
              isCorrectAnswerTimerRunning
            }
          >
            <span className="buttonText">解答をする</span>
            {isCorrectAnswerTimerRunning === true && <span className="correctText">正解</span>}
            {isCorrectAnswerTimerRunning === true && (
              <img src={imgPath('redCircle.webp')} alt="Red Circle" className="redCircle" />
            )}
          </button>
        </div>

        {isCorrectAnswerTimerRunning === true && <div className="correctAnswerTimer">次の問題まで {correctTimer}</div>}
        <Modal
          updateSoundEnabled={updateSoundEnabled}
          sound={sound}
          playSelectAudio={() => {
            resetAudio(selectAudioRef);
            setSelectAudio(true);
          }}
          onClosePopup={() => {
            if (sound.enabled) {
              resetAudio(selectAudioRef);
              setSelectAudio(true);
            }
            setTimeout(() => {
              if (sound.enabled) {
                resetAudio(timerStartAudioRef);
                setTimerStartAudio(true);
              }
              startQuestionTimer();
            }, 200);
          }}
        />
      </Wrapper>
    </QuizWrapper>
  );
};
