import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.intro {
    padding-bottom: 10vh;
    .super_difficult {
      position: relative;
      color: rgba(255, 255, 255, 0.1);
      align-items: center;
      font-weight: 900;
      font-family: 'Source Han Sans JP';
      text-align: center;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.144, 0.144, 0.144]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.258, 0.258, 0.258]),
        });
      }}
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        ${(props) => {
          return responsiveStyle({
            height: multiplyArrays(props.bodyWidth, [0.258, 0.258, 0.258]),
          });
        }}
      }
    }
    .Tiramisu_eng {
      font-weight: 400;
      font-family: 'Kiwi Maru';
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.1]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.062, 0.06]),
          marginTop: multiplyArrays(props.bodyWidth, [0.18, 0.12, 0.08]),
        });
      }}
    }
    .Tiramisu_jap {
      font-weight: 500;
      font-family: 'Kiwi Maru';
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.062, 0.06]),
          marginTop: multiplyArrays(props.bodyWidth, [0.06, 0.05, 0.04]),
        });
      }}
    }
    .bene_reg {
      ${(props) => {
        return responsiveStyle({
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.05, 0.02]),
        });
      }}
      .present {
        font-family: 'Noto Sans JP';
        font-weight: 900;
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.042, 0.04, 0.038]),
          });
        }}
      }
      .BG {
        font-family: 'Noto Sans JP';
        font-weight: 1000;
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.048, 0.046, 0.044]),
          });
        }}
      }
    }
    .tiramisu_cake {
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.86, 0.45, 0.45]),
          height: multiplyArrays(props.bodyWidth, [0.6, 0.28, 0.28]),
          marginTop: multiplyArrays(props.bodyWidth, [0.05, 0.03, 0.03]),
        });
      }}
    }
    .tap_details_wrap {
      width: 100%;
      overflow: hidden;
      // 文言の下が見切れてしまうためmin-height指定
      min-height: 45px;
      .tap_details {
        font-family: 'Kiwi Maru';
        font-weight: 500;
        animation: expandContract 2s ease-in-out infinite;
        transform-origin: center; /* To ensure scaling happens from the center */
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.045]),
            lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.064),
            marginTop: multiplyArrays(props.bodyWidth, [0.05, 0.03, 0.01]),
          });
        }}
      }
      @keyframes expandContract {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }
    .luxury_prize_wrap {
      position: relative;
      width: 100%;
      ${(props) => {
        return responsiveStyle({
          height: multiplyArrays(props.bodyWidth, [0.17, 0.14, 0.12]),
          marginTop: multiplyArrays(props.bodyWidth, [0.02, 0.01, 0.01]),
        });
      }}
      .luxury_prize {
        width: 100vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: grid;
        grid-template-columns: auto;
        @media (min-width: 500px) {
          grid-template-columns: ${(props) => (props.screenOrientation === 'horizontal' ? 'auto auto' : 'auto')};
        }
        cursor: pointer;
        .line {
          color: #fff;
          font-family: 'Kiwi Maru';
          font-weight: 500;
          -webkit-text-stroke: calc(0.05vw + 1.2px) #5f2f17;
          ${(props) => {
            return responsiveStyle({
              fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.07, 0.06]),
              lineHeight: multiplyArrays(props.bodyWidth, [0.085, 0.07, 0.06]),
              Height: multiplyArrays(props.bodyWidth, [0.085, 0.07, 0.06]),
            });
          }}
          @media (min-width: 500px) {
            &:nth-child(1) {
              text-align: ${(props) => props.screenOrientation === 'horizontal' && 'right'};
              padding-right: ${(props) => props.screenOrientation === 'horizontal' && '4px'};
            }
            &:nth-child(2) {
              text-align: ${(props) => props.screenOrientation === 'horizontal' && 'left'};
              padding-left: ${(props) => props.screenOrientation === 'horizontal' && '4px'};
            }
          }
        }
      }
    }
    .btn_box {
      .btn {
        color: #fff;
        font-weight: 800;
        font-family: 'Kiwi Maru';
        text-align: center;
        background-color: #fffa83;
        cursor: pointer;
        -webkit-text-stroke: calc(0.05vw + 0.05px) #5f2f17;
        position: relative;
        display: inline-block;
        z-index: 10;
        border: ${(props) => `${multiplyArrays(props.bodyWidth, [0.027, 0.022, 0.017])[0]}px solid #5F2F17`};
        ${(props) => {
          return responsiveStyle({
            width:
              props.screenOrientation === 'vertical'
                ? multiplyArrays(props.bodyWidth, [0.85, 0.8, 0.75])
                : multiplyArrayByScalar(props.bodyWidth, 0.95),
            height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
            borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
            fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.045]),
            lineHeight: multiplyArrays(props.bodyWidth, [0.16, 0.14, 0.14]),
            marginTop: multiplyArrays(props.bodyWidth, [0.05, 0.06, 0.06]),
          });
        }}
      }
      .btn:before {
        position: absolute;
        content: '';
        width: ${(props) =>
          `calc(100% + ${multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.06])[0]}px)`}; /* Adjust for responsiveness */
        height: ${(props) =>
          `calc(100% + ${multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.06])[0]}px)`}; /* Adjust for responsiveness */
        top: ${(props) => `-${multiplyArrays(props.bodyWidth, [0.04, 0.04, 0.04])[0]}px`};
        left: ${(props) => `-${multiplyArrays(props.bodyWidth, [0.04, 0.04, 0.04])[0]}px`};
        z-index: -10;
        background: #5f2f17; /* Outer border color */
        border-radius: inherit;
        animation: uyon 1s linear infinite;
      }
      .btn:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -3;
        background: #f0dcaa; /* Inner box color */
        border-radius: inherit;
      }
      @keyframes uyon {
        0% {
          opacity: 1;
          transform: scaleY(1) scaleX(1);
        }
        20% {
          opacity: 0.8;
        }
        70% {
          opacity: 0.2;
          transform: scaleY(1.2) scaleX(1.05);
        }
        80% {
          opacity: 0;
          transform: scaleY(1.2) scaleX(1.05);
        }
        90% {
          opacity: 0;
          transform: scaleY(1) scaleX(1);
        }
      }
    }
  }
`;
