import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
padding-left: 0 !important; 
  &.rechallenge {
    .re_challenge_text_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1vw; /* Adjust padding as needed */
    }
    .re_challenge_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      color: #fff;
      font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 900;
      -webkit-text-stroke-width: 0.2vw; /* Stroke width */
      -webkit-text-stroke-color: #5f2f17; /* Stroke color */
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.075, 0.045, 0.03]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.12, 0.03, 0.02]),
        });
      }}
    }
    .post_link_text {
      color: #fff;
      font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 900;
      text-align: left;
      text-shadow: 
        -1px -1px 0 #5f2f17,  
         1px -1px 0 #5f2f17,
        -1px  1px 0 #5f2f17,
         1px  1px 0 #5f2f17; /* Non-WebKit browsers */
      text-align: center;
    }
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.0335, 0.0223]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.0335, 0.0223]),
          paddingLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.07, 0.03, 0.02]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.09, 0.04, 0.03]),
        });
      }}
    }
    .linkContainer {
      margin-top: 2vw; /* Adjusted from 15px to 1.875vh */
    }
    .link {
      padding-left: 2vw;
      margin-bottom: 1.25vw;
      border-radius: 10px;
      border: 4px solid #5f2f17;
      background: #fff;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.0335, 0.0223]),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.2, 0.12, 0.1]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.11, 0.066, 0.044]),
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.8, 0.9, 0.9]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.7, 0.49, 0.28]),
          marginLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.06, 0.03, 0.02]),
        });
      }}
    }
    /* Add hover effect */
    .link:hover {
      border-color: rgb(229, 231, 228); /* Change border color on hover */
    }
    /* Add focus effect */
    .link:focus {
      outline: none; /* Remove default outline on focus */
      border-color: #e87878; /* Change border color on focus */
    }
    .rulesContainer {
    margin-left: auto;
    margin-right: auto;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [1, 1, 1]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.78, 0.546, 0.335]),
        });
      }}
    }
    .rule {
      color: #5f2f17;
     text-shadow: 
        -0.4px -0.4px 0 #5f2f17,  
         0.4px -0.4px 0 #f0dcaa,
        -0.4px  0.4px 0 #f0dcaa,
         0.4px  0.4px 0 #f0dcaa; /* Non-WebKit browsers */
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 500;
      text-align: left;
      padding-left: 2.25vw; /* Adjusted from 10px to 1.25vh */
      letter-spacing: -0.1vw;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.025, 0.01, 0.05]),
        });
      }}
    }
    .errorText {
      padding-bottom: 2vw;
      color: #fe163e;
      text-align: center;
      -webkit-text-stroke-width: 0.005vw;
      -webkit-text-stroke-color: #f0dcaa;
      font-family: 'Kiwi Maru';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 75% */
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
        });
      }}
    }
    .successText1 {
      text-align: left;
      padding-left: 2vw;
      padding-right: 2vw;
      color: #000;
      font-family: 'Kiwi Maru';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
        });
      }}
    }
    .successText2 {
      padding-bottom: 2vw;
      padding-left: 2vw;
      padding-right: 2vw;
      text-align: left;
      color: #000;
      font-family: 'Kiwi Maru';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
        });
      }}
    }
    .submitContainer {
      ${(props) => {
        return responsiveStyle({
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.02, 0.01, 0.005]),
        });
      }}
    }
    .submitDisabled {
      background-color: #d9d9d9; // Disabled background color
      color: rgb(159, 151, 151);
      cursor: not-allowed;
      border-color: solid #6b757e;
      ${(props) => {
        return responsiveStyle({
          borderRadius:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0465, 0.0349, 0.0249]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0465, 0.0349, 0.0249]),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1976, 0.1185, 0.079]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.1976, 0.1185, 0.079]),
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [1, 1, 1]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.7441, 0.5208, 0.31]),
          borderWidth:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0265, 0.0199, 0.015]),
        });
      }}
    }
    .submit {
      background-color: #f0dcaa;
      border-color: solid #5f2f17;
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          borderRadius:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0465, 0.0349, 0.0249]),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1976, 0.1185, 0.079]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.1976, 0.1185, 0.079]),
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.7441, 0.5208, 0.31]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.7441, 0.5208, 0.31]),
          borderWidth:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0265, 0.0199, 0.015]),
        });
      }}
    }
    .submitError {
      border-color: #fe163e !important;
    }
    .buttonText {
      color: #5f2f17;
      text-align: center;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 500;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.0335, 0.0223]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.0335, 0.0223]),
        });
      }}
    }
    .footer {
      position: absolute;
      bottom: 0;
      color: #5f2f17;
      text-shadow: 
      rgb(95, 47, 23) -0.4px -0.4px 0px,
      rgb(240, 220, 170) 0.4px -0.4px 0px,
      rgb(240, 220, 170) -0.4px 0.4px 0px, 
      rgb(240, 220, 170) 0.4px 0.4px 0px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.0223, 0.0149]),
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [1, 1, 1]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.85, 0.595, 0.34]),
        });
      }}
    }
    .privacy_terms {
      padding-top: 1vw;
      ${(props) => {
        return responsiveStyle({
          marginLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.08, 0.01, 0.01]),
        });
      }}
    }
    .privacy {
      text-align: left;
      ${(props) => {
        return responsiveStyle({
          marginRight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.2, 0.08, 0.14]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.01, 0.0, 0.0]),
        });
      }}
    }
    .terms {
      text-align: right;
    }
    .description {
      text-align: left;
      ${(props) => {
        return responsiveStyle({
          marginRight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.13, 0.05, 0.5]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.01, 0.0, 0.0]),
          paddingLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.02, 0.01, 0.01]),
        });
      }}
    }
    .company {
      text-align: right;
    }
    .copyright {
      text-align: left;
      color: white;
      margin-left: 6vw;
      color: #fff;
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.0576, 0.0375]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0325, 0.0195, 0.01625]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.06, 0.03, 0.02]),
          paddingLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.045, 0.025, 0.01]),
          paddingBottom:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.04, 0.02, 0.01]),
        });
      }}
    }

    .tiramisuQuiz {
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 900;
      -webkit-text-stroke: 2px #5f2f17;

      ${(props) => {
        // Define font size multiplier for different orientations
        const fontSizeMultiplier =
          props.screenOrientation === 'horizontal'
            ? [0.111, 0.093, 0.079] // Bigger font sizes for horizontal orientation
            : [0.093, 0.0782, 0.0665]; // Default font sizes

        const paddingTopMultiplier =
          props.screenOrientation === 'horizontal'
            ? [0.25, 0.15, 0.1] // Increased padding for horizontal orientation
            : [0.18, 0.09, 0.05]; // Default padding

        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, fontSizeMultiplier),
          lineHeight: multiplyArrays(props.bodyWidth, fontSizeMultiplier),
          paddingTop: multiplyArrays(props.bodyWidth, paddingTopMultiplier),
        });
      }}
    }
    .questionContainer {
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      ${(props) => {
        return responsiveStyle({
          paddingTop: multiplyArrays(props.bodyWidth, [0.15, 0.085, 0.06]),
        });
      }}
    }
    .question {
      border: 8px solid #f0dc96;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      margin: 0 auto;
      background-color: white;
      color: black;
      display: flex; /* Use flexbox for layout */
      justify-content: center; /* Center items horizontally */
      align-items: center; /* Center items vertically */
      text-align: left;

      ${(props) => {
        // Define font size multiplier for different orientations
        const fontSizeMultiplier =
          props.screenOrientation === 'horizontal'
            ? [0.07, 0.052, 0.04] // Increased font sizes for horizontal orientation
            : [0.037, 0.028, 0.022]; // Default font sizes

        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, fontSizeMultiplier),
        });
      }}
    }

    .questionId {
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      margin-left: 4vw;
      margin-right: 2vw; /* Add spacing between question ID and question text */
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.072, 0.055, 0.042]) // Increased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.055, 0.04163, 0.03272]),
        });
      }}
    }

    @font-face {
      font-family: 'Digital-7';
      src: url('./digital-7.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
    }
    .currentQuestion {
      margin-right: 4vw;
    }
    .timeRemaining {
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      color: black;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      &.red {
        color: red; /* Color when timerColor state is 'red' */
      }
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.073, 0.05, 0.05]) // Increased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
          paddingTop:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.125, 0.08, 0.06]) // Increased paddingTop for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.125, 0.08, 0.06]),
        });
      }}
    }

    .timer {
      font-family: 'Digital-7', sans-serif;
      font-style: normal;
      font-weight: 600;
      color: black;

      &.red {
        color: red; /* Color when timerColor state is 'red' */
      }

      ${(props) => {
        // Define font size and padding top multipliers for different orientations
        const fontSizeMultiplier =
          props.screenOrientation === 'horizontal'
            ? [0.083, 0.056, 0.056] // Increased font sizes for horizontal orientation
            : [0.073, 0.05, 0.05]; // Default font sizes

        const paddingTopMultiplier =
          props.screenOrientation === 'horizontal'
            ? [0.125, 0.08, 0.06] // Increased padding top for horizontal orientation
            : [0.125, 0.08, 0.06]; // Default padding top

        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, fontSizeMultiplier),
          paddingTop: multiplyArrays(props.bodyWidth, paddingTopMultiplier),
        });
      }}
    }

    .correctAnswerTimer {
      padding-top: 1vw;
      color: #fff;
      -webkit-text-stroke: 1px #8f624d;
      font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 900;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428]) // Decreased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428]),
        });
      }}
    }
    .soundButton {
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.07, 0.05, 0.04]) // Increased width for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.055, 0.042]) // Increased height for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
          marginLeft:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]) // Increased marginLeft for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
        });
      }}
    }
    .scrollableOptions {
      flex-grow: 1;
      overflow-y: auto;
      max-height: 35vh; /* Adjust the height as per your requirement */
      margin-bottom: 10px;
    }
    .optionsContainer {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      flex-wrap: wrap; /* Allow items to wrap to the next line */
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      gap: ${(props) => (props.screenOrientation === 'horizontal' ? '20px' : '0')};
    }
    .optionContainer {
      justify-content: center;
      width: ${(props) => (props.screenOrientation === 'horizontal' ? 'calc(100%/2 - 10px)' : '100%')};
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      ${(props) =>
        responsiveStyle({
          paddingTop: multiplyArrays(props.bodyWidth, [0.055, 0.045, 0.04]),
        })}
    }
    .optionId {
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '2vw' : '4vw')};
    }
    .option {
      font-family: 'Kiwi Maru';
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.85, 0.65, 0.5])
              : multiplyArrays(props.bodyWidth, [0.85, 0.65, 0.5]),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.13, 0.0975, 0.075])
              : multiplyArrays(props.bodyWidth, [0.13, 0.0975, 0.075]),
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.07, 0.052, 0.04]) // Increased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
          borderRadius:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.04, 0.03, 0.025]) // Adjusted border radius for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.028, 0.022]),
        });
      }}
    }

    .option8 {
      font-family: 'Kiwi Maru';
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      margin: 0 auto;
      text-align: center;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.85, 0.65, 0.5]),
          height: multiplyArrays(props.bodyWidth, [0.13, 0.0975, 0.075]),
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.037, 0.033, 0.025]) // Decreased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0465, 0.042, 0.032]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.0372, 0.028, 0.022]),
        });
      }}
    }

    .selectedAnswer {
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      color: #f0dcaa;
      -webkit-text-stroke: 1px #8f624d;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 500;
      ${(props) => {
        return responsiveStyle({
          paddingTop: multiplyArrays(props.bodyWidth, [0.04, 0.03, 0.0225]),
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.074, 0.074, 0.074]) // Decreased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.056, 0.056, 0.056]),
        });
      }}
    }
    .confirmationBox {
      justify-content: center;
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '150%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-25%' : '0')};
      padding: 2vw;
      border: 8px solid var(--GOLD, #f0dc96);
      background: #fff;
    }

    .confirmedOption {
      text-align: center;
      background-color: #fff;
      color: #000;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0558, 0.042, 0.0321]) // Decreased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0372, 0.028, 0.0214]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.07, 0.052, 0.04]) // Decreased font size for horizontal orientation
              : multiplyArrays(props.bodyWidth, [0.0558, 0.042, 0.0321]),
        });
      }}
    }
    .nextQuesContainer {
      display: flex;
      justify-content: center;
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      ${(props) => {
        return responsiveStyle({
          paddingTop: multiplyArrays(props.bodyWidth, [0.09, 0.07, 0.06]),
        });
      }}
    }
    .nextQuestionDisabled {
      background-color: #d9d9d9; // Disabled background color
      color: rgb(159, 151, 151);
      cursor: not-allowed;
      border: 0.875vw solid #6b757e;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.7441, 0.56, 0.43])
              : multiplyArrayByScalar(props.bodyWidth, 0.75),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1976, 0.1482, 0.1134])
              : multiplyArrays(props.bodyWidth, [0.1976, 0.1482, 0.1134]),
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428])
              : multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.0465, 0.0558, 0.0267]),
          borderWidth: multiplyArrays(props.bodyWidth, [0.0265, 0.0199, 0.015]),
        });
      }}
    }
    .nextQuestion {
      position: relative;
      color: #5f2f17;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 600;
      background-color: #f0dcaa;
      border: 0.875vw solid #5f2f17;
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.7441, 0.56, 0.43])
              : multiplyArrayByScalar(props.bodyWidth, 0.75),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1976, 0.1482, 0.1134])
              : multiplyArrays(props.bodyWidth, [0.1976, 0.1482, 0.1134]),
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428])
              : multiplyArrays(props.bodyWidth, [0.0744, 0.0558, 0.0428]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.0465, 0.0558, 0.0267]),
          borderWidth: multiplyArrays(props.bodyWidth, [0.0265, 0.0199, 0.015]),
        });
      }}
    }
    .buttonText {
      position: relative;
      // z-index: 1; /* Ensure the text is above the circle */
    }
    .correctText {
      color: #fff;
      text-align: center;
      font-family: 'Hiragino Kaku Gothic Std';
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; /* Ensure the text is above the circle */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1488, 0.097, 0.075])
              : multiplyArrays(props.bodyWidth, [0.1488, 0.097, 0.075]),
        });
      }}
    }
    .redCircle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1; /* Ensure the circle is above the button background but below the text */
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.2326, 0.1628, 0.1279]),
          height: multiplyArrays(props.bodyWidth, [0.2326, 0.1628, 0.1279]),
        });
      }}
    }
    .instructionsContainer {
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-8%' : '0')};
      text-align: left;
      color: #f0dcaa;
      -webkit-text-stroke-width: ${(props) => (props.screenOrientation === 'horizontal' ? '0.05vw' : '1px')};
      -webkit-text-stroke-color: #5f2f17;
      font-family: 'Kiwi Maru';
      font-style: normal;
      font-weight: 500;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.0558, 0.042, 0.0321])
              : multiplyArrays(props.bodyWidth, [0.0558, 0.042, 0.0321]),
          lineHeight:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.093, 0.071, 0.053])
              : multiplyArrays(props.bodyWidth, [0.093, 0.071, 0.053]),
        });
      }}
    }
    .instruction2,
    .instruction3,
    .instruction4,
    .instruction5 {
      margin-left: 20px;
    }
    .textAreaContainer {
      display: flex;
      justify-content: center;
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
      ${(props) => {
        return responsiveStyle({
          paddingTop: multiplyArrays(props.bodyWidth, [0.05, 0.04, 0.03]),
        });
      }}
    }
    .freeNarrativeInput {
      display: flex;
      justify-content: center;
      border-radius: 10px;
      background: #fff;
      border: 0.5vw solid #5f2f17;
      line-height: 1.5;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      ${(props) => {
        return responsiveStyle({
          fontSize:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.07, 0.052, 0.04])
              : multiplyArrays(props.bodyWidth, [0.056, 0.042, 0.032]),
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.7441, 0.56, 0.43])
              : multiplyArrayByScalar(props.bodyWidth, 0.75),
          height:
            props.screenOrientation === 'horizontal'
              ? multiplyArrays(props.bodyWidth, [0.1302, 0.11, 0.09])
              : multiplyArrays(props.bodyWidth, [0.1041, 0.0888, 0.072]),
        });
      }}
    }
  }
`;
