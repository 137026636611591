import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import css from './index.module.scss';

import { importAll } from '../../utils/image';
import { CustomerInfo_takeout, LPHeader, OrderDetails, SideMenu } from '../../components';
import QRCode from 'react-qr-code';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LPCartState, LPCustomerState } from '../../store/atoms';
import { termsAndPolicyState } from '../../../lp-legacy/atoms';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../../zz_general/utils/configs/firebase';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { LinkButton } from '../../components/linkButton';
import { FormHeader } from '../../components/fourStepBar';

export const LPThanksTakeout = () => {
  const image = importAll(require.context('./image', false, /\.(webp|avif|svg)$/));
  const setCart = useSetRecoilState(LPCartState);

  // MEMO: URLパラメータから予約IDを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const reservationId = query.get('reservationsId');

  const inputFormWidth = [340, 512, 720];
  const linkButtonHeight = [48, 52, 60];
  const [reservationData, setReservationData] = useState({});
  const [orderDetails, setOrderDetails] = useState('');
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState('');
  const customer = useRecoilValue(LPCustomerState);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      Object.keys(future.items).forEach((uid) => {
        if (Object.prototype.hasOwnProperty.call(future.items[uid], 'count')) {
          future.items[uid].count = 0;
        }
      });
      return future;
    });
    navigate('/lp-beta');
  };

  // MEMO : 決済の完了が確認できるまでローディングを表示
  useEffect(() => {
    if (paymentStatus === 'done') {
      setLoading(false);
    }
  }, [paymentStatus]);

  // MEMO: SendGridを発火しメールを送信
  const igniteSendGrid = async (data) => {
    const sendTakeoutMail = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'takeout-sendEmail' : 'takeoutTest-sendEmail'
    );
    sendTakeoutMail(data)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('メール送信しました。');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending email:', error);
      });
  };

  // MEMO: ある商品の予約総数を更新
  const updateReserveTotal = async (itemId, num) => {
    const collectionRef = collection(db, 'LP', process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test', 'admins');
    const docRef = doc(collectionRef, itemId);
    const fetchReserveTotal = async () => {
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    };
    const promise = fetchReserveTotal();
    promise
      .then((response) => {
        setDoc(docRef, {
          reserveTotal: response.reserveTotal + num,
        });
      })
      .catch(() => {});
  };

  // MEMO: 商品リストから予約総数を更新
  const loopUpdateReserveTotal = async (items) => {
    for (let id in items) {
      items[id].count > 0 && updateReserveTotal(id, items[id].count);
    }
  };

  // MEMO: PayPay決済のステータスを取得し更新
  const fetchPaymentStatus = async (docRef, response) => {
    const confirmPaymentStatus = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'payPay-confirmPaymentStatus' : 'payPayTest-confirmPaymentStatus'
    );
    try {
      const { data } = await confirmPaymentStatus({ merchantPaymentId: reservationId });
      if (data.data.status === 'COMPLETED') {
        // MEMO: 決済が完了している場合はステータスを更新
        updateDoc(docRef, {
          paymentStatus: 'done',
          isMailSent: true,
        });
        setPaymentStatus('done');
        igniteSendGrid(response);
        loopUpdateReserveTotal(response.items);
      } else {
        // MEMO: 決済が完了していない場合はTOPへ遷移
        navigate('/lp-beta');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching payment status:', error);
    }
  };

  // MEMO: 予約情報を取得
  useEffect(() => {
    !reservationId &&
      window.location.replace(
        process.env.REACT_APP_IS_PROD === 'true' ? 'https://bene-regalo.com/lp-beta' : 'http://localhost:3000/lp-beta'
      );
    const collectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'reservations'
    );
    const docRef = doc(collectionRef, reservationId);
    const fetchReservationData = async () => {
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    };
    const promise = fetchReservationData();
    promise
      .then((response) => {
        setPaymentStatus(response.paymentStatus);
        setReservationData(response);
        // MEMO : 決済が完了していない場合の処理
        if (response.paymentStatus === 'pending') {
          // MEMO : Stripeの場合thanks遷移で完了しているはずなのでここで更新
          if (response.paymentMethod === 'stripe') {
            updateDoc(docRef, {
              paymentStatus: 'done',
              isMailSent: true,
            });
            setPaymentStatus('done');
            igniteSendGrid(response);
            loopUpdateReserveTotal(response.items);
          }
          // MEMO : PayPayの場合
          if (response.paymentMethod === 'payPay') {
            fetchPaymentStatus(docRef, response);
          }
        }
        // MEMO : OrderDetailsはローカルステートの処理も持つので初めにセットしておく
        setOrderDetails(<OrderDetails product={response.items} total={response.total} />);
      })
      .catch(() => {
        navigate('/lp-beta');
      });
  }, []);

  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };

  if (loading) {
    return (
      <div className={classNames(css.loadingContainer)}>
        <div className={classNames(css.loading)}></div>
        <div className={classNames(css.loadingText)}>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className={classNames(css.LPBodyBase)}>
        <div className={classNames(css.reservations, css.LPBodyBase)}>
          {customer.reservationId === reservationId ? (
            // MEMO : thanksページのヘッダー
            <FormHeader title="予約完了" step={4} />
          ) : (
            // MEMO : 予約確認ページのヘッダー
            <>
              <SideMenu>
                <li>
                  <Link to={'/policy'}>
                    <p
                      onClick={() => {
                        updateTermsAndPolicy(true);
                      }}
                    >
                      利用規約
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to={'/policy'}>
                    <p
                      onClick={() => {
                        updateTermsAndPolicy(false);
                      }}
                    >
                      プライバシーポリシー&nbsp;&nbsp;&nbsp;
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to={'/transaction-act'}>
                    <p>特定商取引法に基づく表記</p>
                  </Link>
                </li>
                <li>
                  <Link to={'/lp-beta/inquiry'}>
                    <p>よくある質問／お問い合わせ</p>
                  </Link>
                </li>
              </SideMenu>
              <LPHeader />
            </>
          )}
          <div className={classNames(css.message)}>
            ご予約ありがとうございます。
            <br />
            こちらのQRが受け取りの際に必要ですので、
            <br />
            保存がおすすめです。
            <br />
          </div>
          <div className={css.reservationsIdSection}>
            <div className={css.reservationsId}>
              <div className={css.reservationsId}>予約番号: {reservationId}</div>
            </div>
            <div className={css.reservationQr}>
              <QRCode value={reservationId} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className={css.waitingCover}>
            <img src={image['waitingCover.webp']} alt="" className={css.waitingCoverImg} />
          </div>
          {/* 注文内容 */}
          <div className={css.reservationsContents}>
            {orderDetails}
            <hr className={classNames(css.lineM)} />
            <CustomerInfo_takeout
              customer={{
                firstName: reservationData.fName,
                lastName: reservationData.lName,
                firstNameKana: reservationData.fNameKana,
                lastNameKana: reservationData.lNameKana,
                mailAddress: reservationData.emailAddress,
                phoneNum: reservationData.phoneNumber,
              }}
              takeout={reservationData.howToGet.receiveMethod[reservationData.howToGet.flag]}
            />
            <hr className={classNames(css.lineM)} />
            <div className={css.title}>決済</div>
            {reservationData.paymentMethod === 'stripe' && (
              <div className={classNames(css.cardNum)}>
                <img src={image['credit_card.svg']} className={classNames(css.cardIcon)} />
                <span className={css.text}>
                  クレジットカード
                  <br />
                  <span className={classNames(css.cardFlex)}>
                    <img src={image[`${reservationData.creditBrand}.webp`]} className={classNames(css.cardBrand)} />
                    <span className={css.cardText}>****-****-****-{reservationData.creditNumber}</span>
                  </span>
                </span>
              </div>
            )}
            {reservationData.paymentMethod === 'payPay' && (
              <img src={image['pay_pay.webp']} className={classNames(css.payPay)} />
            )}
            <LinkButton
              className={classNames(css.linkButton)}
              width={inputFormWidth}
              height={linkButtonHeight}
              text="TOPへ"
              color="white"
              arrow="right"
              active={true}
              onClick={() => {
                handleLinkClick();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
