import { atom } from 'recoil';

export const QuizUserState = atom({
  key: 'QuizUserState',
  default: {
    challengeCount: 0,
    loggedIn: false,
  },
});

export const QuizState = atom({
  key: 'QuizState',
  default: {
    currentQuestion: 0,
    failureReason: '',
    selectedOption: '',
    score: 0,
  },
});

export const QuizSoundState = atom({
  key: 'QuizSoundState',
  default: {
    enabled: false,
  },
});

export const QuizCouponLinkState = atom({
  key: 'QuizCouponLinkState',
  default: '',
});
