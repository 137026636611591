import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.failPrize {
    .Tiramisu_jap {
      color: #fff;
      /* font-family: 'Source Han Sans JP'; */
      font-weight: 900;
      -webkit-text-stroke: calc(0.1vw + 2px) #5f2f17;
      font-family: 'AvenirLTStd-Black';
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.1, 0.095, 0.09]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.095, 0.09]),
          marginTop: multiplyArrays(props.bodyWidth, [0.2, 0.15, 0.1]),
        });
      }}
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .blue_cross {
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.112]),
          height: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.112]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
          marginRight: multiplyArrays(props.bodyWidth, [0.05, 0.04, 0.03]),
        });
      }}
    }
    .incorrect_container {
      ${(props) => {
        return responsiveStyle({
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
        });
      }}
    }
    .incorrect_ans {
      color: #0075ff;
      /* font-family: 'Source Han Sans JP'; */
      font-family: 'AvenirLTStd-Black';
      font-weight: 900;
      -webkit-text-stroke: calc(0.1vw + 1px) white;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.112]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.112]),
        });
      }}
    }
    .correct_ans {
      color: #000;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          marginTop: multiplyArrays(props.bodyWidth, [0.04, 0.03, 0.02]),
        });
      }}
    }
    .try_again {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'flex')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '140%' : '75%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-5%' : '15%')};
      color: #000;
      font-family: 'Kiwi Maru';
      font-weight: 500;
      -webkit-text-stroke: calc(0.03vw + 0.2px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.058, 0.05]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
        });
      }}
    }
    .cross {
      margin: auto;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.176, 0.16, 0.15]),
          height: multiplyArrays(props.bodyWidth, [0.176, 0.16, 0.15]),
          marginTop: multiplyArrays(props.bodyWidth, [0.02, 0.01, 0.01]),
        });
      }}
    }
    .correct_upto {
      color: #000;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      -webkit-text-stroke: calc(0.03vw + 0.2px) #fff;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
        });
      }}
    }
    .discount {
      color: #000;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      -webkit-text-stroke: calc(0.03vw + 0.25px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.06, 0.058, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.06, 0.058, 0.056]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
        });
      }}
    }
    .coupon_container {
      position: relative;
      margin: auto;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.94, 0.86, 0.8]),
          height: multiplyArrays(props.bodyWidth, [0.47, 0.48, 0.4]),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.02),
        });
      }}
    }
    .whiteRect {
      width: 100%;
      height: 100%;
      background-color: white;
      position: relative;
    }
    // .blackBorder {
    //   width: calc(100% - 7.44vw); /* subtracting 2 * 3.72vw from width */
    //   height: calc(100% - 7.44vw); /* subtracting 2 * 3.72vw from height */
    //   border: 1vw solid black;
    //   box-sizing: border-box;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   @media (min-width: 1024px) {
    //     width: calc(100% - 4.836vw);
    //     height: calc(100% - 4.836vw);
    //   }
    //   @media (min-width: 1920px) {
    //     width: calc(100% - 3.72vw);
    //     height: calc(100% - 3.72vw);
    //   }
    // }
    // .b_symbol {
    //   position: absolute;
    //   top: 50%;
    //   left: 0%; /* Align the left edge of the b_symbol with the left side of the black border */
    //   transform: translateY(-50%);
    //   height: 100%; /* Set the height to match the height of the black border */
    //   width: calc(
    //     52.3 * 0.01 * var(--blackBorderHeight)
    //   ); /* Set the width to be in the ratio of 0.523 of the height of the black border */
    //   max-width: 18.37vw; /* Set a max-width for the image */
    //   max-height: 35.12vw; /* Set a max-height for the image */
    //   z-index: 10;
    //   @media (min-width: 1024px) {
    //     width: calc(52.3 * 0.01 * 22.82vw); /* Adjust the height for larger screens */
    //   }
    //   @media (min-width: 1920px) {
    //     width: calc(52.3 * 0.01 * 17.56vw); /* Adjust the height for larger screens */
    //   }
    // }

    .blackBorder {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${(props) => {
        const borderThicknessSmall = 0.009;
        const borderThicknessMedium = 0.008;
        const borderThicknessLarge = 0.007;

        const widthScalarSmall = 0.84;
        const widthScalarMedium = 0.78;
        const widthScalarLarge = 0.72;

        const heightScalarSmall = 0.4;
        const heightScalarMedium = 0.4;
        const heightScalarLarge = 0.34;

        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [widthScalarSmall, widthScalarMedium, widthScalarLarge]),
          height: multiplyArrays(props.bodyWidth, [heightScalarSmall, heightScalarMedium, heightScalarLarge]),
          borderWidth: multiplyArrays(props.bodyWidth, [
            borderThicknessSmall,
            borderThicknessMedium,
            borderThicknessLarge,
          ]),
        });
      }}
      border-style: solid;
      border-color: black;
    }
    .b_symbol {
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translateY(-50%);
      z-index: 10;
      ${(props) => {
        const widthScalar = 0.523; // Width relative to the height of the black border

        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [widthScalar, widthScalar, widthScalar]),
          height: multiplyArrays(props.bodyWidth, [1, 1, 1]), // Set height to match the height of the black border
          maxWidth: multiplyArrays(props.bodyWidth, [0.204, 0.204, 0.172]), // max-width scalar
          maxHeight: multiplyArrays(props.bodyWidth, [0.39, 0.39, 0.33]), // max-height scalar
        });
      }}
    }
    .couponText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Noto Sans JP';
      color: #000;
      font-weight: 900;
      .coupon {
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.1, 0.09, 0.08]),
            lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.09, 0.08]),
            marginLeft: multiplyArrays(props.bodyWidth, [0.2, 0.16, 0.14]),
            marginBottom: multiplyArrays(props.bodyWidth, [0.06, 0.05, 0.04]),
          });
        }}
      }
      .couponAmount {
        display: flex;
        white-space: nowrap; /* Prevent text from wrapping onto the next line */
      }
      .coupon_value {
        font-family: 'Noto Sans JP';
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.15, 0.14, 0.13]),
            lineHeight: multiplyArrays(props.bodyWidth, [0.15, 0.14, 0.13]),
            marginLeft: multiplyArrays(props.bodyWidth, [0.2, 0.16, 0.14]),
          });
        }}
      }
      .yen {
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrays(props.bodyWidth, [0.075, 0.07, 0.065]),
            lineHeight: multiplyArrays(props.bodyWidth, [0.075, 0.07, 0.065]),
            marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
          });
        }}
      }
    }
    .page_transition {
      color: #f00;
      font-weight: 800;
      font-family: 'AvenirLTStd-Black';
      -webkit-text-stroke: calc(0.03vw + 0.2px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.06, 0.04, 0.04]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.06, 0.04, 0.04]),
          marginTop: multiplyArrays(props.bodyWidth, [0.01, 0.01, 0.01]),
          marginBottom: multiplyArrayByScalar(props.bodyWidth, [0.06]),
        });
      }}
    }
  }
`;
