const getRandomQuestions = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const addIdToElements = (arr) => {
  return arr.map((item, index) => ({
    ...item,
    id: index + 1,
  }));
};

export const generateQuestions = () => {
  let questions = [];
  questions = questions.concat(getRandomQuestions(levelOne, 2));
  questions = questions.concat(getRandomQuestions(levelTwo, 2));
  questions = questions.concat(getRandomQuestions(levelThree, 2));
  questions = questions.concat(getRandomQuestions(levelFour, 2));
  questions = questions.concat(getRandomQuestions(levelFive, 2));
  questions = addIdToElements(questions);
  return questions;
};

const levelOne = [
  {
    question: 'ティラミスの発祥国はどこでしょう？',
    options: ['🇮🇹イタリア', '🇪🇸スペイン', '🇫🇷フランス', '🇩🇪ドイツ'],
    answer: ['🇮🇹イタリア'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ティラミスに使われる代表的なチーズの種類は何でしょう？',
    options: ['マスカルポーネ', 'モッツァレラ', 'ブッラータ', 'カマンベール'],
    answer: ['マスカルポーネ'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ティラミスという言葉の持つ本来の意味は何でしょう？',
    options: ['私を引き上げて', '愛の味を教えて', '甘い夢を見せて', '永遠の記憶に共に'],
    answer: ['私を引き上げて'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'BENE REGALOの読みを答えてください',
    options: ['ベーネレガーロ', 'ベネレガーロ', 'ベーネレガロ', 'ベルレガル'],
    answer: ['ベーネレガーロ'],
    timeLimit: 10,
    points: 1,
    type: 'singleChoice',
  },
];

const levelTwo = [
  {
    question: 'ティラミスのココアパウダーは、どの国で最初に使用されたものでしょうか？',
    options: ['🇳🇱オランダ', '🇧🇪ベルギー', '🇮🇹イタリア', '🇨🇭スイス'],
    answer: ['🇳🇱オランダ'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'マスカルポーネチーズは、通常どの種類のミルクから作られるでしょうか？',
    options: ['牛のミルク', '山羊のミルク', '羊のミルク', '水牛のミルク'],
    answer: ['牛のミルク'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ティラミスの元となったとされるデザートは？',
    options: ['ザバイオーネ', 'パンナコッタ', 'カッサータ', 'マリトッツォ'],
    answer: ['ザバイオーネ'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'マスカルポーネチーズはどの酸性成分を使用して凝固させますか？',
    options: ['クエン酸', 'レモン果汁', 'ビネガー', '乳酸'],
    answer: ['クエン酸'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'マスカルポーネチーズの製造方法として、他のチーズと大きく異なる点は何ですか？',
    options: ['レンネットを使用しない', '牛乳ではなく水牛乳を使用する', '発酵時間が通常より長い', '塩を一切加えない'],
    answer: ['レンネットを使用しない'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
];

const levelThree = [
  {
    question: 'イタリアのどの地域が、マスカルポーネチーズの発祥地として知られていますか？',
    options: ['ロンバルディア州', 'トスカーナ州', 'ヴェネト州', 'シチリア州'],
    answer: ['ロンバルディア州'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ザバイオーネの原料は何でしょうか',
    options: ['卵黄と砂糖', '卵黄とバター', '卵黄と生クリーム', '卵黄と牛乳'],
    answer: ['卵黄と砂糖'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ヴェネト州のどの都市が、ティラミスの発祥地として最も広く知られているでしょうか？',
    options: ['トレヴィーゾ', 'ヴェネツィア', 'ヴェローナ', 'パドヴァ'],
    answer: ['トレヴィーゾ'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ティラミスを初めてメニューに加えたレストランとされる場所を選んでください。',
    options: [
      'Le Beccherie（レ・ベッケリエ）',
      'Antica Pesa（アンティカ・ペーサ）',
      'Trattoria da Romano（トラットリア・ダ・ロマーノ）',
      'Osteria Francescana（オステリア・フランチェスカーナ）',
    ],
    answer: ['Le Beccherie（レ・ベッケリエ）'],
    timeLimit: 15,
    points: 1,
    type: 'singleChoice',
  },
  {
    question:
      'フリウリ・ヴェネチィア・ジュリア州の説では、17世紀に訪れたどの歴史的な人物のためにティラミスが初めて作られたとされていますか？',
    options: ['コジモ3世・デ・メディチ', 'ナポレオン・ボナパルト', 'カール5世', 'カトリーヌ・ド・メディチ'],
    answer: ['コジモ3世・デ・メディチ'],
    timeLimit: 20,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: '過去、ティラミスがヴェネツィアにてどのような人たちのための精力剤のような役割を担っていたでしょうか。',
    options: ['娼婦', '軍人', '農家', '貴族'],
    answer: ['娼婦'],
    timeLimit: 20,
    points: 1,
    type: 'singleChoice',
  },
  {
    question: 'ティラミスの発祥地に関する説の一つとしてあげられる、食文化が豊かな州はどれでしょうか。',
    options: ['トスカーナ州', 'ピエモンテ州', 'エミリア・ロマーニャ州', 'ラツィオ州'],
    answer: ['トスカーナ州'],
    timeLimit: 20,
    points: 1,
    type: 'singleChoice',
  },
];

const levelFour = [
  {
    question:
      'ティラミスワールドカップにおけるオリジナル・ティラミスにおいて、使用しなければいけないとされている材料を6つ全て選択してください。',
    options: [
      'マスカルポーネ',
      'サヴォイアルディ',
      '卵黄',
      'コーヒー',
      'ココア',
      '上白糖',
      '牛乳',
      'バター',
      '卵白',
      'きび砂糖',
      'ラム酒',
      'ビスケット',
    ],
    answer: ['マスカルポーネ', 'サヴォイアルディ', '卵黄', 'コーヒー', 'ココア', '上白糖'],
    timeLimit: 20,
    points: 1,
    type: 'multipleChoice',
  },
  {
    question: 'BENE REGALOで一番最初に生まれたティラミスの商品名はなんでしょうか。',
    options: ['ローマ字（半角）で回答してください', '正確に回答してください'],
    answer: ['Primo Tiramisu'],
    timeLimit: 20,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question: 'BENE REGALOが取り上げられたことのある番組を全て答えてください',
    options: [
      'ヒルナンデス',
      'めざましテレビ',
      'かまいたちの掟',
      'なりゆき街道旅',
      '神業チャレンジ',
      '王様のブランチ',
      'ざわつく金曜日',
      '月曜から夜更かし',
      '水曜日のダウンタウン',
    ],
    answer: ['ヒルナンデス', 'めざましテレビ', 'かまいたちの掟', 'なりゆき街道旅', '神業チャレンジ'],
    timeLimit: 30,
    points: 1,
    type: 'multipleChoice',
  },
  {
    question: 'ティラミスクリームにメレンゲを加えたクリームを俗に何というでしょう。',
    options: ['カタカナで回答してください'],
    answer: ['ロマノフクリーム', 'ロマノフ'],
    timeLimit: 20,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question:
      'BENE REGALOのティラミスにおいて、味を変えるアクセントとして用いられているもののうち、フランス後で「薄く焼いた」を意味する言葉を持つ原料を使用したものを何というでしょう。',
    options: ['カタカナで回答してください', '空白なし'],
    answer: ['ショコラフィアンティーヌ', 'ショコラフィヤンティーヌ'],
    timeLimit: 30,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question:
      'BENE REGALOのティラミスにおいて、味を変えるアクセントとして用いられているもののうち、液状のものを何というでしょう。',
    options: ['カタカナで回答してください', '空白なし'],
    answer: ['エスプレッソソース'],
    timeLimit: 30,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question:
      'ココアパウダー発祥の国であるオランダと、マスカルポーネチーズ発祥の国であるイタリアは、国旗の色に共通点を持っています。共通する色を全て選びなさい。',
    options: ['赤', '白', '青', '緑', '黄', '黒', '橙'],
    answer: ['赤', '白'],
    timeLimit: 20,
    points: 1,
    type: 'multipleChoice',
  },
  {
    question:
      'ティラミスワールドカップにおけるクリエイティブ・ティラミスにおけるルールとして正しいものを全て選択してください。',
    options: [
      'マスカルポーネの使用は必須',
      '卵白の使用は必須',
      '用意すればビスケット使用可',
      '蜂蜜は砂糖の代用品となる',
      '最大4種類の材料を追加可能',
      '最大3種類の材料を追加可能',
    ],
    answer: ['マスカルポーネの使用は必須', '用意すればビスケット使用可', '最大3種類の材料を追加可能'],
    timeLimit: 30,
    points: 1,
    type: 'multipleChoice',
  },
];

const levelFive = [
  {
    question: 'ティラミスを初めて作ったとされる人物の名前を答えてください。',
    options: ['ローマ字（半角）で記入してください', 'ファーストネームとラストネームの間には半角空白を入れてください'],
    answer: ['Roberto Linguanotto'],
    timeLimit: 45,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question: 'BENE REGALOにはブランドコンセプトが2つあります。そのうち、漢字を3種類使う方は何でしょう。',
    options: [],
    answer: ['甘さ控えめな甘え先'],
    timeLimit: 20,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question: 'BENE REGALOにはブランドコンセプトが2つあります。そのうち、漢字を6種類使う方は何でしょう。',
    options: [],
    answer: ['頑張る人への応援菓'],
    timeLimit: 20,
    points: 1,
    type: 'freeNarrative',
  },
  {
    question:
      'ベネト州トレビゾでレストラン「レ・ベッケリエ（Le Beccherie）」を経営していたティラミスの「父」と言われる人は誰でしょうか。',
    options: ['ローマ字（半角）で回答してください', 'ファーストネームとラストネームの間には半角空白を入れてください'],
    answer: ['Ado Campeol'],
    timeLimit: 45,
    points: 1,
    type: 'freeNarrative',
  },
];
