import { useState, useEffect } from 'react';
import shopCss from './index.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../lp-legacy/Footer';

// MEMO: OGP
import { HeadBlock } from '../zz_general/components/head';
import { yoyogiHeadData } from '../zz_general/components/head/data';

export function Yoyogi() {
  useEffect(() => {
    document.documentElement.style.setProperty('--menuWidth', `295`);
    document.documentElement.style.setProperty('--menuHeight', `409`);
  }, []);

  const [clickedMenu, setClickedMenu] = useState('');

  return (
    <>
      <HeadBlock head={yoyogiHeadData} />

      <div className={classNames(shopCss.shopPage)}>
        <div
          className={clickedMenu !== '' ? shopCss.blackCover : shopCss.dNone}
          onClick={() => {
            setClickedMenu('');
          }}
        ></div>
        <div
          className={clickedMenu !== '' ? shopCss.crossIcon : shopCss.dNone}
          onClick={() => {
            setClickedMenu('');
          }}
        >
          ✖
        </div>
        <Link to={'/'} className={shopCss.landingPageLink}>
          <div className={shopCss.cartButton}>
            <img className={shopCss.cartIcon} src={`${process.env.PUBLIC_URL}/cartLogo2.webp`} alt="" />
            <div className={shopCss.buttonText}>購入ページ</div>
          </div>
        </Link>
        <div className={shopCss.body}>
          <div className={shopCss.sec1}>
            <div className={shopCss.title}>BENE REGALO 代々木店</div>
            <img src={`${process.env.PUBLIC_URL}/yoyogi.webp`} alt="" className="" />
          </div>
          <div className={shopCss.sec2}>
            <div className={shopCss.title}>MENU</div>
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/yoyogi/takeout_menu_202404.webp`}
              alt=""
              onClick={() => {
                clickedMenu === 'menu1' ? setClickedMenu('') : setClickedMenu('menu1');
              }}
              className={clickedMenu === 'menu1' ? shopCss.spread : shopCss.menu}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/yoyogi/inuTiramisu.webp`}
              alt=""
              onClick={() => {
                clickedMenu === 'menu3' ? setClickedMenu('') : setClickedMenu('menu3');
              }}
              className={clickedMenu === 'menu3' ? shopCss.spread : shopCss.menu}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/yoyogi/yoyogi_menu_eat_in_menu1.webp`}
              alt=""
              onClick={() => {
                clickedMenu === 'menu4' ? setClickedMenu('') : setClickedMenu('menu4');
              }}
              className={clickedMenu === 'menu4' ? shopCss.spread : shopCss.menu}
            />
          </div>
          <div className={shopCss.sec3}>
            <div className={shopCss.title}>予約・取り置き</div>
            <div className={shopCss.sec3flex}>
              <a href="tel:03-6300-9465" className={shopCss.box1}>
                <img src={`${process.env.PUBLIC_URL}/phone.webp`} alt="" className="" />
                <p>03-6300-9465</p>
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdMKcX9QRaADWmC0_8LZ5snhrvOFFwmPQBY1TekCdctY6lCIA/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className={shopCss.box1}
              >
                <img src={`${process.env.PUBLIC_URL}/reserve.webp`} alt="" className="" />
                <p>WEB予約</p>
              </a>
            </div>
            <div className={shopCss.sec3flex2}>
              <img src={`${process.env.PUBLIC_URL}/warning.webp`} alt="" className="" />
              <p>当店は、5-6人が座れるベンチがございますが、基本的にテイクアウトのお店となります。</p>
            </div>
          </div>
          <div className={shopCss.sec4}>
            <div className={shopCss.title}>アクセス</div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.834521216736!2d139.6977165755477!3d35.6810763725873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d67eda10887%3A0x38f4b875f089a412!2z44OG44Kj44Op44Of44K55bCC6ZaA5bqXIEJFTkUgUkVHQUxPIOS7o-OAheacqOW6lw!5e0!3m2!1sja!2sjp!4v1683357367280!5m2!1sja!2sjp"
              allowfullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className={shopCss.map}
            ></iframe>
          </div>
          <div className={shopCss.sec5}>
            <div className={shopCss.title}>口コミ</div>
            <img src={`${process.env.PUBLIC_URL}/yoyogi_review1.webp`} alt="" className={shopCss.review} />
            <img src={`${process.env.PUBLIC_URL}/yoyogi_review2.webp`} alt="" className={shopCss.review} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
