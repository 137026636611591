import dayjs from 'dayjs';
import { getFormatPhone } from './util';
import { purchasedItems } from '../../lp-legacy/utils/PurchasedItems';

export const sheetHeaders = [
  { header: 'お客様管理番号\n半角英数字50文字', key: 'customerControlNumber' },
  {
    header:
      '送り状種類\n半角数字1文字\n0 : 発払い\n2 : コレクト\n 3 : ＤＭ便\n 4 : タイム\n 5 : 着払い\n 7 : ネコポス\n 8 : 宅急便コンパクト\n9 : 宅急便コンパクトコレクト\n \n(※宅急便_必須項目)\n(※ＤＭ便_必須項目)\n(※ネコポス_必須項目)',
    key: 'invoiceType',
  },
  {
    header: 'クール区分\n半角数字1文字\n0または空白 : 通常\n1 : クール冷凍\n2 : クール冷蔵',
    key: 'coolCategory',
  },
  { header: '伝票番号\n半角数字12文字\n\n※B2クラウドにて付与', key: 'documentNumber' },
  {
    header:
      '出荷予定日\n半角10文字\n｢YYYY/MM/DD｣で入力してください。\n\n(※宅急便_必須項目)\n(※ＤＭ便_必須項目)\n(※ネコポス_必須項目)',
    key: 'estimatedShipDate',
  },
  {
    header:
      'お届け予定日\n  半角10文字\n  ｢YYYY/MM/DD｣で入力してください。\n  \n  ※入力なしの場合、印字されません。\n  ※「最短日」と入力可',
    key: 'estimatedDeliveryDate',
  },
  {
    header:
      '配達時間帯\n  半角4文字\n  タイム、ＤＭ便以外\n   空白 : 指定なし\n   0812 : 午前中\n   1416 : 14～16時\n   1618 : 16～18時\n   1820 : 18～20時\n   1921 : 19～21時\n   \n  タイム\n   0010 : 午前10時まで\n   0017 : 午後5時まで',
    key: 'deliveryTimeSlot',
  },
  { header: 'お届け先コード\n  半角英数字20文字', key: 'shippingCode' },
  {
    header:
      'お届け先電話番号\n  半角数字15文字ハイフン含む\n  \n  (※宅急便_必須項目)\n  (※ＤＭ便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'shippingPhoneNumber',
  },
  { header: 'お届け先電話番号枝番\n  半角数字2文字', key: 'shippingPhoneNumberBranchNumber' },
  {
    header:
      'お届け先郵便番号\n  半角数字8文字\n  ハイフンなし7文字も可\n  \n  (※宅急便_必須項目)\n  (※ＤＭ便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'shippingPostalCode',
  },
  {
    header:
      'お届け先住所\n  全角/半角\n  都道府県（４文字）\n  市区郡町村（１２文字）\n  町・番地（１６文字）\n  \n  (※宅急便_必須項目)\n  (※ＤＭ便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'deliveryAddress',
  },
  { header: 'お届け先アパートマンション名\n  全角/半角 \n  16文字/32文字', key: 'destinationApartmentApartmentName' },
  { header: 'お届け先会社・部門１\n  全角/半角\n  25文字/50文字', key: 'destinationCompanyDepartment1' },
  { header: 'お届け先会社・部門２\n  全角/半角 \n  25文字/50文字', key: 'destinationCompanyDepartment2' },
  {
    header:
      'お届け先名\n  全角/半角\n  16文字/32文字 \n  \n  (※宅急便_必須項目)\n  (※ＤＭ便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'shippingName',
  },
  { header: 'お届け先名(ｶﾅ)\n  半角カタカナ 50文字', key: 'shippingNameKana' },
  {
    header:
      '敬称\n  全角/半角 2文字/4文字\n  ＤＭ便の場合に指定可能\n  【入力例】\n  様・御中・殿・行・係・宛・先生・なし',
    key: 'titleOfHonor',
  },
  { header: 'ご依頼主コード\n  半角英数字 20文字', key: 'requesterCode' },
  {
    header: 'ご依頼主電話番号\n  半角数字15文字ハイフン含む\n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'requestersPhoneNumber',
  },
  { header: 'ご依頼主電話番号枝番\n  半角数字 2文字', key: 'requestersPhoneNumberBranchNumber' },
  {
    header:
      'ご依頼主郵便番号\n  半角数字8文字\n  ハイフンなし半角7文字も可 \n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'requestersPostalCode',
  },
  {
    header:
      'ご依頼主住所\n  全角/半角32文字/64文字\n  都道府県（４文字）\n  市区郡町村（１２文字）\n  町・番地（１６文字）\n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'requestersAddress',
  },
  { header: 'ご依頼主アパートマンション\n  全角/半角 16文字/32文字', key: 'clientApartmentApartment' },
  {
    header: 'ご依頼主名\n  全角/半角 16文字/32文字 \n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'nameOfTheClient',
  },
  { header: 'ご依頼主名(ｶﾅ)\n  半角カタカナ 50文字', key: 'nameOfTheClientKana' },
  { header: '品名コード１\n  半角英数字 30文字', key: 'ProductCode1' },
  {
    header: '品名１\n  全角/半角 25文字/50文字 \n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'productName1',
  },
  { header: '品名コード２\n  半角英数字 30文字', key: 'ProductCode2' },
  { header: '品名２\n  全角/半角 25文字/50文字', key: 'productName2' },
  { header: '荷扱い１\n  全角/半角 10文字/20文字', key: 'cargoHandling1' },
  { header: '荷扱い２\n  全角/半角 10文字/20文字', key: 'cargoHandling2' },
  { header: '記事\n  全角/半角 22文字/44文字', key: 'article' },
  {
    header:
      'ｺﾚｸﾄ代金引換額（税込)\n  半角数字 7文字\n  \n  ※コレクトの場合は必須\n  300,000円以下 1円以上\n  ※但し、宅急便コンパクトコレクトの場合は\n  30,000円以下 1円以上',
    key: 'collectCashOnDeliveryTaxIncluded',
  },
  {
    header: '内消費税額等\n  半角数字 7文字\n  \n  ※コレクトの場合は必須 \n  ※コレクト代金引換額（税込)以下',
    key: 'consumptionTaxEtc',
  },
  { header: '止置き\n  半角数字 1文字\n  0 : 利用しない\n  1 : 利用する', key: 'stationary' },
  { header: '営業所コード\n  半角数字 6文字\n  \n  ※止置きを利用する場合は必須', key: 'salesOfficeCode' },
  { header: '発行枚数\n  半角数字 2文字\n  \n  ※発払いのみ指定可能', key: 'numberOfTicketsIssued' },
  {
    header:
      '個数口表示フラグ\n  半角数字 1文字\n  1 : 印字する\n  2 : 印字しない \n  3 : 枠と口数を印字する\n  \n  ※宅急便コンパクト、宅急便コンパクトコレクトは対象外',
    key: 'countDisplayFlag',
  },
  {
    header: '請求先顧客コード\n  半角数字12文字\n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'billingCustomerCode',
  },
  { header: '請求先分類コード\n  空白または半角数字3文字', key: 'billingClassificationCode' },
  {
    header: '運賃管理番号\n  半角数字2文字\n  \n  (※宅急便_必須項目)\n  (※ネコポス_必須項目)',
    key: 'FareControlNumber',
  },
];

const DeliveryTimeSlotObject = {
  OTHER: '',
  812: '0812', // MEMO: 0812 : 午前中
  1416: '1416', // MEMO: 1416 : 14～16時
  1618: '1618', // MEMO: 1618 : 16～18時
  1820: '1820', // MEMO: 820 : 18～20時
  1921: '1921', // MEMO: 921 : 19～21時
};

export const sheetBody = (orders) => {
  // ヤマト帳票出力のヤマトさん側の仕様(2023/11/12)
  // 住所欄：全角16文字、半角32文字
  // お届け先名称：全角16文字、半角32文字
  // 品名：17文字以内
  // 品名コード：半角英数字30文字以内

  const subtractMonth = 1;
  const aMonthAgo = dayjs().subtract(subtractMonth, 'months').format('YYYY/MM/DD');

  return orders
    .map((order) => {
      const d = order[0].props?.order[1]?.orderDate?.toDate() ? order[0].props.order[1].orderDate.toDate() : null;
      const dateOfReceipt = d ? dayjs(d).format('YYYY/MM/DDTHH:mm:ss') : ''; // MEMO: 受付日付
      const today = dayjs().format('YYYY/MM/DD');

      const orderInfo = order[0].props?.order[1]?.orderInfo; // 注文商品情報 お届け先情報
      const customerInfoBasicInfo = order[0].props?.order[1]?.customerInfo?.basicInfo; // 注文者情報
      const orderItems = orderInfo?.items; // 注文商品情報

      const productName1 = purchasedItems(orderInfo?.items);

      const estimatedDeliveryDate =
        orderItems?.deliveryDate === '指定なし' || orderItems?.deliveryDate === undefined
          ? ''
          : dayjs(orderItems?.deliveryDate.replace('年', '/').replace('月', '/').replace('日', '').trim()).format(
              'YYYY/MM/DD'
            );

      const estimatedShipDate =
        estimatedDeliveryDate === '' ? today : dayjs(estimatedDeliveryDate).subtract(2, 'day').format('YYYY/MM/DD');

      const deliveryTimeSlot =
        // MEMO: DB登録の段階でヤマト形式にしたいなぁ
        orderItems?.deliveryTime === '8:00 ~ 12:00'
          ? DeliveryTimeSlotObject[812]
          : orderItems?.deliveryTime === '14:00 ~ 16:00'
          ? DeliveryTimeSlotObject[1416]
          : orderItems?.deliveryTime === '16:00 ~ 18:00'
          ? DeliveryTimeSlotObject[1618]
          : orderItems?.deliveryTime === '18:00 ~ 20:00'
          ? DeliveryTimeSlotObject[1820]
          : orderItems?.deliveryTime === '19:00 ~ 21:00'
          ? DeliveryTimeSlotObject[1921]
          : DeliveryTimeSlotObject.OTHER;

      const shippingName = `${orderInfo?.lName} ${orderInfo?.fName}`;
      const shippingPhoneNumber = orderInfo?.phoneNum ? getFormatPhone(order[0].props.order[1].orderInfo.phoneNum) : '';
      const shippingPostalCode = orderInfo?.postalCode.replace(/(\d{3})/, '$1-');
      const deliveryAddress = orderInfo?.address;

      let requestersPhoneNumber;
      let requestersPostalCode;
      let requestersAddress;
      let nameOfTheClient;

      let requesterCode;
      let clientApartmentApartment;

      if (orderInfo?.address === customerInfoBasicInfo?.address) {
        // MEMO: 配送先と請求先の住所が同じ場合はBENE REGALOが依頼主
        requestersPhoneNumber = '042-808-7933';
        requestersPostalCode = '197-0804';
        requestersAddress = '東京都あきる野市秋川1-8';
        nameOfTheClient = 'BENE REGALO';

        requesterCode = '00001';
        clientApartmentApartment = 'あきる野ルピア1階';
      } else {
        requestersPhoneNumber = customerInfoBasicInfo?.phoneNum ? getFormatPhone(customerInfoBasicInfo.phoneNum) : '';
        requestersPostalCode = customerInfoBasicInfo?.postalCode
          ? customerInfoBasicInfo.postalCode.replace(/(\d{3})/, '$1-')
          : '';
        requestersAddress = customerInfoBasicInfo?.address ? customerInfoBasicInfo.address : '';
        nameOfTheClient =
          customerInfoBasicInfo?.lName || customerInfoBasicInfo?.fName
            ? `${customerInfoBasicInfo.lName} ${customerInfoBasicInfo.fName}`
            : '';

        requesterCode = '';
        clientApartmentApartment = '';
      }
      /** MEMO: 商品追加時にcoolCategoryの追加が必要 */
      const isNeedsCoolShippingItems = (orderItems) => {
        // MEMO: coolCategory 0:常温 1:冷凍
        if (
          orderItems?.uid1 > 0 ||
          orderItems?.uid2 > 0 ||
          orderItems?.uid6 > 0 ||
          orderItems?.uid8 > 0 ||
          orderItems?.uid9 > 0 ||
          orderItems?.uid10 > 0 ||
          orderItems?.uid11 > 0 ||
          orderItems?.uid12 > 0 ||
          // orderItems?.uid13 > 0 ||
          // orderItems?.uid14 > 0 ||
          // orderItems?.uid15 > 0 ||
          // MEMO: 白黒復活のため残す
          // orderItems?.uid16 > 0 ||
          // orderItems?.uid17 > 0 ||
          orderItems?.uid18 > 0 ||
          orderItems?.uid19 > 0 ||
          orderItems?.uid20 > 0 ||
          orderItems?.uid21 > 0 ||
          orderItems?.uid22 > 0 ||
          orderItems?.uid23 > 0 ||
          orderItems?.uid25 > 0
          // MEMO: 白黒復活のため残す
          // orderItems?.uid24 > 0
        ) {
          return true;
        }
        return false;
      };

      const coolCategory = isNeedsCoolShippingItems(orderItems);

      return {
        dateOfReceipt: dateOfReceipt, // MEMO: 直近のデータだけを絞り込むのに必要。エクセルにはkeyが指定されていないため書き込まれない。
        items: orderItems, // MEMO: 直近のデータだけを絞り込むのに必要。エクセルにはkeyが指定されていないため書き込まれない。
        order: order[0].props?.order[0], // MEMO: 直近のデータだけを絞り込むのに必要。エクセルにはkeyが指定されていないため書き込まれない。

        customerControlNumber: '', // MEMO: お客様管理番号半角英数字50文字
        invoiceType: '0', // MEMO: 送り状種類
        coolCategory: coolCategory ? 1 : 0, // MEMO: クール区分
        documentNumber: '', // MEMO: 伝票番号
        estimatedShipDate: estimatedShipDate, // MEMO: 出荷予定日
        estimatedDeliveryDate: estimatedDeliveryDate, // MEMO: お届け予定日
        deliveryTimeSlot: deliveryTimeSlot, // MEMO: 配達時間帯
        shippingCode: '', // MEMO: お届け先コード
        shippingPhoneNumber: shippingPhoneNumber, // MEMO: お届け先電話番号
        shippingPhoneNumberBranchNumber: '', // MEMO: お届け先電話番号枝番
        shippingPostalCode: shippingPostalCode, // MEMO: お届け先郵便番号
        deliveryAddress: deliveryAddress, // MEMO: お届け先住所
        destinationApartmentApartmentName: '', // MEMO: お届け先アパートマンション名
        destinationCompanyDepartment1: '', // MEMO: お届け先会社・部門１
        destinationCompanyDepartment2: '', // MEMO: お届け先会社・部門２
        shippingName: shippingName, // MEMO: お届け先名
        shippingNameKana: '', // MEMO: お届け先名(ｶﾅ)
        titleOfHonor: '', // MEMO: 敬称
        requesterCode: requesterCode, // MEMO: ご依頼主コード
        requestersPhoneNumber: requestersPhoneNumber, // MEMO: ご依頼主電話番号
        requestersPhoneNumberBranchNumber: '', // MEMO: ご依頼主電話番号枝番
        requestersPostalCode: requestersPostalCode, // MEMO: ご依頼主郵便番号
        requestersAddress: requestersAddress, // MEMO: ご依頼主住所
        clientApartmentApartment: clientApartmentApartment, // MEMO: ご依頼主アパートマンション
        nameOfTheClient: nameOfTheClient, // MEMO: ご依頼主名
        nameOfTheClientKana: '', // MEMO: ご依頼主名(ｶﾅ)
        ProductCode1: '', // MEMO: 品名コード１
        productName1: productName1, // MEMO: 品名１
        ProductCode2: '', // MEMO: 品名コード２
        productName2: '', // MEMO: 品名２
        cargoHandling1: '', // MEMO: 荷扱い１
        cargoHandling2: '', // MEMO: 荷扱い２
        article: '', // MEMO: 記事
        collectCashOnDeliveryTaxIncluded: '', // MEMO: ｺﾚｸﾄ代金引換額（税込)
        consumptionTaxEtc: '', // MEMO: 内消費税額等
        stationary: '', // MEMO: 止置き
        salesOfficeCode: '', // MEMO: 営業所コード
        numberOfTicketsIssued: '', // MEMO: 発行枚数
        countDisplayFlag: '', // MEMO: 個数口表示フラグ
        billingCustomerCode: '0428087933-', // MEMO: 請求先顧客コード
        billingClassificationCode: '', // MEMO: 請求先分類コード
        FareControlNumber: '01', // MEMO: 運賃管理番号
      };
    })
    .sort(function (a, b) {
      return a.dateOfReceipt > b.dateOfReceipt ? -1 : 1; // 降順ソート
    })
    .filter((value) => {
      return `${value.dateOfReceipt}` >= `${aMonthAgo}`;
    });
};
