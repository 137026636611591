import { useEffect, useState } from 'react';
import { Wrapper } from './start.scss';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';

import { QuizHeader } from '../components/quizHeader';
import { consoleLog } from '../../zz_general/utils/snippets/console';
import { updateUser } from '../utils/update';
import { QuizWrapper } from '../components/quizWrapper';
import { QuizState, QuizUserState } from '../store/atom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginWithLineUser, loginWithTestUser } from '../utils/login';
import { env, isProd, publicUrl } from '../../zz_general/utils/snippets/env';
import { changePage } from '../utils/navigate';
import { HeadBlock } from '../../zz_general/components/head';
import { quizHeadData } from '../../zz_general/components/head/data';

const imgPath = (fileName) => publicUrl + '/quiz/start/images/' + fileName;

export const QuizStart = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [user, setUser] = useRecoilState(QuizUserState);
  const resetQuiz = useResetRecoilState(QuizState);

  const [isTextVisible, setIsTextVisible] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const [availablePrizes, setAvailablePrizes] = useState(0);
  const btnNotAvailable = user.challengeCount === 0 || user.achieved || availablePrizes === 0;

  const fetchAvailablePrizes = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'prizes'));
      const docs = {};
      let count = 0;
      querySnapshot.forEach((doc) => {
        docs[doc.id] = doc.data();
        count = count + doc.data().selected;
      });
      setAvailablePrizes(count);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching documents: ', error);
    }
  };

  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  const handleStartClick = () => {
    if (btnNotAvailable) {
      return;
    }
    resetQuiz();
    updateUser(user, setUser, { challengeCount: user.challengeCount - 1 });
    consoleLog('start button clicked');
    changePage(navigate, 'questions', 'start');
  };

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };

  const login = async () => {
    if (user.loggedIn) return;

    let result;

    if (query.get('state') === 'login') {
      if (env === 'dev') {
        result = await loginWithTestUser();
      } else {
        result = await loginWithLineUser(query);
      }
    }
    if (!result) {
      changePage(navigate, '', 'start');
      return;
    }

    setUser({ ...result, loggedIn: true });
    changePage(navigate, 'start', 'login');
  };

  useEffect(() => {
    const async = async () => {
      setPageIsLoading(true);
      await fetchAvailablePrizes();
      await login();
      setPageIsLoading(false);
    };
    async();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HeadBlock head={quizHeadData} />
      <QuizWrapper
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
        pageIsLoading={pageIsLoading}
      >
        <Wrapper
          className="start"
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          screenOrientation={screenOrientation}
          bodyWidth={bodyWidth}
          btnNotAvailable={btnNotAvailable}
        >
          <QuizHeader
            onClick={() => {
              changePage(navigate, '', 'start');
            }}
            title="ティラミスクイズ"
          />
          <div>
            <img src={imgPath('cake_tiramisu.webp')} alt="Image" className="Tiramisu_cake" />
          </div>
          <div className="buttonContainer">
            <span className="Rules_text">ルール確認</span>
            <button className="toggleButton" onClick={toggleTextVisibility}>
              {isTextVisible ? <span className="collapseButton">-</span> : <span className="collapseButton">+</span>}
            </button>
          </div>
          <div style={{ display: isTextVisible ? 'grid' : 'none' }} className="rulesWrap">
            <div className="rules">
              <div className="rule">
                <span>1.</span>問題は全10問
              </div>
              <div className="rule">
                <span>2.</span>それぞれ制限時間あり
              </div>
              <div className="rule">
                <span>3.</span>不正解の時点で終了
              </div>
              <div className="rule">
                <span>4.</span>不正解の場合、正答は表示されません。
              </div>
              <div className="rule">
                <span>5.</span>チャレンジは1人1回
              </div>
            </div>
          </div>
          <div className="retry"> チャレンジ可能回数 </div>
          {user.challengeCount !== null && <div className="retryCount">{user.challengeCount}</div>}
          <div className="click_to_start">
            {user.challengeCount === 0 && 'チャレンジ可能回数がありません'}
            {btnNotAvailable || '＼ボタンをクリックで挑戦がスタート／'}
            {user.achieved && <p>チャレンジ達成済みです</p>}
            {availablePrizes === 0 && (
              <p>
                賞品が品切れとなってしまいました。
                <br />
                次回のチャレンジをお待ち下さい。
                <br />
                <br />
              </p>
            )}
          </div>
          <div className="btn_box">
            <div onClick={handleStartClick} className="btn">
              チャレンジスタート
            </div>
          </div>
          <div className="chance_increment">
            <div
              className="btn_2"
              onClick={() => {
                changePage(navigate, 'code-input', 'start');
              }}
            >
              チャレンジ回数を増やす
            </div>
          </div>
        </Wrapper>
      </QuizWrapper>
    </>
  );
};
