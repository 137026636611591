import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../zz_general/utils/configs/firebase';
import { consoleError, consoleLog } from '../../../zz_general/utils/snippets/console';

export const updateUser = async (user, setUser, data) => {
  try {
    const docRef = doc(
      db,
      'quiz',
      process.env.REACT_APP_IS_PROD === 'true' ? 'quiz' : 'quiz-test',
      'users',
      user.userInfo.sub
    );
    setUser({
      ...user,
      ...data,
    });
    await updateDoc(docRef, data);
    consoleLog('User document updated successfully!');
  } catch (error) {
    consoleError('Error updating user document: ', error);
    return;
  }
};
