import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.start {
    .content {
      display: flex;
      align-items: center;
    }
    .home_button {
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.13]),
          height: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.13]),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
          // marginRight: multiplyArrayByScalar(props.bodyWidth, 0.05),
        });
      }}
    }
    .top_tiramisu {
      color: #fff;
      font-family: 'Source Han Sans JP';
      font-weight: 900;
      -webkit-text-stroke: calc(0.1vw + 2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.08, 0.075]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.095, 0.09]),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
          marginLeft: multiplyArrays(props.bodyWidth, [0.03, 0.08, 0.05]),
        });
      }}
    }
    .Tiramisu_cake {
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.86, 0.7, 0.7]),
          height: multiplyArrays(props.bodyWidth, [0.6, 0.49, 0.49]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.04]),
        });
      }}
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
    }
    .Rules_text {
      position: relative;

      color: #fff;
      font-family: 'AvenirLTStd-Black';
      font-weight: bolder;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.08, 0.075]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.095, 0.09]),
          marginTop: multiplyArrays(props.bodyWidth, [0.07, 0.05, 0.03]),
        });
      }}
    }

    .Rules_text::before {
      content: 'ルール確認';
      color: #5f2f17;
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }

    .collapseButton {
      color: #fff;
      cursor: pointer;
      -webkit-text-stroke: calc(0.05vw + 2px) #5f2f17;
      font-weight: 500;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.12),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.08),
          marginTop: multiplyArrays(props.bodyWidth, [0.07, 0.03, 0.01]),
          marginLeft: multiplyArrayByScalar(props.bodyWidth, 0.02),
        });
      }}
    }
    .toggleButton {
      display: flex;
      justify-content: center;
    }
    .rulesWrap {
      @media (min-width: 500px) {
        display: grid;
        place-items: center;
        height: auto;
      }
      .rules {
        margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '0' : '12%')};
        margin-right: ${(props) => (props.screenOrientation === 'horizontal' ? '0' : '4%')};
        margin: ${(props) => props.screenOrientation === 'vertical' && '0.7 auto'};
        ${(props) => {
          return responsiveStyle({
            marginTop: multiplyArrayByScalar(props.bodyWidth, 0.01),
          });
        }}
        @media (min-width: 500px) {
          width: ${(props) => (props.screenOrientation === 'horizontal' ? '120%' : '100%')};
        }

        .rule {
          display: grid;
          grid-template-columns: auto 1fr;
          text-align: left;
          color: #000;
          font-weight: 500;
          font-family: 'Kiwi Maru';
          -webkit-text-stroke: calc(0.02vw + 0.1px) #f0dcaa;
          ${(props) => {
            return responsiveStyle({
              fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
              lineHeight: multiplyArrays(props.bodyWidth, [0.085, 0.08, 0.075]),
            });
          }}

          span {
          }
        }
      }
    }
    .retry {
      color: #fff;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      -webkit-text-stroke: calc(0.06vw + 0.9px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.09, 0.09]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.04]),
        });
      }}
    }
    .retryCount {
      color: #fff;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      -webkit-text-stroke: calc(0.08vw + 2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.112]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.1, 0.09, 0.09]),
          marginTop: multiplyArrays(props.bodyWidth, [0.01, 0.01, 0.01]),
        });
      }}
    }
    .click_to_start {
      color: black;
      font-family: 'Kiwi Maru';
      font-weight: 500;
      -webkit-text-stroke: calc(0.01vw + 0.06px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.042, 0.04, 0.038]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.082, 0.074, 0.074]),
          marginTop: multiplyArrays(props.bodyWidth, [0.1, 0.1, 0.1]),
        });
      }}
    }
    .btn {
      color: #fff;
      font-weight: 900;
      /* font-family: 'Source Han Sans JP'; */
      font-family: 'AvenirLTStd-Black';
      font-weight: bolder;
      text-align: center;
      background-color: ${(props) => (props.btnNotAvailable ? '#D9D9D9' : '#fffa83')};
      cursor: ${(props) => (props.btnNotAvailable ? 'not-allowed' : 'pointer')};
      -webkit-text-stroke: calc(0.1vw + 1px) ${(props) => (props.btnNotAvailable ? '#A7A7A7' : '#5F2F17')};
      position: relative;
      display: inline-block;
      z-index: 10;
      border: ${(props) =>
        `${multiplyArrays(props.bodyWidth, [0.027, 0.022, 0.017])[0]}px solid ${
          props.btnNotAvailable ? '#A7A7A7' : '#5F2F17'
        }`};
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.85, 0.8, 0.75])
              : multiplyArrayByScalar(props.bodyWidth, 0.95),
          height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.14]),
        });
      }}
    }
    .btn:before {
      position: absolute;
      content: '';
      width: ${(props) =>
        `calc(100% + ${multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.06])[0]}px)`}; /* Adjust for responsiveness */
      height: ${(props) =>
        `calc(100% + ${multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.06])[0]}px)`}; /* Adjust for responsiveness */
      top: ${(props) => `-${multiplyArrays(props.bodyWidth, [0.04, 0.04, 0.04])[0]}px`};
      left: ${(props) => `-${multiplyArrays(props.bodyWidth, [0.04, 0.04, 0.04])[0]}px`};
      z-index: -10;
      background: ${(props) => (props.btnNotAvailable ? '#A7A7A7' : '#5F2F17')}; /* Outer border color */
      border-radius: inherit;
      animation: ${(props) => (props.btnNotAvailable ? 'none' : 'uyon 1s linear infinite')};
    }
    .btn:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -3;
      background: ${(props) => (props.btnNotAvailable ? '#D9D9D9' : '#fffa83')};
      border-radius: inherit;
    }
    @keyframes uyon {
      0% {
        opacity: 1;
        transform: scaleY(1) scaleX(1);
      }
      20% {
        opacity: 0.8;
      }
      70% {
        opacity: 0.2;
        transform: scaleY(1.2) scaleX(1.05);
      }
      80% {
        opacity: 0;
        transform: scaleY(1.2) scaleX(1.05);
      }
      90% {
        opacity: 0;
        transform: scaleY(1) scaleX(1);
      }
    }
    .chance_increment {
      display: flex;
      justify-content: center;
      align-items: center;
      ${(props) => {
        return responsiveStyle({
          marginTop: multiplyArrays(props.bodyWidth, [0.04, 0.03, 0.02]),
          marginBottom: multiplyArrays(props.bodyWidth, [0.04, 0.03, 0.02]),
        });
      }}
    }
    .btn_2 {
      color: #5f2f17;
      font-weight: 500;
      font-family: 'Kiwi Maru';
      text-align: center;
      background-color: #f0dcaa;
      cursor: pointer;
      border: ${(props) => `${multiplyArrays(props.bodyWidth, [0.012, 0.01, 0.008])[0]}px solid #5F2F17`};
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'vertical'
              ? multiplyArrays(props.bodyWidth, [0.85, 0.8, 0.75])
              : multiplyArrayByScalar(props.bodyWidth, 0.95),
          height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.06, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.19, 0.16, 0.15]),
        });
      }}
    }
  }
`;
