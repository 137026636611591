import { atom, selector } from 'recoil';

//
export const LPErrorPageState = atom({
  key: 'LPErrorPageState',
  default: {
    show: false,
    message: '',
    code: '',
  },
});

// 顧客情報を保存（配送/店舗受け取り共通）
export const LPCustomerState = atom({
  key: 'LPCustomerState',
  default: {
    reservationId: '',
    receiveDM: true,
  },
});

// 決済情報を保存（配送/店舗受け取り共通）
export const LPPaymentState = atom({
  key: 'LPPaymentState',
  default: {
    mode: 'stripe',
    stripe: {
      paymentIntent: {
        clientSecret: 'null_secret',
      },
      paymentMethod: {
        id: 'null_id',
        card: {
          brand: 'null',
          last4: 'null',
        },
      },
    },
    payPay: {
      paymentLink: 'null_link',
    },
  },
});

// フォームのバリデーション状況を保存（配送/店舗受け取り共通）
export const LPFormState = atom({
  key: 'LPFormState',
  default: {
    delivery_customerFormIsValid: false, // customer-infoページが有効か (配送ユーザー)
    delivery_deliveryFormIsValid: false, // delivery-infoページが有効か (配送ユーザー)
    takeout_customerFormIsValid: false, // customer-infoページが有効か (受取ユーザー)
    // TODO : お問い合わせフォームのバリデーション状況を追加する
    content: {
      lastName: 'not filled',
      firstName: 'not filled',
      lastNameKana: 'not filled',
      firstNameKana: 'not filled',
      mailAddress: 'not filled',
      phoneNum: 'not filled',
      zipCode: 'not filled',
      street: 'not filled',
      building: 'valid',
      corporation: 'valid',
      takeoutPlace: 'not filled',
      takeoutDate: 'not filled',
      takeoutTime: 'not filled',
      deliveryDate: 'not filled',
      deliveryTime: 'not filled',
    },
  },
});

// 配送日時を保存（配送ユーザー）
export const LPDeliveryState = atom({
  key: 'LPDeliveryState',
  default: {},
});

// 住所情報を保存（配送ユーザー）
export const LPAddressState = atom({
  key: 'LPAddressState',
  default: {
    selected: 0,
    list: [],
  },
});

// 受け取り日時を保存（店舗受け取りユーザー）
export const LPTakeoutState = atom({
  key: 'LPTakeoutState',
  default: {},
});

export const LPtotalPriceState = selector({
  key: 'LPtotalPriceState',
  get: ({ get }) => {
    const cart = get(LPCartState);
    let shippingFee = 0;

    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (key === 'uid4') {
          shippingFee = 800;
        }
      }
    }
    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (
          key === 'uid1' ||
          key === 'uid2' ||
          key === 'uid8' ||
          key === 'uid11' ||
          key === 'uid12' ||
          key === 'uid18' ||
          key === 'uid25' ||
          key === 'uid31' ||
          key === 'uid38' ||
          key === 'uid39' ||
          key === 'uid42'
          // MEMO: 白黒復活のため残す
          // key === 'uid16' ||
          // key === 'uid17' ||
        ) {
          shippingFee = 1000;
        }
      }
    }

    // MEMO: 送料無料があと勝ち
    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (
          key === 'uid9' ||
          key === 'uid10' ||
          // key === 'uid13' ||
          // key === 'uid14' ||
          // key === 'uid15' ||
          key === 'uid23' ||
          key === 'uid22' ||
          key === 'uid21' ||
          key === 'uid20' ||
          key === 'uid19' ||
          key === 'uid26' ||
          key === 'uid27' ||
          key === 'uid28' ||
          key === 'uid29' ||
          key === 'uid30' ||
          key === 'uid32' ||
          key === 'uid33' ||
          key === 'uid34' ||
          key === 'uid40'
          // MEMO: 白黒復活のため残す
          // key === 'uid24'
        ) {
          shippingFee = 0;
        }
      }
    }
    return sumUpCart(cart) + shippingFee;
  },
});

export const sumUpCart = (cart) => {
  let totalPrice = 0;
  for (let key in cart.items) {
    totalPrice += cart.items[key].price * cart.items[key].count;
  }
  return totalPrice;
};

export const LPtotalCountState = selector({
  key: 'LPtotalCountState',
  get: ({ get }) => {
    const cart = get(LPCartState);
    let count = 0;
    for (const key in cart.items) {
      if (key !== 'uid38') {
        count += cart['items'][key]['count'];
      }
    }
    return count;
  },
});

export const LPCartState = atom({
  key: 'LPCartState',
  default: {
    items: {
      uid1: {
        name: 'Primo Tiramisu in box',
        description: 'Tiramisu in box',
        price: 5000,
        count: 0,
        fileName: 'BENE_REGALO_Tiramisu_in_box.webp',
        fileName2: 'BENE_REGALO_Tiramisu_in_box.avif',
        disabled: false,
        limit: 2,
      },
      uid2: {
        name: 'Primo Tiramisu in bag',
        description: 'Tiramisu in Bag',
        price: 4000,
        count: 0,
        fileName: 'BENE_REGALO_Tiramisu_in_bag.webp',
        fileName2: 'BENE_REGALO_Tiramisu_in_bag.avif',
        disabled: false,
        limit: 2,
      },
      uid3: {
        name: 'Tiramisu Financier ×2',
        description: 'Financier ×2',
        price: 500,
        count: 0,
        fileName: 'BENE_REGALO_Tiramisu_Financier_2.webp',
        fileName2: 'BENE_REGALO_Tiramisu_Financier_2.avif',
        disabled: false,
        limit: null, //無制限
      },
      uid4: {
        name: 'Tiramisu Financier ×10',
        description: 'Financier ×10',
        price: 2500,
        count: 0,
        fileName: 'BENE_REGALO_Tiramisu_Financier_10.webp',
        fileName2: 'BENE_REGALO_Tiramisu_Financier_10.avif',
        disabled: false,
        limit: null,
      },
      uid5: {
        name: 'Chocolat Feuillantine',
        description: 'Chocolat Feuillantine',
        price: 500,
        count: 0,
        fileName: 'BENE_REGALO_Chocolat_Feuillantine.webp',
        fileName2: 'BENE_REGALO_Chocolat_Feuillantine.avif',
        disabled: false,
        limit: null,
      },
      uid6: {
        name: 'Espresso Sauce',
        description: 'Espresso Sauce',
        price: 500,
        count: 0,
        fileName: 'BENE_REGALO_Espresso_Sauce.webp',
        fileName2: 'BENE_REGALO_Espresso_Sauce.avif',
        disabled: false,
        limit: null,
      },
      uid8: {
        name: 'Tiramisu Macaron 4個Set',
        description: 'Tiramisu Macaron',
        price: 2000,
        count: 0,
        fileName: 'macaronInBox.webp',
        fileName1: 'macaronInBox.webp',
        fileName2: 'macaronInBox.webp',
        fileName3: 'macaron1.webp',
        fileName4: 'macaron1.webp',
        fileName5: 'macaron2.webp',
        fileName6: 'macaron2.webp',
        disabled: false,
        limit: null,
        slide: 2,
      },
      uid9: {
        name: 'Tiramisu Lovers Set',
        description: 'Lovers Set',
        price: 9000,
        count: 0,
        fileName: 'loversSet.webp',
        fileName2: 'loversSet.webp',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid10: {
        name: 'Tiramisu Super Lovers Set',
        description: 'Super Lovers Set',
        price: 11500,
        count: 0,
        fileName: 'tiramisu_super_lovers_set.webp',
        fileName2: 'tiramisu_super_lovers_set.webp',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid11: {
        name: 'Tiramisu Gelato 2×3 Set',
        description: 'Tiramisu Gelato ×6',
        price: 3000,
        count: 0,
        fileName: 'tiramisu_gelato_6.webp',
        fileName2: 'tiramisu_gelato_6.webp',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid12: {
        name: 'Tiramisu Gelato 2×6 Set',
        description: 'Tiramisu Gelato ×12',
        price: 5500,
        count: 0,
        fileName: 'tiramisu_gelato_12.webp',
        fileName2: 'tiramisu_gelato_12.webp',
        disabled: false,
        limit: null,
        slide: 1,
      },
      // uid13: {
      //   name: 'Campaign Set 1',
      //   description: 'Campaign Set 1',
      //   price: 8000,
      //   count: 0,
      //   fileName: 'tiramisu_gelato_set1.webp',
      //   fileName2: 'tiramisu_gelato_set1.webp',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid14: {
      //   name: 'Campaign Set 2',
      //   description: 'Campaign Set 2',
      //   price: 5500,
      //   count: 0,
      //   fileName: 'tiramisu_gelato_set2.webp',
      //   fileName2: 'tiramisu_gelato_set2.webp',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid15: {
      //   name: 'Campaign Set 3',
      //   description: 'Campaign Set 3',
      //   price: 5000,
      //   count: 0,
      //   fileName: 'tiramisu_gelato_set3.webp',
      //   fileName2: 'tiramisu_gelato_set3.webp',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // MEMO: 白黒復活のため残す
      uid16: {
        name: 'Tiramisu Bianco Set in Box',
        description: 'Tiramisu Bianco Set',
        price: 6500,
        count: 0,
        fileName: 'tiramisu_bianco_set_in_box.webp',
        fileName2: 'tiramisu_bianco_set_in_box.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      // uid17: {
      //   name: 'Tiramisu Nero Set in Box',
      //   description: 'Tiramisu Nero Set',
      //   price: 6500,
      //   count: 0,
      //   fileName: 'tiramisu_nero_set_in_box.webp',
      //   fileName2: 'tiramisu_nero_set_in_box.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Nero',
      // },
      // uid18: {
      //   name: '抹茶てぃらみすぷりん4個セット',
      //   description: 'Matcha Tiramisu ×4',
      //   price: 3000,
      //   count: 0,
      //   fileName: 'matcha_tiramisu_pudding_4.webp',
      //   fileName2: 'matcha_tiramisu_pudding_4.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // uid19: {
      //   name: '抹茶てぃらぷりセット①',
      //   description: 'Matcha Set 1',
      //   price: 8200,
      //   count: 0,
      //   fileName: 'matcha_tiramisu_set1.webp',
      //   fileName2: 'matcha_tiramisu_set1.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // uid20: {
      //   name: '抹茶てぃらぷりセット②',
      //   description: 'Matcha Set 2',
      //   price: 5700,
      //   count: 0,
      //   fileName: 'matcha_tiramisu_set2.webp',
      //   fileName2: 'matcha_tiramisu_set2.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // uid21: {
      //   name: '抹茶てぃらぷりセット③',
      //   description: 'Matcha Set 3',
      //   price: 5200,
      //   count: 0,
      //   fileName: 'matcha_tiramisu_set3.webp',
      //   fileName2: 'matcha_tiramisu_set3.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // uid22: {
      //   name: 'ティラミス尽くしセット',
      //   description: 'All Tiramisu Set',
      //   price: 12200,
      //   count: 0,
      //   fileName: 'all_tiramisu_set.webp',
      //   fileName2: 'all_tiramisu_set.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // uid23: {
      //   name: 'ティラミス尽くしフルセット',
      //   description: 'Full Tiramisu Set',
      //   price: 15000,
      //   count: 0,
      //   fileName: 'all_tiramisu_full_set.webp',
      //   fileName2: 'all_tiramisu_full_set.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Matcha',
      // },
      // MEMO: 白黒復活のため残す
      // uid24: {
      //   name: 'Bianco/Nero Tiramisu Set',
      //   description: 'Bianco/Nero Set',
      //   price: 12000,
      //   count: 0,
      //   fileName: 'tiramisu_bianco_nero_set.webp',
      //   fileName2: 'tiramisu_bianco_nero_set.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      //   theme: 'Split',
      // },
      // uid25: {
      //   name: 'ティラミスまん5個 Bag入り',
      //   description: 'Tiramisu Bun x5',
      //   price: 2500,
      //   count: 0,
      //   fileName: 'bun_002.webp',
      //   fileName2: 'bun_002.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid26: {
      //   name: 'ティラミス尽くしまんセット',
      //   description: 'All Tiramisu Bun Set',
      //   price: 12000,
      //   count: 0,
      //   fileName: 'all_tiramisu_bun_set.webp',
      //   fileName2: 'all_tiramisu_bun_set.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid27: {
      //   name: 'ティラミス尽くしフルまんセット',
      //   description: 'Full Tiramisu Bun Set',
      //   price: 15000,
      //   count: 0,
      //   fileName: 'all_tiramisu_bun_full_set.webp',
      //   fileName2: 'all_tiramisu_bun_full_set.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid28: {
      //   name: 'ティラミスまんセット①',
      //   description: 'Tiramisu Bun Set 1',
      //   price: 8000,
      //   count: 0,
      //   fileName: 'tiramisu_bun_set1.webp',
      //   fileName2: 'tiramisu_bun_set1.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid29: {
      //   name: 'ティラミスまんセット②',
      //   description: 'Tiramisu Bun Set 2',
      //   price: 5000,
      //   count: 0,
      //   fileName: 'tiramisu_bun_set2.webp',
      //   fileName2: 'tiramisu_bun_set2.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      // uid30: {
      //   name: 'ティラミスまんセット③',
      //   description: 'Tiramisu Bun Set 3',
      //   price: 4500,
      //   count: 0,
      //   fileName: 'tiramisu_bun_set3.webp',
      //   fileName2: 'tiramisu_bun_set3.avif',
      //   disabled: false,
      //   limit: null,
      //   slide: 1,
      // },
      uid31: {
        name: '生チョコティラミス16個',
        description: 'Namachoco Tiramisu x16',
        price: 2000,
        count: 0,
        fileName: 'namachoco_002.webp',
        fileName2: 'namachoco_002.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid32: {
        name: 'キャンペーンセット①',
        description: 'Campaign Set 1',
        price: 7000,
        count: 0,
        fileName: 'white_day_set1.webp',
        fileName2: 'white_day_set1.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid33: {
        name: 'キャンペーンセット②',
        description: 'Campaign Set 2',
        price: 4500,
        count: 0,
        fileName: 'white_day_set2.webp',
        fileName2: 'white_day_set2.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid34: {
        name: 'キャンペーンセット③',
        description: 'Campaign Set 3',
        price: 4000,
        count: 0,
        fileName: 'white_day_set3.webp',
        fileName2: 'white_day_set3.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid35: {
        name: 'キャンペーンフルセット',
        description: 'Campaign Full Set',
        price: 11500,
        count: 0,
        fileName: 'white_day_full_set.webp',
        fileName2: 'white_day_full_set.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid36: {
        name: 'ネギトロティラミス5貫',
        description: 'Negitoro Tiramisu ×5',
        price: 2000,
        count: 0,
        fileName: 'negitoro.webp',
        fileName2: 'negitoro.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid37: {
        name: '【追加のみ】限定コースター3種',
        description: 'coaster ×3 set',
        price: 600,
        count: 0,
        fileName: 'coaster.webp',
        fileName2: 'coaster.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid38: {
        name: '割引クーポン',
        description: 'coupon discount',
        price: 0,
        count: 0,
        fileName: '',
        fileName2: '',
        disabled: false,
        limit: 1,
        slide: 1,
      },
      uid39: {
        name: 'いぬティラ約8食分（400g）',
        description: 'Dog Tiramisu',
        price: 3300,
        count: 0,
        fileName: 'dog_tiramisu.webp',
        fileName2: 'dog_tiramisu.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid40: {
        name: '家族でティラミスセット',
        description: 'Primo Tiramisu in Box',
        price: 8000,
        count: 0,
        fileName: 'dog_tiramisu_family_set.webp',
        fileName2: 'dog_tiramisu_family_set.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid41: {
        name: 'ねぎとろ軍艦風ティラミス5貫',
        description: 'Negitoro Tiramisu',
        price: 2000,
        count: 0,
        fileName: 'negitoro_002.webp',
        fileName2: 'negitoro_002.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
      uid42: {
        name: '松島聡さんがお店で食べたよセット',
        description: '松島聡さんがお店で食べたよセット',
        price: 6500,
        count: 0,
        fileName: 'kamiwaza-set.webp',
        fileName2: 'kamiwaza-set.avif',
        disabled: false,
        limit: null,
        slide: 1,
      },
    },
  },
});
