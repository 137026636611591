import { Helmet } from 'react-helmet-async';

export const HeadBlock = (props) => {
  const {
    canonical,
    description,
    ogUrl,
    ogType,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    twitterSite,
    twitterCard,
    twitterTitle,
    twitterDescription,
    twitterImage,
    msvalidate01,
    title,
  } = props.head;
  return (
    <Helmet>
      {/* MEMO: 各ページの個別設定 */}
      <link rel="canonical" href={canonical ? canonical : 'https://bene-regalo.com'} />
      <meta
        name="description"
        content={
          description
            ? description
            : '700種類以上（現在も更新中）のティラミスを実際に食べて、日々日本、東京で最高のティラミスを開発し続けています。どこにいても最高のティラミスを食べられるように、全国への配送・お取り寄せも承っております。現在は東京2店舗と各地でイベント出店を行なっております。'
        }
      />
      <meta property="og:url" content={ogUrl ? ogUrl : 'https://bene-regalo.com'} />
      <meta property="og:type" content={ogType ? ogType : 'website'} />
      <meta
        property="og:title"
        content={
          ogTitle ? ogTitle : '通販・お取り寄せもできるこだわりのティラミス専門店 BENE REGALO（ベーネ レガーロ）'
        }
      />
      <meta
        property="og:description"
        content={
          ogDescription
            ? ogDescription
            : '700種類以上のティラミスを試食して開発した最高のティラミスを全国へ提供できるよう、配送も可能。東京2店舗（あきる野、代々木）と期間限定で各地イベント出店も。'
        }
      />
      <meta property="og:site_name" content={ogSiteName ? ogSiteName : 'BENE REGALO（ベーネ レガーロ）'} />
      <meta property="og:image" content={ogImage ? ogImage : 'https://bene-regalo.com/ogp_top.png'} />
      <meta property="og:image:width" content={ogImageWidth ? ogImageWidth : '1200'} />
      <meta property="og:image:height" content={ogImageHeight ? ogImageHeight : '630'} />
      <meta name="twitter:site" content={twitterSite ? twitterSite : '@bene-regalo'} />
      <meta name="twitter:card" content={twitterCard ? twitterCard : 'summary_large_image'} />
      <meta name="twitter:title" content={twitterTitle ? twitterTitle : 'BENE REGALO（ベーネ レガーロ）'} />
      <meta
        name="twitter:description"
        content={
          twitterDescription
            ? twitterDescription
            : 'BENE REGALO（ベーネ レガーロ）- 通販・お取り寄せもできるティラミス専門店'
        }
      />
      <meta name="twitter:image" content={twitterImage ? twitterImage : 'https://bene-regalo.com/ogp_top.png'} />
      <meta name="msvalidate.01" content={msvalidate01 ? msvalidate01 : 'F36799709E10A64A3A37A82369A43C2D'} />
      <title>
        {title ? title : '通販・お取り寄せもできるおすすめティラミス専門店 - BENE REGALO（ベーネ レガーロ）'}
      </title>
    </Helmet>
  );
};
