import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    font-family: 'KiwiMaru', 'NotoSanJP';
    @media (max-width: 320px) {
      /* display: none !important; */
    }
  }
  &.body {
    text-align: center;
    display: block;
    margin: 0 auto;
    color: black;
    font-weight: 500;
    margin-top: 2vh;
    ${(props) => {
      return responsiveStyle({
        width: props.bodyWidth,
      });
    }}
  }
  .backGround {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    .line1 {
      height: 10vh;
      width: 100vw;
      background-color: #5f2f17;
    }
    .line2 {
      height: 30vh;
      width: 100vw;
      background-color: #f0dcaa;
    }
    .line3 {
      height: 15vh;
      width: 100vw;
      background-color: #8f624d;
    }
    .line4 {
      height: 30vh;
      width: 100vw;
      background-color: #f0dcaa;
    }
    .line5 {
      height: 15vh;
      width: 100vw;
      background-color: #8f624d;
    }
  }
`;
