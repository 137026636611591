import styled from 'styled-components';
import { MdArrowBackIosNew } from 'react-icons/md';
import css from '../../../index.module.scss';
import classNames from 'classnames';

const BoxButtonCon = styled.div`
  height: 48px;
  border-radius: 4px;
  position: relative;
  > button {
    background-color: transparent;

    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: #ffffff;
    font-family: 'KiwiMaru';

    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export const BoxButton = (props) => {
  const disabledStyle = props.disabled ? css.bcC4C4C4 : css.bc161c1c;
  return (
    <BoxButtonCon className={classNames(props.className, disabledStyle)}>
      <button onClick={props.onClick} disabled={props.disabled} type="button">
        <div className={classNames(css.flexCenter)}>
          {props.text || '---'}
          <div className={props.isLoading ? css.loaderWhite : css.displayNone}>Loading...</div>
        </div>
      </button>
    </BoxButtonCon>
  );
};

const MiniBoxButtonCon = styled.div`
  margin-top: 30px;
  width: 25.87vw;
  max-height: 40px;
  height: 10.67vw;
  background: #161c1c;
  border-radius: 4px;
  position: relative;
  border: 1px solid black;
  > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    font-family: 'KiwiMaru';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  :hover {
    background-color: white;
    button {
      color: black;
    }
  }
`;

const MiniBoxButtonConWhite = styled.div`
  margin-top: 30px;
  width: 25.87vw;
  max-height: 40px;
  height: 10.67vw;
  background: white;
  border-radius: 4px;
  position: relative;
  border: 1px solid black;
  > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    font-family: 'KiwiMaru';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  :hover {
    background-color: black;
    button {
      color: white;
    }
  }
`;

export const MiniBoxButton = (props) => {
  const disabledStyle = props.disabled ? css.disabledTrue : '';
  const buttonColorWhite = props.buttonColorWhite;

  if (buttonColorWhite) {
    return (
      <MiniBoxButtonConWhite className={classNames(disabledStyle)} onClick={props.onClick}>
        <button>{props.text || '---'}</button>
      </MiniBoxButtonConWhite>
    );
  } else {
    return (
      <MiniBoxButtonCon className={classNames(props.className, disabledStyle)} onClick={props.onClick}>
        <button>{props.text || '---'}</button>
      </MiniBoxButtonCon>
    );
  }
};

const TextButtonCon = styled.div`
  text-align: center;
  padding: 12px;
`;

const TextButtonInner = styled.span``;

export const TextButton = (props) => {
  return (
    <TextButtonCon onClick={props.onClick}>
      <MdArrowBackIosNew className={css.mb2_5_m} />
      <TextButtonInner className={css.VertAlC}>{props.text || '---'}</TextButtonInner>
    </TextButtonCon>
  );
};
