import styled from 'styled-components';

export const ItemPanelCon = styled.div``;

export const TotalPrice = styled.div`
  padding: 12px;
  display: flex;
  height: clamp(0px, calc(17.06vw-24px), 76px);
  line-height: 100%;
  letter-spacing: 0;
  background-color: #161c1c;
  font-family: 'KiwiMaru';
  position: fixed;
  bottom: clamp(64px, 17.06vw, 80px);
  right: 0px;
  z-index: 9;
  border-radius: 4px;
`;

export const PriceCon = styled.div`
  display: flex;
  background-color: white;
  border-radius: 4px;
  color: black;
`;

export const PriceFonts = styled.div`
  margin-top: auto;
  margin-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
`;

export const CartIconCon = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-right: 16px;
  margin-left: 4px;
`;

export const CountIcon = styled.div`
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  margin-bottom: -20px;
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 18px;
    padding-left: 3px;
    margin-bottom: -12px;
  }
`;
