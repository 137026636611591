import styled from 'styled-components';

export const TotalPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 42px;
  margin-top: 12px;
  margin-right: 22px;
`;

export const CartIconCon = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
`;

export const CountIcon = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  margin-bottom: -0.7em;
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 18px;
    padding-left: 3px;
    margin-bottom: -0.8em;
  }
`;

export const ItemCon = styled.div`
  width: 60%;
  position: relative;
  @media (min-width: 900px) {
    width: 50%;
  }
`;

export const ItemCountCon = styled.div`
  @media (min-width: 900px) {
    width: 50%;
  }
`;
