import { Loading } from '../loading';
import { Wrapper } from './scsss';

export const QuizWrapper = ({ children, screenWidth, screenHeight, screenOrientation, bodyWidth, pageIsLoading }) => {
  return (
    <Wrapper
      className="body"
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
    >
      {pageIsLoading ? <Loading /> : children}
      <div className="backGround">
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
        <div className="line4"></div>
        <div className="line5"></div>
        <div className="line6"></div>
      </div>
    </Wrapper>
  );
};
