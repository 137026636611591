import { useEffect, useState } from 'react';
import { QuizWrapper } from '../components/quizWrapper';
import { Wrapper } from './scss';
import { QuizHeader } from '../components/quizHeader';
import { changePage } from '../utils/navigate';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { QuizUserState } from '../store/atom';
import { consoleError, consoleLog } from '../../zz_general/utils/snippets/console';
import { updateUser } from '../utils/update';
import { fetchCode, useCode } from '../utils/code';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { isProd, publicUrl } from '../../zz_general/utils/snippets/env';

const imgPath = (fileName) => publicUrl + '/quiz/failPrize/images/' + fileName;

const text = (num) => `10問連続クリアで豪華賞品をゲット出来る、ティラミスにまつわる超難問クイズイベント開催中！

ティラミスを愛し、ティラミスに愛されるものは誰か

現在の10問連続正解者は${num - 5}人

挑戦求む！`;

export const QuizCodeInput = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [user, setUser] = useRecoilState(QuizUserState);
  const [message, setMessage] = useState('');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [achievedUser, setAchievedUser] = useState(0);

  const displayMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const validateCode = async (code) => {
    if (!code) {
      displayMessage('コードを入力してください。');
      return;
    }
    if (code.length !== 10) {
      consoleLog('invalid code');
      displayMessage('コードが誤っています。');
      return;
    }
    const result = await fetchCode(code);
    if (result.error) {
      displayMessage(result.error);
      return;
    }
    const usageCount = user.codeUsageCount?.[result.inflow] ?? 0;
    if (usageCount >= 3) {
      displayMessage('コードの使用回数が上限に達しました。');
      return;
    }
    setIsPageLoading(true);
    consoleLog(usageCount + result.num);
    await updateUser(user, setUser, {
      challengeCount: user.challengeCount + result.num,
      codeUsageCount: {
        ...user.codeUsageCount,
        [result.inflow]: usageCount + result.num,
      },
    });
    await useCode(code);
    changePage(navigate, 'start', 'code-input');
    setIsPageLoading(false);
  };

  const handleTwitterShare = () => {
    const url = '\n\nhttps://bene-regalo.com/quiz\n\n'; // Replace with your actual URL
    const hashtags = 'ティラミスクイズ';

    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text(achievedUser)
    )}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(hashtags)}`;

    window.open(twitterIntentUrl, '_blank');
    changePage(navigate, 'rechallenge', 'code-input');
  };

  const fetchAchievedUser = async () => {
    try {
      const collectionRef = collection(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'users');
      const snapshot = await getDocs(collectionRef);

      let count = 0;

      snapshot.forEach((doc) => {
        if (doc.data().achieved === true) {
          count++;
        }
      });

      setAchievedUser(count);
      setIsPageLoading(false);
    } catch (e) {
      consoleError('Error Fetching achieved users: ', e);
      setIsPageLoading(false);
      return;
    }
  };

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };
  useEffect(() => {
    !user.loggedIn && changePage(navigate, '', 'code-input');
    setIsPageLoading(true);
    fetchAchievedUser();

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={isPageLoading}
    >
      <Wrapper
        className="codeInput"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <QuizHeader
          onClick={() => {
            changePage(navigate, 'start', 'code-input');
          }}
          title="コード入力"
        />
        <div className="challengeCountTitle stroke">チャレンジ可能回数</div>
        <div className="challengeCount stroke">{user.challengeCount}</div>
        <p className="subTitle stroke">
          <span>商品購入でGETできる</span>
          <span>コードを入力</span>
        </p>
        <p className="errorMessage">{message}</p>
        <input
          onChange={(e) => {
            const value = e.target.value;
            setInput(value);
          }}
          className={`inputBox ${message ? 'error' : ''}`}
        />
        <p className="inputBoxDescription stroke">10桁</p>
        <button
          onClick={() => {
            validateCode(input);
          }}
          className="confirmButton stroke"
        >
          確定
        </button>
        <p className="xPostDescription">
          <span>X(旧Twitter)への投稿で</span>
          <span>もう一回チャレンジする</span>
        </p>
        <img className="xIcon" src={imgPath('xIcon.webp')} alt="xIcon" onClick={handleTwitterShare} />
      </Wrapper>
    </QuizWrapper>
  );
};
