import { StyledInput, useStyledDiv, useStyledImg } from '../../../utils/scss-modules/styled';
import { importAll } from '../../../utils/snippets/image';
import { useState } from 'react';

const [Wrapper, Header, Title, Required, Help, Description, ErrorMessage] = useStyledDiv(7);
const [CheckImg, ErrorImg, HelpImg] = useStyledImg(3);

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

export const InputForm = ({
  className,
  width,
  height,
  required,
  title,
  placeholder,
  description,
  help,
  id,
  type,
  value,
  onChange,
  onKeyUp,
  onKeyDown,
  onBlur,
  check,
  error,
  errorMessage,
}) => {
  const image = importAll(require.context('./image', false, /\.(svg)$/));
  const [showHelp, setShowHelp] = useState(false);
  const showHeader = title || required || help;

  const getTextWidth = (text, font) => {
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  };
  return (
    <Wrapper
      className={className}
      css={`
        * {
          box-sizing: border-box;
          border: 0;
          margin: 0;
          padding: 0;
          font-family: 'KiwiMaru', 'NotoSanJP';
        }
        input {
          display: block;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      `}
      responsive={{
        width: width,
      }}
    >
      {showHeader && (
        <Header
          responsive={{
            marginBottom: multiplyList(height, 0.25),
          }}
          style={{
            display: 'flex',
          }}
        >
          <Title
            responsive={{
              height: multiplyList(height, 0.5),
              fontSize: multiplyList(height, 0.5),
              lineHeight: multiplyList(height, 0.5),
            }}
          >
            {title}
          </Title>
          {required && (
            <Required
              responsive={{
                height: multiplyList(height, 0.5),
                fontSize: multiplyList(height, 0.375),
                lineHeight: multiplyList(height, 0.5),
                borderRadius: multiplyList(height, 0.125),
                marginLeft: multiplyList(height, 0.25),
                paddingLeft: multiplyList(height, 0.125),
                paddingRight: multiplyList(height, 0.125),
              }}
              style={{
                backgroundColor: '#FF5656',
                color: '#FFFFFF',
              }}
            >
              必須
            </Required>
          )}
          <div
            style={{
              position: 'relative',
            }}
          >
            <Help
              responsive={{
                height: multiplyList(height, 0.5),
                width: multiplyList(height, (0.5 / 12) * getTextWidth(help, 'KiwiMaru')),
                fontSize: multiplyList(height, 0.375),
                lineHeight: multiplyList(height, 0.5),
                borderRadius: multiplyList(height, 0.25),
                marginLeft: multiplyList(height, 0.25),
                paddingLeft: multiplyList(height, 0.125),
                paddingRight: multiplyList(height, 0.125),
              }}
              style={{
                textAlign: 'center',
                position: 'absolute',
                backgroundColor: '#C4C4C4',
                color: '#FFFFFF',
                cursor: 'pointer',
                display: help && showHelp ? 'block' : 'none',
              }}
              onClick={() => setShowHelp(false)}
            >
              {help}
            </Help>
          </div>
          <HelpImg
            src={image['help.svg']}
            responsive={{
              width: multiplyList(height, 0.5),
              height: multiplyList(height, 0.5),
              marginLeft: multiplyList(height, 0.25),
            }}
            style={{
              cursor: 'pointer',
              display: help && !showHelp ? 'block' : 'none',
            }}
            onClick={() => setShowHelp(true)}
          />
        </Header>
      )}
      <div
        style={{
          position: 'relative',
        }}
      >
        <StyledInput
          responsive={{
            width: width,
            height: height,
            padding: multiplyList(height, 0.25),
            fontSize: multiplyList(height, 0.5),
            lineHeight: multiplyList(height, 0.5),
            borderRadius: multiplyList(height, 0.125),
          }}
          style={{
            backgroundColor: '#F3F4F5',
            outline: error && '2px solid #FF5656',
          }}
          placeholder={placeholder}
          id={id}
          type={type || 'text'}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        <CheckImg
          src={image['check.svg']}
          responsive={{
            width: multiplyList(height, 0.5),
            height: multiplyList(height, 0.5),
            top: multiplyList(height, 0.25),
            right: multiplyList(height, 0.25),
          }}
          style={{
            position: 'absolute',
            display: check ? 'block' : 'none',
          }}
        />
        <ErrorImg
          src={image['error.svg']}
          responsive={{
            width: multiplyList(height, 0.5),
            height: multiplyList(height, 0.5),
            top: multiplyList(height, 0.25),
            right: multiplyList(height, 0.25),
          }}
          style={{
            position: 'absolute',
            display: error ? 'block' : 'none',
          }}
        />
      </div>
      {description && (
        <Description
          responsive={{
            height: multiplyList(height, 0.375),
            lineHeight: multiplyList(height, 0.375),
            fontSize: multiplyList(height, 0.375),
            marginTop: multiplyList(height, 0.25),
            marginBottom: multiplyList(height, 0.125),
          }}
          style={{
            color: '#6B757E',
          }}
        >
          {description}
        </Description>
      )}
      {error && (
        <ErrorMessage
          responsive={{
            lineHeight: multiplyList(height, 0.375),
            fontSize: multiplyList(height, 0.375),
            marginTop: multiplyList(height, 0.25),
            marginBottom: multiplyList(height, 0.125),
          }}
          style={{
            color: '#FF5656',
          }}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </Wrapper>
  );
};
