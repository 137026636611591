import { useEffect, useState } from 'react';
import { Wrapper } from './intro.scss';

import { publicUrl } from '../../zz_general/utils/snippets/env';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { QuizUserState } from '../store/atom';
import { QuizWrapper } from '../components/quizWrapper';
import { changePage } from '../utils/navigate';

import { HeadBlock } from '../../zz_general/components/head/index';
import { quizHeadData } from '../../zz_general/components/head/data';

const imgPath = (fileName) => publicUrl + '/quiz/intro/images/' + fileName;

export const QuizIntro = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(QuizUserState);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HeadBlock head={quizHeadData} />
      <QuizWrapper
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
        pageIsLoading={false}
      >
        <Wrapper
          className="intro"
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          screenOrientation={screenOrientation}
          bodyWidth={bodyWidth}
        >
          <div>
            <div className="super_difficult">
              超難問 <img src={imgPath('title.svg')} />
            </div>
          </div>
          <div className="Tiramisu_eng"> Tiramisù Quiz </div>
          <div className="Tiramisu_jap"> ティラミスクイズ </div>
          <div className="bene_reg">
            <span className="present"> presented by </span> <span className="BG"> BENE REGALO </span>
          </div>
          <div>
            <img src={imgPath('tiramisuCake.webp')} alt="Image" className="tiramisu_cake" />
          </div>
          <div className="tap_details_wrap">
            <div className="tap_details"> ＼詳細はタップ↓／ </div>
          </div>
          <div className="container">
            <div
              className="luxury_prize_wrap"
              onClick={() => {
                changePage(navigate, 'prize-detail', 'intro');
              }}
            >
              <button className="luxury_prize">
                <span className="line"> 全問正解者先着5名 </span>
                <span className="line"> 豪華賞品プレゼント </span>
              </button>
            </div>
            <div className="btn_box">
              <div
                onClick={() => {
                  if (user.loggedIn) {
                    changePage(navigate, 'start', 'intro');
                  } else {
                    changePage(navigate, 'explanation', 'intro');
                  }
                }}
                className="btn"
              >
                クイズにチャレンジする
              </div>
            </div>
          </div>
        </Wrapper>
      </QuizWrapper>
    </>
  );
};
