import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.quizHeader {
    color: white;
    -webkit-text-stroke: 1px #5f2f17;
    font-size: 2.5rem;
    position: relative;
    text-align: center;
    ${(props) => {
      return responsiveStyle({
        marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
        paddingLeft: multiplyArrayByScalar(props.bodyWidth, 0.05),
        paddingRight: multiplyArrayByScalar(props.bodyWidth, 0.05),
        height: multiplyArrays(props.bodyWidth, [0.14, 0.13, 0.12]),
      });
    }}
    .back_button {
      position: absolute;
      left: 0;
      cursor: pointer;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.14, 0.13, 0.12]),
          height: multiplyArrays(props.bodyWidth, [0.14, 0.13, 0.12]),
        });
      }}
      img {
        width: 100%;
      }
    }
    .title {
      -webkit-text-stroke: calc(0.1vw + 1px) #5f2f17;
      font-family: 'AvenirLTStd-Black';
      font-weight: bolder;
      ${(props) => {
        return responsiveStyle({
          lineHeight: multiplyArrays(props.bodyWidth, [0.17, 0.15, 0.15]),
          fontSize: multiplyArrays(props.bodyWidth, [0.08, 0.07, 0.06]),
        });
      }}
    }
  }
`;
