export const homeHeadData = {
  canonical: 'https://bene-regalo.com',
  description:
    '700種類以上（現在も更新中）のティラミスを実際に食べて、日々日本、東京で最高のティラミスを開発し続けています。どこにいても最高のティラミスを食べられるように、全国への配送・お取り寄せも承っております。現在は東京2店舗と各地でイベント出店を行なっております。',
  ogUrl: 'https://bene-regalo.com',
  ogType: 'website',
  ogTitle: '通販・お取り寄せもできるこだわりのティラミス専門店 BENE REGALO（ベーネ レガーロ）',
  ogDescription:
    '700種類以上のティラミスを試食して開発した最高のティラミスを全国へ提供できるよう、配送も可能。東京2店舗（あきる野、代々木）と期間限定で各地イベント出店も。',
  ogSiteName: 'BENE REGALO（ベーネ レガーロ）',
  ogImage: 'https://bene-regalo.com/ogp_top.png',
  ogImageWidth: '1200',
  ogImageHeight: '630',
  twitterSite: '@bene-regalo',
  twitterCard: 'summary_large_image',
  twitterTitle: 'BENE REGALO（ベーネ レガーロ）',
  twitterDescription: 'BENE REGALO（ベーネ レガーロ）- 通販・お取り寄せもできるティラミス専門店',
  twitterImage: 'https://bene-regalo.com/ogp_top.png',
  msvalidate01: 'F36799709E10A64A3A37A82369A43C2D',
  title: '通販・お取り寄せもできるおすすめティラミス専門店 - BENE REGALO（ベーネ レガーロ)',
};

export const akigawaHeadData = {
  canonical: 'https://bene-regalo.com',
  description:
    '700種類以上（現在も更新中）のティラミスを実際に食べて、日々日本、東京で最高のティラミスを開発し続けています。どこにいても最高のティラミスを食べられるように、全国への配送・お取り寄せも承っております。現在は東京2店舗と各地でイベント出店を行なっております。',
  ogUrl: 'https://bene-regalo.com',
  ogType: 'website',
  ogTitle: 'あきる野市秋川本店_ティラミス専門店 BENE REGALO（ベーネ レガーロ）',
  ogDescription:
    '700種類以上のティラミスを試食して開発した最高のティラミスを全国へ提供できるよう、配送も可能。あきる野市秋川に構える本店。',
  ogSiteName: 'BENE REGALO（ベーネ レガーロ）秋川店',
  ogImage: 'https://bene-regalo.com/ogp_top.png',
  ogImageWidth: '1200',
  ogImageHeight: '630',
  twitterSite: '@bene-regalo',
  twitterCard: 'summary_large_image',
  twitterTitle: 'BENE REGALO（ベーネ レガーロ）秋川本店',
  twitterDescription: 'ティラミス専門店"BENE REGALO（ベーネ レガーロ）- 秋川本店',
  twitterImage: 'https://bene-regalo.com/ogp_top.png',
  msvalidate01: 'F36799709E10A64A3A37A82369A43C2D',
  title: '東京おすすめこだわりのティラミス専門店 - BENE REGALO（ベーネ レガーロ）秋川本店',
};

export const yoyogiHeadData = {
  canonical: 'https://bene-regalo.com',
  description:
    '700種類以上（現在も更新中）のティラミスを実際に食べて、日々日本、東京で最高のティラミスを開発し続けています。どこにいても最高のティラミスを食べられるように、全国への配送・お取り寄せも承っております。現在は東京2店舗と各地でイベント出店を行なっております。',
  ogUrl: 'https://bene-regalo.com',
  ogType: 'website',
  ogTitle: 'あきる野市秋川本店_ティラミス専門店 BENE REGALO（ベーネ レガーロ）',
  ogDescription:
    '700種類以上のティラミスを試食して開発した最高のティラミスを全国へ提供できるよう、配送も可能。渋谷区代々木に構える代々木店',
  ogSiteName: 'BENE REGALO（ベーネ レガーロ）代々木店',
  ogImage: 'https://bene-regalo.com/ogp_top.png',
  ogImageWidth: '1200',
  ogImageHeight: '630',
  twitterSite: '@bene-regalo',
  twitterCard: 'summary_large_image',
  twitterTitle: 'BENE REGALO（ベーネ レガーロ）代々木店',
  twitterDescription: 'ティラミス専門店"BENE REGALO（ベーネ レガーロ）- 代々木店',
  twitterImage: 'https://bene-regalo.com/ogp_top.png',
  msvalidate01: 'F36799709E10A64A3A37A82369A43C2D',
  title: '東京おすすめこだわりのティラミス専門店 - BENE REGALO（ベーネ レガーロ）代々木店',
};

export const quizHeadData = {
  canonical: 'https://bene-regalo.com',
  description: '10月ティラミス月間記念企画 超難問ティラミスクイズ 全問正解で豪華商品プレゼント！',
  ogUrl: 'https://bene-regalo.com',
  ogType: 'website',
  ogTitle: '超難問ティラミスクイズ 全問正解で豪華商品プレゼント"',
  ogDescription: '10月ティラミス月間記念企画 誰にも解けない！？超難問ティラミスクイズ 全問正解で豪華商品プレゼント！',
  ogSiteName: '超難問ティラミスクイズ 全問正解で豪華商品プレゼント',
  // ogImage: 'https://bene-regalo.com/ogp_top.png',
  ogImage: 'https://bene-regalo.com/quiz/tiramisu-quiz_ogp.png',
  ogImageWidth: '1200',
  ogImageHeight: '630',
  twitterSite: '@bene-regalo',
  twitterCard: 'summary_large_image',
  twitterTitle: '超難問ティラミスクイズ 全問正解で豪華商品プレゼント',
  twitterDescription:
    '10月ティラミス月間記念企画 誰にも解けない！？超難問ティラミスクイズ 全問正解で豪華商品プレゼント！',
  // twitterImage: 'https://bene-regalo.com/ogp_top.png',
  twitterImage: 'https://bene-regalo.com/quiz/tiramisu-quiz_ogp.png',
  msvalidate01: 'F36799709E10A64A3A37A82369A43C2D',
  title: '超難問ティラミスクイズ 全問正解で豪華商品プレゼント',
};
