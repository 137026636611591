import { getAuth, signOut } from 'firebase/auth';
import { Wrapper } from './scss';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { adminState } from '../store/atom';
import { useEffect } from 'react';

export const AdminItem = () => {
  // #region Variables
  const auth = getAuth();
  const navigate = useNavigate();
  const [admin, setAdmin] = useRecoilState(adminState);

  // #endregion
  // #region Functions
  const setIsLogin = (value) => {
    setAdmin((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['isLogin'] = value;
      return future;
    });
  };
  const logOut = () => {
    signOut(auth);
    setIsLogin(false);
    window.location.reload();
  };
  // #endregion
  // #region UseEffects
  useEffect(() => {
    if (admin.isLogin === false) {
      navigate('/admin/sign-in');
    }
  }, [admin.isLogin]);
  // #endregion
  return (
    <Wrapper>
      <h1>
        item管理{' '}
        <span
          onClick={() => {
            logOut();
          }}
        >
          ログアウト
        </span>
      </h1>
      <div></div>
      <div>商品追加</div>
    </Wrapper>
  );
};
