import styled from 'styled-components';
import { responsiveStyle } from '../../../../zz_general/utils/scss-modules/styled';
import { resetCss } from '../../../../zz_general/utils/scss-modules/reset';

export const Wrapper = styled.div`
  &.quizCoupon {
    ${resetCss()}
    top: 0;
    font-weight: bold;

    .backGround {
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .popUp {
      position: fixed;
      z-index: 11;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      color: #000;
      background-color: #fff;
      ${responsiveStyle({
        width: [300, 400, 500],
        paddingRight: [30, 45, 60],
        paddingLeft: [30, 45, 60],
        paddingTop: [10, 10, 10],
        paddingBottom: [10, 10, 10],
      })}

      .cross {
        position: absolute;
        text-align: center;
        right: 0;
        top: 0;
        font-weight: normal;
        color: rgb(120, 120, 120);
        ${responsiveStyle({
          fontSize: [24, 28, 32],
          lineHeight: [24, 28, 32],
          height: [24, 28, 32],
          width: [24, 28, 32],
        })}
      }

      .title {
        text-align: center;
        ${responsiveStyle({
          fontSize: [16, 18, 20],
        })}
      }

      .countDown {
        text-align: center;
        ${responsiveStyle({
          fontSize: [28, 30, 32],
        })}
        span {
          ${responsiveStyle({
            fontSize: [14, 15, 16],
          })}
        }
      }

      .couponTicket {
        text-align: center;
        outline: 1px solid #000;
        display: grid;
        grid-template-columns: auto 1fr;
        img {
          ${responsiveStyle({
            width: [60, 66, 72],
          })}
        }
        .ticketTitle {
          width: 100%;
          ${responsiveStyle({
            fontSize: [28, 32, 36],
            paddingBottom: [3, 2, 1],
          })}
        }
        .discount {
          ${responsiveStyle({
            fontSize: [20, 24, 28],
          })}
          .price {
            ${responsiveStyle({
              fontSize: [42, 44, 48],
            })}
          }
        }
      }
    }

    .cartIconBanner {
      position: fixed;
      z-index: 8;
      bottom: 0;
      right: 0;
      bottom: clamp(64px, 17.06vw, 80px);
      background-color: rgb(255, 0, 0);
      padding: 5px 0;
      border-radius: 8px;
      padding-bottom: clamp(64px, 17.06vw, 100px);

      ${responsiveStyle({
        fontSize: [12, 13, 13],
        lineHeight: [13, 13, 15],
      })}
      span {
        ${responsiveStyle({
          fontSize: [8, 8, 10],
        })}
      }
    }

    .cartIconBanner.zeroCount {
      /* totalCount が 0 の場合のスタイル */
      width: clamp(0px, 17.06vw, 100px);
      text-align: center;
    }

    .cartIconBanner.positiveCount {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      width: clamp(233px, 17.06vw, 400px);
      @media (min-width: 900px) {
        width: 281px;
      }

      .discountBlock,
      .timerBlock {
        margin: 0 0.5vw; /* 左右に10pxの余白を追加 */
        border-radius: 8px; /* 角を丸くする */
      }

      .discountBlock {
        text-align: left;
      }

      .timerBlock {
        /* display: flex; タイマーとラベルを横並びにする */
        align-items: center; /* 縦方向を中央揃え */
        text-align: right;

        .remainingLabel {
          margin-right: 0.5vw; /* "残り"ラベルとタイマーの間にスペースを追加 */
        }
        .countDownTimer {
          display: inline-block;
        }
      }
    }
  }
`;
