import { useState, useRef } from 'react';
import classNames from 'classnames';
import css from './modal.module.scss';
import { publicUrl } from '../../../zz_general/utils/snippets/env';

const imgPath = (fileName) => publicUrl + '/quiz/components/modal/images/' + fileName;

export const Modal = ({ updateSoundEnabled, sound, playSelectAudio, onClosePopup }) => {
  const [modal, setModal] = useState(true);

  const handleSoundChange = (enabled) => {
    enabled && playSelectAudio();
    updateSoundEnabled(enabled);
  };

  const closePopup = () => {
    setModal(false);
    onClosePopup();
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <>
      {modal && (
        <div className={css.modal}>
          <div onClick={closePopup} className={css.overlay}></div>
          <div className={css.modal_content} onClick={(e) => e.stopPropagation()}>
            <button className={css.close_button} onClick={closePopup}>
              &times;
            </button>
            <p className={css.modal_text}>制限時間が10秒以下の時などに音が鳴ります。</p>
            <img
              src={imgPath(sound.enabled ? 'sound_off.svg' : 'sound_on.svg')}
              alt="Sound"
              className={css.sound_button}
              onClick={() => {
                handleSoundChange(!sound.enabled);
              }}
            />
            <div className={css.button_group}>
              <button
                className={classNames(css.sound_button, { [css.selected]: sound.enabled })}
                onClick={() => handleSoundChange(true)}
              >
                ON
              </button>
              <button
                className={classNames(css.sound_button, { [css.selected]: !sound.enabled })}
                onClick={() => handleSoundChange(false)}
              >
                OFF
              </button>
            </div>
            <div className={css.button_group}>
              <button
                style={{ backgroundColor: '#FFFA83' }}
                className={classNames(css.sound_button, { [css.selected]: true })}
                onClick={() => closePopup()}
              >
                確定
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
