import { useRecoilState } from 'recoil';
import { adminState } from '../store/atom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from './scss';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { isProd } from '../../zz_general/utils/snippets/env';
import { generateUniqueID } from '../../zz_general/utils/snippets/generateId';
import { copyToClipboard } from '../../zz_general/utils/snippets/clipboard';

export const AdminHome = () => {
  // #region Variables
  const auth = getAuth();
  const navigate = useNavigate();
  const [_admin, setAdmin] = useRecoilState(adminState);

  // MEMO: ティラミスクイズ用
  const [code, setCode] = useState({
    txt: '',
    num: 1,
    inflow: 'ec',
    message: '',
  });

  // #endregion
  // #region Functions

  // #endregion
  // #region UseEffects
  useEffect(() => {
    // MEMO : ログイン状態を監視
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid !== process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin/sign-in');
        }
      } else {
        navigate('/admin/sign-in');
      }
    });

    // コンポーネントがアンマウントされる際にリスナーを解除
    return () => unsubscribe();
  }, []);
  // #endregion
  return (
    <Wrapper>
      <h1>Dashboard</h1>
      <p
        onClick={() => {
          navigate('/admin/item');
        }}
      >
        item管理
      </p>
      <p
        onClick={() => {
          setAdmin((current) => {
            const future = JSON.parse(JSON.stringify(current));
            future['testPurchase'] = true;
            return future;
          });
          navigate('/lp-beta');
        }}
      >
        テスト購入
      </p>

      <br />
      <br />
      <label
        style={{
          padding: '10px 20px',
          fontSize: '18px',
          margin: 0,
        }}
      >
        購入経路:
      </label>
      <select
        onChange={(e) => {
          setCode({
            ...code,
            inflow: e.target.value,
          });
        }}
        style={{
          cursor: 'pointer',
          padding: '10px 20px',
          fontSize: '18px',
          borderRadius: '4px',
          margin: 0,
        }}
      >
        <option value="ec">EC購入</option>
        <option value="akigawa">店舗購入（秋川）</option>
        <option value="yoyogi">店舗購入（代々木）</option>
        <option value="other">その他</option>
      </select>
      {/* <br />
      <br />
      <span>付与回数 : </span>
      <input
        style={{
          cursor: 'pointer',
          padding: '10px 20px',
          fontSize: '18px',
          borderRadius: '4px',
          margin: 0,
        }}
        onChange={(e) => {
          setCode({
            ...code,
            num: parseInt(e.target.value),
          });
        }}
        type="number"
        defaultValue={1}
      /> */}
      <br />
      <br />
      <button
        style={{
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          padding: '10px 20px',
          fontSize: '18px',
          borderRadius: '4px',
        }}
        onClick={() => {
          const async = async () => {
            const collectionRef = collection(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'codes');
            const docId = await generateUniqueID(collectionRef, 10);
            await setDoc(doc(collectionRef, docId), {
              used: false,
              num: code.num,
              inflow: code.inflow,
            });
            // eslint-disable-next-line no-console
            console.log('Code created: ', docId);
            setCode({
              ...code,
              txt: docId,
              message: 'コードを生成しました',
            });
            setTimeout(() => {
              setCode({
                ...code,
                txt: docId,
                message: '',
              });
            }, 3000);
          };
          async();
        }}
      >
        ティラミスクイズ 挑戦権取得用 入力コード生成
      </button>
      <h1>{code.txt && 'コード : ' + code.txt} </h1>
      {code.txt && (
        <div
          style={{
            textAlign: 'right',
            maxWidth: '420px',
          }}
        >
          <span
            style={{
              color: 'white',
              backgroundColor: 'black',
              padding: '5px',
              borderRadius: '4px',
              cursor: 'pointer',
              marginLeft: '10px',
              fontSize: '20px',
            }}
            onClick={() => {
              copyToClipboard(code.txt);
              setCode({
                ...code,
                message: 'コードをコピーしました',
              });
              setTimeout(() => {
                setCode({
                  ...code,
                  message: '',
                });
              }, 3000);
            }}
          >
            コピー
          </span>
        </div>
      )}
      <h2 style={{ color: 'red' }}>{code.message && code.message}</h2>
      <br />
      <br />
      <br />
      <p
        onClick={() => {
          signOut(auth);
        }}
      >
        ログアウト
      </p>
    </Wrapper>
  );
};
