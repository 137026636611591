import { useEffect, useState } from 'react';
import { Wrapper } from './scss';

import backIcon from './image/back_button.webp';

export const QuizHeader = ({ onClick, title }) => {
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);

  const handleBackClick = () => {
    onClick();
  };

  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Wrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      className="quizHeader"
    >
      <div className="back_button" onClick={handleBackClick}>
        <img src={backIcon} alt="back" />
      </div>
      <div className="title">{title}</div>
    </Wrapper>
  );
};
