import styled from 'styled-components';
import { responsiveStyle } from '../../zz_general/utils/scss-modules/styled';
import { resetCss } from '../../zz_general/utils/scss-modules/reset';

export const Wrapper = styled.div`
  &.codeInput {
    ${resetCss()}
    .stroke {
      color: white;
      font-weight: bold;
      -webkit-text-stroke-width: 0.7px;
      -webkit-text-stroke-color: #5f2f17;
      text-shadow: 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa,
        0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa,
        0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa, 0 0 3px #f0dcaa;
    }
    .challengeCountTitle {
      margin-top: 32px;
      margin-bottom: 16px;
      ${responsiveStyle({
        fontSize: [32, 36, 40],
        height: [32, 36, 40],
        lineHeight: [32, 36, 40],
      })}
    }
    .challengeCount {
      margin-bottom: 16px;
      ${responsiveStyle({
        fontSize: [48, 52, 56],
        height: [48, 52, 56],
        lineHeight: [48, 52, 56],
      })}
    }
    .subTitle {
      margin: 0;
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      span {
        ${responsiveStyle({
          fontSize: [32, 32, 32],
          height: [32, 32, 32],
          lineHeight: [32, 32, 32],
        })}
      }
    }
    .errorMessage {
      color: red;
      margin-bottom: 16px;
      ${responsiveStyle({
        fontSize: [24, 24, 24],
        height: [24, 24, 24],
        lineHeight: [24, 24, 24],
      })}
    }
    .inputBox {
      outline: 4px solid #5f2f17;
      width: calc(80% - 8px);
      margin-bottom: 24px;

      ${responsiveStyle({
        fontSize: [32, 32, 32],
        height: [64, 64, 64],
        lineHeight: [32, 32, 32],
        padding: [16, 16, 16],
        borderRadius: [16, 16, 16],
      })}

      &.error {
        outline: 4px solid red;
      }
    }
    .inputBoxDescription {
      width: 80%;
      text-align: left;
      margin: 0 auto;
      margin-bottom: 32px;
      ${responsiveStyle({
        fontSize: [24, 24, 24],
        height: [24, 24, 24],
        lineHeight: [24, 24, 24],
      })}
    }
    .confirmButton {
      outline: 16px solid #5f2f17;
      background-color: #f0dcaa;
      width: calc(80% - 32px);
      margin-bottom: 32px;

      ${responsiveStyle({
        fontSize: [32, 32, 32],
        height: [64, 64, 64],
        lineHeight: [32, 32, 32],
        padding: [16, 16, 16],
        borderRadius: [16, 16, 16],
      })}
    }

    .xPostDescription {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 16px;
      span {
        ${responsiveStyle({
          fontSize: [24, 24, 24],
          height: [24, 24, 24],
          lineHeight: [24, 24, 24],
        })}
      }
    }

    .xIcon {
      margin: 0 auto;
      margin-bottom: 16px;
      ${responsiveStyle({
        width: [64, 68, 72],
      })}
    }
  }
`;
