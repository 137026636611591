import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from './prizeDetail.scss';

// swiper
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { QuizHeader } from '../components/quizHeader';
import { QuizWrapper } from '../components/quizWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QuizUserState } from '../store/atom';
import { publicUrl } from '../../zz_general/utils/snippets/env';
import { changePage } from '../utils/navigate';

const imgPath = (fileName) => publicUrl + '/quiz/prizeDetail/images/' + fileName;

export const QuizPrizeDetail = () => {
  const navigate = useNavigate();
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [prizes, setPrizes] = useState([]);
  const [availablePrizes, setAvailablePrizes] = useState(5);

  const user = useRecoilValue(QuizUserState);

  const resizeSwiper = () => {
    if (window.outerWidth > window.outerHeight) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(1);
    }
  };

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, 'quiz', process.env.REACT_APP_IS_PROD === 'true' ? 'quiz' : 'quiz-test', 'prizes')
      );
      const docs = {};
      let count = 0;
      querySnapshot.forEach((doc) => {
        docs[doc.id] = doc.data();
        count = count + doc.data().selected;
      });
      setPrizes(docs);
      setAvailablePrizes(count);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching documents: ', error);
    }
  };

  useEffect(() => {
    fetchData();
    resizeSwiper();
    window.addEventListener('resize', resizeSwiper);
    return () => window.removeEventListener('resize', resizeSwiper);
  }, []);

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={false}
    >
      <Wrapper
        className="prizeDetail"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <QuizHeader
          onClick={() => {
            changePage(navigate, '', 'prize-detail');
          }}
          title="賞品詳細"
        />
        <div>
          <div className="priority"> 全問正解者先着 </div>
        </div>
        <div className="itemsWrap">
          <span className="items_text items_text-rest">残り</span>
          <span className="number_text"> {availablePrizes} </span>
          <span className="items_text items_text-goods">品</span>
        </div>
        <section className="prizeSection">
          <Swiper
            modules={[Pagination, Autoplay, EffectFade]} // Register the modules you want to use
            loop={true}
            // pagination={{ clickable: true }}
            // effectFade={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            speed={1500}
            slidesPerView={slidesToShow}
            centeredSlides={true}
            className="prizeSlide"
            spaceBetween={30}
          >
            <SwiperSlide>
              <div className="prize_topText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                BENE REGALO <br />
                フルセット15,500円相当
              </div>
              <div className="prizeContainer">
                {prizes?.fullset?.selected === 0 && <div className="stock_out">品切れ</div>}
                <img src={imgPath('first_prize.webp')} alt="first prize" className="prize_details" />
              </div>
              <div className="prize_botText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Primo Tiramisu in Box{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Tiramisu Financier Box{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Tiramisu Macaron Box{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Tiramisu Gelato MASCAR3/ESPRESSO3{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.fullset?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Namachoco Tiramisu{' '}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="prize_topText"
                style={{ color: prizes?.amazon10000?.selected === 0 ? '#A7A7A7' : '#000' }}
              >
                Amazonギフトカード <br /> 10,000円分
              </div>
              <div className="prizeContainer">
                {prizes?.amazon10000?.selected === 0 && <div className="stock_out">品切れ</div>}
                <img src={imgPath('second_prize.webp')} alt="second prize" className="prize_details" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prize_topText" style={{ color: prizes?.set?.selected === 0 ? '#A7A7A7' : '#000' }}>
                BENE REGALO <br /> 定番3セット10,500円相当
              </div>
              <div className="prizeContainer">
                {prizes?.set?.selected === 0 && <div className="stock_out">品切れ</div>}
                <img src={imgPath('third_prize.webp')} alt="third prize" className="prize_details" />
              </div>
              <div className="prize_botText" style={{ color: prizes?.set?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Primo Tiramisu in Box{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.set?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Tiramisu Financier Box{' '}
              </div>
              <div className="prize_botText" style={{ color: prizes?.set?.selected === 0 ? '#A7A7A7' : '#000' }}>
                {' '}
                Tiramisu Macaron Box{' '}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prize_topText" style={{ color: prizes?.amazon5000?.selected === 0 ? '#A7A7A7' : '#000' }}>
                Amazonギフトカード <br /> 5,000円分
              </div>
              <div className="prizeContainer">
                {prizes?.amazon5000?.selected === 0 && <div className="stock_out">品切れ</div>}
                <img src={imgPath('fourth_prize.webp')} alt="fourth prize" className="prize_details" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prize_topText" style={{ color: prizes?.box?.selected === 0 ? '#A7A7A7' : '#000' }}>
                Primo Tiramisu in Box <br /> 6,000円相当
              </div>
              <div className="prizeContainer">
                {prizes?.box?.selected === 0 && <div className="stock_out">品切れ</div>}
                <img src={imgPath('fifth_prize.webp')} alt="fifth prize" className="prize_details" />
              </div>
            </SwiperSlide>
            {/* Add more SwiperSlide components with your data */}
          </Swiper>
        </section>
        <div className="buttonWrapper">
          {availablePrizes > 0 ? (
            <button
              onClick={() => {
                if (user.loggedIn) {
                  changePage(navigate, 'start', 'prize-detail');
                } else {
                  changePage(navigate, 'explanation', 'prize-detail');
                }
              }}
              className="start_button"
            >
              <span className="challenge">クイズにチャレンジする</span>
            </button>
          ) : (
            <div className="wait_nextQuiz"> 次回のティラミスクイズを お待ちください </div>
          )}
        </div>
      </Wrapper>
    </QuizWrapper>
  );
};
