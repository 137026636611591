export const consoleLog = (...args) => {
  if (process.env.REACT_APP_IS_PROD !== 'true') {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const consoleError = (...args) => {
  if (process.env.REACT_APP_IS_PROD !== 'true') {
    // eslint-disable-next-line no-console
    console.error(...args);
  }
};
