import styled from 'styled-components';
import { StyledDiv } from '../../zz_general/utils/scss-modules/styled';

export const Wrapper = styled(StyledDiv)`
  padding: 4vw;
  h1 {
    /* color: red;s */
  }
  p {
    cursor: pointer;
  }
`;
