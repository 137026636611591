import { Wrapper } from './scss';
import { InputForm } from '../../zz_general/components/molecules/inputForm';
import { LinkButton } from '../../zz_general/components/molecules/linkButton';
import { useState } from 'react';
import { validation } from '../../zz_general/utils/snippets/form';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

export const AdminSignIn = () => {
  // #region Variables
  //firebase用state
  const auth = getAuth();
  const navigate = useNavigate();
  const inputFormWidth = [340, 512, 720];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [isValid, setIsValid] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [form, setForm] = useState({
    mail: 'not filled',
    password: 'not filled',
  });
  const [content, setContent] = useState({
    mail: '',
    password: '',
  });
  // #endregion

  // #region Functions
  const setContentByProp = (key, value) => {
    setContent((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setContentByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };

  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };

  // MEMO : inputのtypeをemailにするとspace入力時にonClickが発火しないため、onKeyUpで代替する
  const onKeyUpMailAddress = (event) => {
    const value = event.target.value;

    if (event.keyCode === 32) {
      setFormByProp('mail', 'invalid');
      setIsValid(false);
    } else if (!value) {
      setFormByProp('mail', 'not filled');
      setIsValid(false);
    } else if (validation.mail.test(value)) setFormByProp('mail', 'valid');
  };

  const ShowError = (message) => {
    setLoginError(message);
    setTimeout(() => {
      setLoginError('');
    }, 3000);
  };

  const logIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (user.uid === process.env.REACT_APP_ADMIN_UID) {
          // eslint-disable-next-line no-console
          console.log('Loginに成功しました');
          setButtonIsLoading(false);
        } else {
          // eslint-disable-next-line no-console
          console.log('Loginに失敗しました');
          ShowError('管理者権限の無いユーザーです。');
          setButtonIsLoading(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.code);
        // eslint-disable-next-line no-console
        console.log(error.message);
        if (error.code === 'auth/user-not-found')
          ShowError('ユーザーが存在しません。メールアドレスを確認してください。');
        if (error.code === 'auth/wrong-password') ShowError('パスワードが間違っています。');
        setButtonIsLoading(false);
      });
  };

  // // #endregion

  // #region Effects
  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    // MEMO : フォームの有効性をセット
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    // MEMO : ログイン状態を監視
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid === process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin');
        }
      }
    });

    // コンポーネントがアンマウントされる際にリスナーを解除
    return () => unsubscribe();
  }, []);

  // #endregion

  return (
    // #region JSX
    <Wrapper>
      <h1>管理画面 </h1>
      <InputForm
        className=""
        required={true}
        title="メールアドレス"
        help="半角英数字記号のみ"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="sample@sample.com"
        description=""
        id="mail"
        type="email"
        value={content.mail || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'mail', 'mail');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'mail', 'password');
          onKeyUpMailAddress(e);
        }}
        check={form.mail === 'valid'}
        error={form.mail === 'invalid'}
        errorMessage="有効なメールアドレスを入力してください"
      />
      <InputForm
        className="marginTop"
        required={true}
        title="パスワード"
        help="半角英数字記号のみ"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="****"
        description=""
        id="password"
        type="password"
        value={content.password || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'password', 'string');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'lastName', '');
        }}
        check={form.password === 'valid'}
        error={form.password === 'invalid'}
        errorMessage="無効な文字や空白を含んでいます"
      />
      <p className="marginTop errorMessage">{loginError}</p>
      <LinkButton
        className="marginTop"
        width={inputFormWidth}
        height={linkButtonHeight}
        text="ログイン"
        color="black"
        active={isValid}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          logIn(content.mail, content.password);
        }}
      />
    </Wrapper>
    // #endregion
  );
};
