import dayjs from 'dayjs';

// Convert Firestore timestamp to dayjs object and adjust date if necessary
export const convertToDayjs = (timestamp) => {
  if (!timestamp) return dayjs(); // Handle null or undefined timestamps
  const seconds = timestamp.seconds || timestamp._seconds;
  const nanoseconds = timestamp.nanoseconds || timestamp._nanoseconds;
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);
  return dayjs(date);
};
