import { useNavigate } from 'react-router-dom';
import { Wrapper } from './scss';
import { useRecoilState } from 'recoil';
import { LPErrorPageState } from '../../store/atoms';

export const Error = () => {
  const navigate = useNavigate();
  const [errorPage, setErrorPage] = useRecoilState(LPErrorPageState);

  return (
    <Wrapper>
      <h1>予期せぬエラーが発生しました。</h1>
      <p>{errorPage.message}</p>
      <p>エラーコード：{errorPage.code}</p>
      <p>
        <span
          onClick={() => {
            navigate(`/inquiry`);
          }}
          style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
        >
          こちら
        </span>
        からお問い合わせください。
      </p>
    </Wrapper>
  );
};
