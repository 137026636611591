import { useEffect, useState, forwardRef } from 'react';
import {
  BoxButton,
  FormHeader,
  InputBox,
  RequiredLabel,
  TextButton,
  InputBoxHalfCon,
  InputBoxHalfTitle,
  InputBoxHalfDesc,
  InputBoxHalfErrorDesc,
} from './components';
import css from '../index.module.scss';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cartState, deliveryState, paymentState, totalPriceState, userState, firstViewState } from './atoms';
import { errorMessage } from './common';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Util from './util';

import { validationExpressions } from './form/form';

const PaymentCon = styled.div`
  padding: 0 24px 24px 24px;
`;

const Inner = styled.div`
  // padding: 24px 0 24px 24px;
  padding: ${(props) => (props.padding ? props.padding[0] : 24)}px
    ${(props) => (props.padding ? props.padding[1] : 0)}px ${(props) => (props.padding ? props.padding[2] : 24)}px
    ${(props) => (props.padding ? props.padding[3] : 24)}px;
  margin-left: 0;
  width: calc(
    100% - ${(props) => (props.padding ? props.padding[1] : 0)}px -
      ${(props) => (props.padding ? props.padding[3] : 24)}px
  );
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6b757e;
  height: 20px;
  background: #f3f4f5;
  &.focus {
  }
  display: block;
  border: none;
  color: #161c1c;
  outline: none;
`;

const InnerCon = styled.div`
  position: relative;
`;

const StripeInputBox = forwardRef(function StripeInputBox(props, ref) {
  const requiredLabel = props.requiredLabel ? <RequiredLabel>必須</RequiredLabel> : null;
  const img = props.img || null;
  return (
    <InputBoxHalfCon padding={props.padding}>
      <InputBoxHalfTitle>
        {props.title || ''}
        {requiredLabel}
      </InputBoxHalfTitle>
      <InnerCon>
        <img
          src={`${process.env.PUBLIC_URL}/${img}.svg`}
          alt=""
          className={`${img === null ? css.displayNone : css.card_img}`}
        />
        <Inner
          type={props.type || 'text'}
          placeholder={props.placeholder || ''}
          onChange={props.onChange || null}
          onBlur={props.onBlur}
          ref={ref}
          defaultValue={props.content || null}
          padding={props.inner_padding}
        >
          {props.element}
        </Inner>
      </InnerCon>
      <InputBoxHalfDesc>{props.description}</InputBoxHalfDesc>
      {props.isError && <InputBoxHalfErrorDesc>{props.errorMessage}</InputBoxHalfErrorDesc>}
    </InputBoxHalfCon>
  );
});

function PaymentWrapped(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [_stripeErrorMessage, setStripeErrorMessage] = useState(null);

  // エラー表示用state
  const [_cardNumValidError, setCardNumValidError] = useState(false);
  const [_effectiveDateValidError, setEffectiveDateValidError] = useState(false);
  const [_securityCodeValidError, setSecurityCodeValidError] = useState(false);
  const [accountNameValidError, setAccountNameValidError] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const validError = {
    cardNum: function (isValid) {
      setCardNumValidError(isValid);
    },
    exp: function (isValid) {
      setEffectiveDateValidError(isValid);
    },
    securityCode: function (isValid) {
      setSecurityCodeValidError(isValid);
    },
    accountName: function (isValid) {
      setAccountNameValidError(isValid);
    },
  };

  const [card, setCard] = useState('card_front');
  const [cardNumDigits, setCardNumDigits] = useState(16);

  const cardHandleChange = (event) => {
    if (event.brand === 'unknown') {
      setCard('card_front');
    } else {
      setCard(event.brand);
    }
    if (event.brand === 'amex') {
      setCardNumDigits(15);
    } else if (event.brand === 'diners') {
      setCardNumDigits(14);
    } else {
      setCardNumDigits(16);
    }
  };

  // 有効な場合にする処理
  const updateValidly = (dataName, value) => {
    // 新しくフォームのstateに登録したいデータ（stateは上書きしかできないので
    // 前のオブジェクトを展開して変更箇所だけ更新するための記述）
    const validIncludedAfter = {
      ...form,
      contents: {
        ...form.contents,
        [dataName]: {
          value: value,
          isValid: true,
        },
      },
    };
    // 新しい値に更新
    setForm(validIncludedAfter);
  };

  const handleChange = (event, dataName, validationType) => {
    // 入力内容
    const value = event.target.value;

    // バリデーション条件のチェック
    if (!validationExpressions[validationType]) {
      return;
    }
    // バリデーション
    if (validationExpressions[validationType].test(value)) {
      updateValidly(dataName, value);
      validError[dataName](false);
    } else {
      // 無効なら該当箇所の判定を無効にして更新（同様に、展開して差分を上書き）
      setForm({
        ...form,
        isValid: false,
        contents: {
          ...form.contents,
          [dataName]: {
            value: value,
            isValid: false,
          },
        },
      });
      if (value.length === 0) {
        validError[dataName](false);
      } else {
        validError[dataName](true);
      }
    }
  };

  const navigate = useNavigate();

  // 進むボタンが押されたときの処理
  const handleLinkClick = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) return;

    const card = elements.getElement(CardNumberElement);

    if (card == null) return;

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: form.accountName?.value || '-',
      },
    });

    if (payload.error) {
      setErrMessage(payload.error.message);

      setAccountNameValidError(true);
      setStripeErrorMessage(payload.error.message);
      setIsLoading(false);
    } else {
      setStripeErrorMessage(null);
      props.setPayment((current) => {
        const future = JSON.parse(JSON.stringify(current));
        future['paymentMethod'] = payload.paymentMethod;
        return future;
      });
      setIsLoading(false);
      navigate('/lp-legacy/confirm');
    }
  };

  return (
    <div className={css.input_info_con}>
      <FormHeader title="決済" step="step3" />
      <PaymentCon PaymentCon>
        {errMessage != null ? (
          <div className={css.paymentErrMessage}>
            <img src={`${process.env.PUBLIC_URL}/common/err_exclamation_mark.png`} alt="" />
            <p>{errMessage}</p>
          </div>
        ) : (
          ''
        )}
        <img src={`${process.env.PUBLIC_URL}/visa.svg`} alt="" className={css.card_imgs} />
        <img src={`${process.env.PUBLIC_URL}/mastercard.svg`} alt="" className={css.card_imgs} />
        <img src={`${process.env.PUBLIC_URL}/jcb.svg`} alt="" className={css.card_imgs} />
        <img src={`${process.env.PUBLIC_URL}/amex.svg`} alt="" className={css.card_imgs} />
        <img src={`${process.env.PUBLIC_URL}/diners.svg`} alt="" className={css.card_imgs} />
        <StripeInputBox
          title="カード登録"
          description={`ハイフン入力不要 ${cardNumDigits}桁`}
          placeholder="0000 0000 0000 0000"
          required={true}
          inner_padding={[24, 0, 24, 96]}
          img={card}
          // isError={cardNumValidError}
          // errorMessage={errorMessage.cardNumValidMessage}
          // content={form.contents?.cardNum.value || null}
          element={
            <CardNumberElement
              onChange={(e) => cardHandleChange(e)}
              options={{
                placeholder: '0000 0000 0000 0000',
                style: { base: { fontSize: '18px' } },
              }}
            />
          }
        />
        <div className={css.flex}>
          <StripeInputBox
            title="有効期限"
            placeholder="MM/YY"
            description="スラッシュなし"
            padding={[12, 8, 0, 0]}
            required={true}
            // isError={effectiveDateValidError}
            // errorMessage={errorMessage.effectiveDateValidMessage}
            // content={form.contents?.exp.value || null}
            element={
              <CardExpiryElement
                options={{
                  placeholder: 'MM / YY',
                  style: { base: { fontSize: '18px' } },
                }}
              />
            }
          />
          <StripeInputBox
            title="セキュリティコード"
            placeholder="123"
            description="3-4桁の数字"
            padding={[12, 0, 0, 8]}
            inner_padding={[24, 0, 24, 96]}
            required={true}
            img="card_back"
            // isError={securityCodeValidError}
            // errorMessage={errorMessage.securityCodeValidMessage}
            // content={form.contents?.securityCode.value || null}
            element={
              <CardCvcElement
                options={{
                  placeholder: '123',
                  style: { base: { fontSize: '18px' } },
                }}
              />
            }
          />
        </div>
        <InputBox
          title="カード名義"
          placeholder="HIROMI YAMADA"
          required={true}
          description="名と姓の間はスペースを入れてください"
          className={css.mb24}
          isError={accountNameValidError}
          errorMessage={errorMessage?.accountNameValidMessage}
          onChange={(e) => handleChange(e, 'accountName', 'accountName')}
          content={form?.contents?.accountName.value || null}
        />
        <BoxButton
          text="確認画面へ進む"
          className={css.mt24}
          onClick={(e) => {
            handleLinkClick(e);
          }}
          disabled={isLoading || !stripe || !elements}
          isLoading={isLoading}
        />
        <Link to={'/lp-legacy/delivery'}>
          <TextButton text="配送に戻る" />
        </Link>
      </PaymentCon>
    </div>
  );
}

const app = initializeApp({
  apiKey: 'AIzaSyBIcp0Ggkkiycsetzheo63jhgzllGMRvjQ',
  authDomain: 'bene-reglo-lp.firebaseapp.com',
  projectId: 'bene-reglo-lp',
  storageBucket: 'bene-reglo-lp.appspot.com',
  messagingSenderId: '500090694268',
  appId: '1:500090694268:web:1d4cbbb4a16e1a4cd377f9',
  measurementId: 'G-S5J5HM4S8Y',
});

const functions = getFunctions(app, 'asia-northeast1');
function Payment() {
  const stripePromise =
    process.env.REACT_APP_IS_PROD === 'true'
      ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY.toString())
      : loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY.toString());

  const amount = useRecoilValue(totalPriceState);
  const cart = useRecoilValue(cartState); // カート情報
  const user = useRecoilValue(userState).user; //ユーザーの情報
  const delivery = useRecoilValue(deliveryState).selectedAddress;

  const setPayment = useSetRecoilState(paymentState);

  const metadata = {};
  Object.keys(cart.items).forEach((key) => {
    metadata[cart.items[key]?.nameInConfirm] = cart.items[key].count;
    metadata[key] = cart.items[key].count;
  });

  metadata.deliveryDate = useRecoilValue(deliveryState).dateTime?.fulldate;
  metadata.deliveryTime = useRecoilValue(deliveryState).dateTime?.clockTime;

  useEffect(() => {
    Util.sendRefererData('Payment');
  }, []);

  useEffect(() => {
    const createPayment = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'createPayment' : 'createTestPayment'
    );
    createPayment({
      // Stripeに送る支払い情報
      payload: {
        amount: amount || 100,
        currency: 'jpy',
        shipping: {
          name: delivery.lName?.value + ' ' + delivery.fName?.value,
          address: {
            line1: delivery.postalCode?.value2,
            line2: delivery.address1?.value + (delivery.address2?.value || '') + (delivery.address3?.value || ''),
            city: '-',
            state: '-',
            postal_code: delivery.postalCode?.value,
            country: 'JP',
          },
          phone: delivery.phoneNum?.value,
        },
        receipt_email: user.mail?.value,
        metadata: metadata,
      },
    })
      .then((res) => {
        if (!res.data) return;

        // 確定の時に参照するStripeのオブジェクトを保持する
        setPayment((current) => {
          const future = JSON.parse(JSON.stringify(current));
          future['paymentIntent'] = res.data;
          return future;
        });
      })
      .catch((_err) => {});
  }, []);

  const firstView = useRecoilValue(firstViewState);
  const navigate = useNavigate();
  useEffect(() => {
    if (!firstView.visited) {
      navigate('/lp-legacy');
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <PaymentWrapped setPayment={setPayment} />
    </Elements>
  );
}

export default Payment;
