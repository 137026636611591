export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('copied to clipboard:', text);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('error copying clipboard', err);
    });
};
