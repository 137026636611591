import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.explanation {
    overflow: hidden;
    .rule {
      margin: 0 auto;
      color: #161c1c;
      text-align: left;

      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.8, 0.9, 0.9]),
          marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
      p.ruleGrid {
        display: grid;
        grid-template-columns: auto 1fr;
        margin: 0;
        ${(props) => {
          return responsiveStyle({
            fontSize: multiplyArrayByScalar(props.bodyWidth, 0.05),
            lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.05),
          });
        }}
      }
    }
    .notesTitle {
      position: relative;
      color: white;
      font-weight: 800;
      text-align: center;
      font-family: 'AvenirLTStd-Black';
      font-weight: bolder;

      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.08),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.08),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.07),
        });
      }}
    }
    .notesTitle::before {
      content: 'お願い';
      color: #5f2f17;
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }

    .notes {
      margin: 0 auto;
      text-align: left;

      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.8, 0.9, 0.9]),
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.05),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.05),
          marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
          paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
    }

    .notes2 {
      margin: 0 auto;
      text-align: left;

      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.8, 0.9, 0.9]),
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.05),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.05),
          paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
    }

    .tap_details {
      font-family: 'Kiwi Maru';
      font-weight: 500;
      animation: expandContract 2s ease-in-out infinite;
      transform-origin: center; /* To ensure scaling happens from the center */
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.048, 0.05, 0.045]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.064),
        });
      }}
    }
    @keyframes expandContract {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    .lineButton {
      display: block;
      margin: 0 auto;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrayByScalar(props.bodyWidth, 0.6)
              : multiplyArrayByScalar(props.bodyWidth, 0.7),
          height: multiplyArrayByScalar(props.bodyWidth, 0.126),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.01),
        });
      }}
    }

    .smsTitle {
      color: white;
      -webkit-text-stroke: 1px #5f2f17;
      text-align: center;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.072),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.072),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.072),
        });
      }}
    }
    .smsInput {
      display: block;
      margin: 12px auto;
      border: none;
      padding: 10px;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrayByScalar(props.bodyWidth, 0.6)
              : multiplyArrayByScalar(props.bodyWidth, 0.7),
          height: multiplyArrayByScalar(props.bodyWidth, 0.126),
          borderRadius: multiplyArrayByScalar(props.bodyWidth, 0.02),
        });
      }}
      &:focus {
        outline: #5f2f17 solid 2px;
      }
    }
    .sendButton {
      display: block;
      margin: 20px auto;
      text-align: center;
      border-radius: 20px;
      outline: 10px solid #5f2f17;
      background: #f0dcaa;
      color: #5f2f17;
      ${(props) => {
        return responsiveStyle({
          width:
            props.screenOrientation === 'horizontal'
              ? multiplyArrayByScalar(props.bodyWidth, 0.6)
              : multiplyArrayByScalar(props.bodyWidth, 0.7),
          height: multiplyArrayByScalar(props.bodyWidth, 0.126),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.126),
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.056),
        });
      }}
    }

    .sampleWrap {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '120%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-16.67%' : '0')};

      .sample {
        text-align: center;
        ${(props) => {
          return responsiveStyle({
            width:
              props.screenOrientation === 'horizontal'
                ? multiplyArrays(props.bodyWidth, [0.6, 0.6, 0.6])
                : multiplyArrays(props.bodyWidth, [1, 1, 1]),
            fontSize: multiplyArrayByScalar(props.bodyWidth, 0.042),
            lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.042),
            height: multiplyArrayByScalar(props.bodyWidth, 0.042),
            marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
            paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
          });
        }}
      }
      .sample1 {
        background-color: blue;
      }

      .sample2 {
        background-color: green;
      }
    }
  }
`;
