import { multiplyArrayByScalar, multiplyArrays, responsiveStyle } from '../components/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.prizeDetail {
    .prize {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 800;
      color: #fff;
      -webkit-text-stroke: calc(0.1vw + 1.2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.1, 0.1]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrays(props.bodyWidth, [0.12, 0.1, 0.08]),
          paddingLeft: multiplyArrayByScalar(props.bodyWidth, 0.15),
        });
      }}
    }
    .priority {
      position: relative;
      color: #fff;
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 800;
      // -webkit-text-stroke: calc(0.1vw + 1.2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.085, 0.1, 0.1]),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.08),
        });
      }}
    }
    .priority::before {
      content: '全問正解者先着';
      color: #5f2f17;
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }

    .itemsWrap {
      color: #fff;
      font-weight: 800;
      ${(props) => {
        return responsiveStyle({
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.064),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.08),
          paddingBottom: multiplyArrayByScalar(props.bodyWidth, 0.05),
        });
      }}
    }
    .number_text {
      font-family: 'Noto Sans JP';
      -webkit-text-stroke: calc(0.1vw + 1.2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.12, 0.12]),
        });
      }}
    }
    .items_text {
      color: #fff;
      position: relative;
      font-family: 'Hiragino Kaku Gothic Pro';
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.056]),
        });
      }}
    }

    .items_text-rest::before {
      content: '残り';
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }

    .items_text-goods::before {
      content: '品';
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }

    .prizeSlide {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '200%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-50%' : '0')};
    }
    .prize_topText {
      font-family: 'Hiragino Kaku Gothic Std';
      font-weight: 800;
      -webkit-text-stroke: calc(0.03vw + 0.5px) #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.056]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.056, 0.056]),
        });
      }}
    }
    .prize_botText {
      -webkit-text-stroke: calc(0.01vw + 0.5px) #fff;
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 600;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.042, 0.038, 0.038]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.042, 0.038, 0.038]),
        });
      }}
    }
    .prizeContainer {
      position: relative;
      display: inline-block;
      width: 100%;
    }
    .prize_details {
      position: relative;
      z-index: 0;
      ${(props) => {
        return responsiveStyle({
          width: multiplyArrays(props.bodyWidth, [0.6, 0.45, 0.45]),
          height: multiplyArrays(props.bodyWidth, [0.38, 0.28, 0.28]),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.01),
        });
      }}
    }
    .stock_out {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #fff;

      font-family: 'Source Han Sans JP';
      font-weight: 900;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.128, 0.09, 0.09]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.064, 0.042, 0.042]),
        });
      }}
    }
    .stock_out::before {
      content: '品切れ';
      color: #fff;
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 15px #a7a7a7;
      z-index: -1;
    }
    .buttonWrapper {
      ${(props) => {
        return responsiveStyle({
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.05),
          paddingBottom: multiplyArrays(props.bodyWidth, [0.09, 0.06, 0.06]),
        });
      }}
    }
    .start_button {
      border: ${(props) => `${multiplyArrays(props.bodyWidth, [0.027, 0.017, 0.01])[0]}px solid #5F2F17`};
      background: #f0dcaa;
      ${(props) => {
        return responsiveStyle({
          height: multiplyArrays(props.bodyWidth, [0.225, 0.18, 0.16]),
          // marginTop: multiplyArrays(props.bodyWidth, [0.09, 0.05, 0.03]),
          borderRadius: multiplyArrays(props.bodyWidth, [0.054, 0.044, 0.034]),
        });
      }}
    }
    .challenge {
      color: #fff;
      position: relative;
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 800;
      -webkit-text-stroke: calc(0.05vw + 1px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrayByScalar(props.bodyWidth, 0.064),
          lineHeight: multiplyArrayByScalar(props.bodyWidth, 0.09),
          marginTop: multiplyArrayByScalar(props.bodyWidth, 0.08),
        });
      }}
    }

    .challenge::before {
      content: 'クイズにチャレンジする';
      color: #5f2f17;
      position: absolute;
      inset: 0;
      -webkit-text-stroke: 3px #5f2f17;
      z-index: -1;
    }
    .wait_nextQuiz {
      display: ${(props) => (props.screenOrientation === 'horizontal' ? 'flex' : 'block')};
      width: ${(props) => (props.screenOrientation === 'horizontal' ? '160%' : '100%')};
      margin-left: ${(props) => (props.screenOrientation === 'horizontal' ? '-16.67%' : '0')};
      margin: ${(props) => props.screenOrientation === 'vertical' && '0 auto'};
      color: #fff;
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 800;
      -webkit-text-stroke: calc(0.1vw + 1.2px) #5f2f17;
      ${(props) => {
        return responsiveStyle({
          fontSize: multiplyArrays(props.bodyWidth, [0.075, 0.08, 0.08]),
          lineHeight: multiplyArrays(props.bodyWidth, [0.128, 0.1, 0.1]),
          marginTop: multiplyArrays(props.bodyWidth, [0.08, 0.06, 0.04]),
        });
      }}
    }
  }
`;
